import './index.css'
import { Container } from '../Container'
import imgdente from '../../../assets/imgdente.jpg'
import { Link } from 'react-router-dom'

export default function BoasVindas(){
    return(
        <section className='boasvindas-container'>
            <Container>
                <div className='boasvindas-topo'>
                    <div>
                        <h2>Conheça o sistema Minha clínica, um sistema para controle de pacientes, consultas, agendamentos, financeiro, podendo ser usado para consultas médicas, odontológicas, estéticas e muito mais 100% online.</h2>
                        <Link to='/cadastrouser' target='_blank'>TESTE AGORA GRATUITAMENTE</Link>
                        <div className='boasvindas-txtbtn'>
                            <span>Teste grátis pelo seu computador.</span>   
                        </div> 
                    </div>
                    <img src={imgdente} alt="Imagem dente" />
                </div>
            </Container>
        </section>

    )
}