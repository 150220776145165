import { useContext } from 'react'
import { Routes, Route } from "react-router-dom";
import { AuthContext } from '../contexts/AuthContext';

import Home from "../pages/home";
import QuemSomos from '../components/QuemSomos';
import Login from "../pages/login";
import Dashboard from "../pages/dashboard";
import ListaPaciente from "../pages/ListaPaciente";
import NovoPaciente from "../pages/NovoPaciente";
import Private from "./private";
import MinhaConta from '../pages/MinhaConta';
import CadastroUsuario from '../pages/CadastroUsuario';
import Agenda from '../pages/Agenda';
import Prontuario from '../pages/Prontuario';
import ListaProdutos from '../pages/ListaProdutos';
import NovoProduto from '../pages/NovoProduto';
import ListaProcedimentos from '../pages/ListaProcedimentos';
import NovoProcedimento from '../pages/NovoProcedimento';
import ContasReceber from '../pages/ContasReceber';

import Notfound from "../pages/Notfound";

export default function RoutesApp(){
    const { isAuthenticated } = useContext(AuthContext)

    return(
        <Routes>
            <Route path="/" element={ <Home/> }/>
            <Route path="/quemsomos" element={ <QuemSomos/> }/>
            <Route path="/login" element={ isAuthenticated ? <Dashboard/> : <Login/> } />
            <Route path="/cadastrouser" element={ isAuthenticated ? <Dashboard/> : <CadastroUsuario/> } />

            <Route path="/dashboard" element={ <Private> <Dashboard/> </Private>  }/>
            <Route path="/pacientes" element={ <Private> <ListaPaciente/> </Private>  } />
            <Route path="/novopaciente/:id?" element={ <Private> <NovoPaciente/> </Private>  } />
            <Route path="/account" element={ <Private> <MinhaConta/> </Private> } />
            <Route path="/agenda" element={ <Private> <Agenda/>  </Private>  } />
            <Route path="/prontuario/:id?/:paciente?" element={ <Private> <Prontuario/> </Private> } />
            <Route path="/produtos" element={ <Private> <ListaProdutos/> </Private> } />
            <Route path="/novoproduto/:id?" element={ <Private> <NovoProduto/> </Private> } />

            <Route path="/procedimentos" element={ <Private> <ListaProcedimentos/> </Private> } />
            <Route path="/novoprocedimento/:id?" element={ <Private> <NovoProcedimento/> </Private> } />

            <Route path="/contasreceber" element={ <Private> <ContasReceber/> </Private> } />

            <Route path="*" element={ <Notfound/> } />
        </Routes>
    )    
}