import { useState, useContext, FormEvent, ChangeEvent, useEffect } from 'react'
import './index.css'
import Header from '../../components/Header'
import BarraLateral from '../../components/BarraLateral'
import Footer from '../../components/Footer'
import { RiAccountCircleFill } from 'react-icons/ri'
import Loading from '../../components/Loading'
import { MdCancel } from 'react-icons/md'
import img from '../../assets/avatar.png'
import { BsFillCheckCircleFill } from 'react-icons/bs'
import { IoMdSearch, IoIosCheckmark } from "react-icons/io"
import fundologo from '../../assets/fundologo.jpg'
import { Input } from '../../components/Input'
import { toast } from 'react-toastify'
import { AuthContext } from '../../contexts/AuthContext'
import { setupApiClient } from '../../services/api'
import { useNavigate } from 'react-router-dom'
import { converterDataParaTexto, telMask, cepMask, cpfMask } from '../../utils/utils'
import { mask, unMask } from 'remask'
import { cpf , cnpj as validCNPJ } from 'cpf-cnpj-validator'

export default function MinhaConta(){
    const navigate = useNavigate()
    const { user, updateUser, signOut } = useContext(AuthContext)

    const [isLoading, setIsLoading] = useState(false)
    const [nome, setNome] = useState(user?.nome)
    const [endereco, setEndereco] = useState('')
    const [numero, setNumero] = useState('')
    const [bairro, setBairro] = useState('')
    const [complemento, setComplemento] = useState('')
    const [cidade, setCidade] = useState('')
    const [ufSelected, setUfSelected] = useState('')
    const [cnpj, setCNPJ] = useState('')
    const [ie, setIE] = useState('')
    const [urlfoto, setUrlFoto] = useState(user?.urlfoto)
    const [urlLogo, setUrlLogo] = useState(user?.url_logo)
    const [telefone, setTelefone] = useState('')
    const [celular, setCelular] = useState('')
    const [cep, setCep] = useState('')
    const [fotoUser, setFotoUser] = useState(null)
    const [logotipo, setLogoTipo] = useState(null)
    const [cpfvalido, setCpfValido] = useState(false)

    useEffect( () => {
        async function getDadosUser(){
            try{
                const email = localStorage.getItem('@emailMinhaClinica')
                const apiClient = setupApiClient()
                await apiClient.get('/me',{
                    params:{
                        email: email
                    }
                })
                .then( (response) => {
                    const { CODIGO, NOME, EMAIL, ENDERECO, NUMERO, BAIRRO,
                            COMPLEMENTO, CIDADE, CEP, UF, TELEFONE, CELULAR, 
                            CNPJ, IE,CRIADO, URLFOTO, URL_LOGO } = response.data

                    let obj = {
                        id: CODIGO,
                        nome: NOME,
                        email: EMAIL,
                        datacriado: converterDataParaTexto(CRIADO),
                        urlfoto: URLFOTO,
                        url_logo: URL_LOGO,
                        endereco: ENDERECO,
                        numero: NUMERO,
                        bairro: BAIRRO,
                        complemento: COMPLEMENTO,
                        cep: CEP,
                        cidade: CIDADE,
                        uf: UF,
                        telefone: TELEFONE,
                        celular: CELULAR,
                        cnpj: CNPJ == null ? '' : CNPJ,
                        ie: IE
                    }

                    updateUser(obj)
                    setNome(NOME)
                    setUrlFoto(URLFOTO)
                    setUrlLogo(URL_LOGO)
                    setEndereco(ENDERECO)
                    setNumero(NUMERO)
                    setBairro(BAIRRO)
                    setComplemento(COMPLEMENTO)
                    setCidade(CIDADE)
                    setCep(CEP)
                    setUfSelected(UF)
                    setTelefone(TELEFONE)
                    setCelular(CELULAR)
                    setCNPJ(CNPJ == null ? '' : CNPJ)
                    setIE(IE)
                    setCpfValido(CNPJ != '' ? true : false)
                })
                .catch( () => {
                    signOut()
                    navigate('/')
                })
            } catch(err) {
                console.log(err)
            }
        }

        getDadosUser()
    },[])

    function onChangeFone(e: ChangeEvent<HTMLInputElement>){
        const originalValue = unMask(e.target.value)
        const maskedValue = mask(originalValue, telMask)
        setTelefone(maskedValue)
    }

    function handleCancelar(e: FormEvent){
        e.preventDefault()
        navigate('/dashboard')
    }

    function onChangeCep(e: ChangeEvent<HTMLInputElement>){
        const originalValue = unMask(e.target.value)
        const maskedValue = mask(originalValue, cepMask)
        setCep(maskedValue)
    }

    function onChangeCel(e: ChangeEvent<HTMLInputElement>){
        const originalValue = unMask(e.target.value)
        const maskedValue = mask(originalValue, telMask)
        setCelular(maskedValue)
    }

    function onChangeCNPJ(e: ChangeEvent<HTMLInputElement>){
        const originalValue = unMask(e.target.value)
        const maskedValue = mask(originalValue, cpfMask)
        setCNPJ(maskedValue)

        if(originalValue.length > 11){
            validCNPJ.isValid(originalValue) ? setCpfValido(true) : setCpfValido(false) 
        } else {
            cpf.isValid(originalValue) ? setCpfValido(true) : setCpfValido(false) 
        }
    }

    async function handleCep(){
        await fetch(`https://viacep.com.br/ws/${cep.replace('-','').replace('.','')}/json/`)
        .then( (response) => response.json()) 
        .then( (response) => {
            setEndereco(response?.logradouro.toUpperCase())
            setBairro(response?.bairro.toUpperCase())    
            setCidade(response?.localidade.toUpperCase())
            setUfSelected(response?.uf)
            document.getElementById('p_num').focus()
        })
        .catch( () => {
            setEndereco('')
            setBairro('')
            setCidade('')
            toast.warn('Erro buscando o cep!')
        })     
    }
    
    async function handleSalvar(e: FormEvent){
        e.preventDefault()

        if(nome == ''){
            toast.warn('Digite o nome!')
            document.getElementById('p_nome').focus()
            return
        }

        if(cnpj !== ''){
            if(!cpfvalido){
                toast.warn('CNPJ inválido!')
                document.getElementById('p_cpf').focus()
                return                
            }
        }

        try{
            setIsLoading(true)

            const req = new FormData();
            req.append('userid', user?.email)
            req.append('nome',nome);
            req.append('endereco', endereco)
            req.append('numero', numero)
            req.append('bairro', bairro)
            req.append('complemento', complemento)
            req.append('cidade', cidade)
            req.append('cep', cep)
            req.append('uf', ufSelected)
            req.append('telefone', telefone)
            req.append('celular', celular)
            req.append('cnpj', cnpj)
            req.append('ie', ie)

            if (fotoUser){
                req.append('file', fotoUser)
            } 

            const apiClient = setupApiClient()

            const response = await apiClient.put('/usuario', req)

            if(response.data?.COD == 200){
                toast.success('Dados atualizados!')
                navigate('/dashboard')
                setIsLoading(false)             
            } else {
                setIsLoading(false)
                toast.error(response.data?.COD+' - '+response.data?.MSG)
                return  
            }
        } catch(error) {
            console.log(error)
            toast.error('Erro atualizando o perfil, verifique os dados digitados!')
            setIsLoading(false)
        }
    }   
    
    function handleFoto(e: ChangeEvent<HTMLInputElement>){
        if(!e.target.files){
            return
        }

        const img = e.target.files[0];
        if (!img){
            return;
        }

        if (img.type === 'image/jpeg' || img.type === 'image/png' || img.type === 'image/jpg'){
            setFotoUser(img)
            setUrlFoto(URL.createObjectURL(img))
        }        
    }

    function handlelogotipo(e: ChangeEvent<HTMLInputElement>){
        if(!e.target.files){
            return
        }

        const img = e.target.files[0];
        if (!img){
            return;
        }

        if (img.type === 'image/jpeg' || img.type === 'image/png' || img.type === 'image/jpg'){
            setLogoTipo(img)
            setUrlLogo(URL.createObjectURL(img))
        }        
    }

    async function handleRemoverLogo() {
        try{
            setIsLoading(true)

            const apiClient = setupApiClient()

            const response = await apiClient.delete('/logotipo',{
                params:{
                    userid: user?.email
                }
            })

            if(response.data?.COD == 200){
                toast.success('Logotipo removido com sucesso!')
                navigate('/dashboard')
                setIsLoading(false)              
            } else {
                setIsLoading(false)
                toast.error(response.data?.COD+' - '+response.data?.MSG)
                return  
            }
        } catch(error) {
            console.log(error)
            toast.error('Erro removendo logotipo!')
            setIsLoading(false)
        }        
    }

    async function handleSalvarLogo(){
        try{
            setIsLoading(true)
            if(!logotipo){
                return
            }

            const req = new FormData();
            req.append('userid', user?.email)
            req.append('file', logotipo)

            const apiClient = setupApiClient()

            const response = await apiClient.put('/logotipo', req)

            if(response.data?.COD == 200){
                toast.success('Logotipo atualizado com sucesso!')
                navigate('/dashboard')
                setIsLoading(false)              
            } else {
                setIsLoading(false)
                toast.error(response.data?.COD+' - '+response.data?.MSG)
                return  
            }
        } catch(error) {
            console.log(error)
            toast.error('Erro atualizando logotipo!')
            setIsLoading(false)
        }
    }

    if(isLoading){
        return(
            <main>
                <Header/>
                <BarraLateral/>            
                <Loading/>
                <Footer/>
            </main>
        )
    }

    return(
        <main>
            <Header/>
            <BarraLateral/>
            <div className='content-listapaciente'>
                <div className='container-titulo-paciente'>
                    <div className='textheader'>
                        <RiAccountCircleFill size={25} color='#555'/>
                        <h3>Você está em minha conta</h3>
                    </div>               
                </div> 

                <div className='boxImg'>
                    <label id='img-user'>
                        <input type='file' accept='image/jpeg, image/png' onChange={handleFoto}/>
                        <img src={ urlfoto ? urlfoto : img} alt='Avatar' width={120} />
                    </label>

                    <label>
                        <span className='txtLogo'>LOGOTIPO 500x140</span>
                        <input type='file' accept='image/jpeg, image/png' onChange={handlelogotipo}/>
                        <img id='logotipo' src={ urlLogo ? urlLogo : fundologo} alt='Logo' width={500} />
                    </label>
                    <div className='div-btn-logo'>
                        { urlLogo && (
                            <button className='btnLogo' onClick={handleRemoverLogo}>Remover</button>
                        )}
                        { logotipo && (
                            <button className='btnLogo' onClick={handleSalvarLogo}>Salvar</button>
                        )}
                    </div>
                </div>

                <form className='boxInputs' onSubmit={handleSalvar}>

                    <div className='div-perfil-linha'>
                        <div className='div-inputs'>
                            <label htmlFor="p_email">EMAIL DE ACESSO</label>
                            <Input
                                id='p_email'
                                style={{width:'300px', cursor:'not-allowed'}}
                                placeholder='email'
                                type='email'
                                disabled={true}
                                value={user?.email}
                            /> 
                        </div>
                        <div className='div-inputs'>
                            <label htmlFor="p_data">CLIENTE DESDE</label>
                            <Input
                                id='p_data'
                                style={{width:'100px',cursor:'not-allowed'}}
                                placeholder='Data'
                                type='text'
                                disabled={true}
                                value={user?.datacriado}
                            /> 
                        </div>
                    </div>

                    <div className='div-perfil-linha'>
                        <div className='div-inputs'>
                            <label htmlFor="p_nome">NOME ESTABELECIMENTO OU USUÁRIO</label>
                            <Input
                                style={{width:'520px'}}
                                id='p_nome'
                                value={nome}    
                                placeholder='Nome'
                                type='text'
                                onChange={ (e) => setNome(e.target.value)}
                            /> 
                        </div>

                    </div>

                    <div className='div-perfil-linha'>
                        <div className='div-inputs'>
                            <label htmlFor="p_end">ENDEREÇO</label>
                            <Input 
                                style={{width:'300px'}}
                                id='p_end' 
                                placeholder='Endereço'
                                type='text'
                                value={endereco}
                                onChange={ (e) => setEndereco(e.target.value)}
                            />
                        </div>                                  
                        <div className='div-inputs'>
                            <label htmlFor="p_num">NÚMERO</label>
                            <Input 
                                style={{width:'80px'}}
                                id='p_num' 
                                placeholder='Numero'
                                type='text'
                                value={numero}
                                onChange={ (e) => setNumero(e.target.value)}
                            />
                        </div>  
                        <div className='div-inputs'>
                            <label htmlFor="p_bairro">BAIRRO</label>
                            <Input 
                                style={{width:'180px'}}
                                id='p_bairro' 
                                placeholder='Bairro'
                                type='text'
                                value={bairro}
                                onChange={ (e) => setBairro(e.target.value)}
                            />
                        </div> 
                        <div className='div-inputs'>
                            <label htmlFor="p_comp">COMPLEMENTO</label>
                            <Input 
                                style={{width:'105px'}}
                                id='p_comp' 
                                placeholder='Complemento'
                                type='text'
                                value={complemento}
                                onChange={ (e) => setComplemento(e.target.value)}
                            />
                        </div> 
                    </div>

                    <div className='div-perfil-linha'>
                        <div className='div-inputs'>
                            <label htmlFor="p_cidade">CIDADE</label>
                            <Input 
                                style={ {width:'243px'}}
                                id='p_cidade' 
                                placeholder='Cidade'
                                type='text'
                                value={cidade}
                                onChange={ (e) => setCidade(e.target.value)}
                            />
                        </div>
                    
                        <div className='div-inputs'>
                            <label htmlFor="p_cep">CEP</label>
                            <div className='div-cpf'>
                                <Input 
                                    style={ {width:'80px'}}
                                    id='p_cep' 
                                    placeholder='00.000-000'
                                    type='text'
                                    value={cep}
                                    onChange={onChangeCep}
                                />
                                <IoMdSearch 
                                    size={20} 
                                    id='search-cep' 
                                    color='#000' 
                                    onClick={handleCep} 
                                    title='Buscar CEP'
                                />
                            </div>
                        </div>

                        <div className='div-inputs'>
                            <label htmlFor="p_uf">UF</label>
                            <select 
                                style={ {width:'60px',borderColor:'#ccc'}}
                                id='p_uf' 
                                value={ufSelected}
                                onChange={ (e) => setUfSelected(e.target.value)}
                            >
                                <option value="AC">AC</option>
                                <option value="AL">AL</option>
                                <option value="AP">AP</option>
                                <option value="AM">AM</option>
                                <option value="BA">BA</option>
                                <option value="CE">CE</option>
                                <option value="ES">ES</option>
                                <option value="GO">GO</option>
                                <option value="MA">MA</option>
                                <option value="MT">MT</option>
                                <option value="MS">MS</option>
                                <option value="MG">MG</option>
                                <option value="PA">PA</option>
                                <option value="PB">PB</option>
                                <option value="PR">PR</option>
                                <option value="PE">PE</option>
                                <option value="PI">PI</option>
                                <option value="RJ">RJ</option>
                                <option value="RN">RN</option>
                                <option value="RS">RS</option>
                                <option value="RO">RO</option>
                                <option value="RR">RR</option>
                                <option value="SC">SC</option>
                                <option value="SP">SP</option>
                                <option value="SE">SE</option>
                                <option value="TO">TO</option>
                                <option value="DF">DF</option>
                            </select>
                        </div>

                        <div className='div-inputs'>
                            <label htmlFor="p_fone">TELEFONE</label>
                            <Input 
                                style={ {width:'120px'}}
                                id='p_fone' 
                                placeholder='(00) 0000-0000'
                                type='text'
                                value={telefone}
                                onChange={onChangeFone}
                            />
                        </div>

                        <div className='div-inputs'>
                            <label htmlFor="cel">CELULAR</label>
                            <Input 
                                style={ {width:'120px'}}
                                id='p_cel' 
                                placeholder='(00) 0 0000-0000'
                                type='text'
                                value={celular}
                                onChange={onChangeCel}
                            />
                        </div>                            
                    </div>                

                    <div className='div-perfil-linha'>
                        <div className='div-inputs'>
                            <label htmlFor="cpf">CNPJ</label>
                            <div className='div-cpf'>
                                <Input 
                                    style={ {width:'155px'}}
                                    id='p_cpf' 
                                    placeholder='00.000.000/0000-00'
                                    type='text'
                                    value={cnpj}
                                    onChange={onChangeCNPJ}
                                />
                                { cpfvalido ? (
                                    <IoIosCheckmark size={25} color='green'/>
                                ) : (
                                    <MdCancel color='red' size={25}/>
                                )}
                            </div>
                        </div>

                        <div className='div-inputs'>
                            <label htmlFor="p_rg">IE</label>
                            <Input 
                                style={ {width:'195px'}}
                                id='rg' 
                                placeholder='000000000'
                                type='text'
                                value={ie}
                                onChange={ (e) => setIE(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className='div-btn-perfil'>
                        <button 
                            id='salvar'
                            type='submit'
                        >
                            <BsFillCheckCircleFill color='#FFF' size={18}/>
                            SALVAR
                        </button>

                        <button onClick={handleCancelar}>
                            <MdCancel color='#FFF' size={18}/>
                            CANCELAR
                        </button>
                    </div> 
                </form>
            </div>
            <Footer/>
        </main>
    )
}