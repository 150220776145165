import './index.css'
import img1 from '../../../assets/dentista.jpg'
import img2 from '../../../assets/medico.jpg'
import img3 from '../../../assets/veterinario.jpg'
import img4 from '../../../assets/estetica.jpg'
import { Container } from '../Container'
import { Navigation, Pagination, Scrollbar } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react';

export default function BannerCarousel(){
    const slides = [img1, img2, img3, img4]

    return(
        <section className='banner-container'>
            <Container>
                <Swiper
                    modules={[Navigation, Pagination, Scrollbar]}
                    slidesPerView={1}
                    pagination={{ clickable: true }}
                    navigation
                    autoplay
                >
                    {slides.map( (slide, index) => (
                        <SwiperSlide key={index}>
                           <img
                            src={slide}
                            alt='Slides'
                            className='banner-img'
                           /> 
                        </SwiperSlide>
                    ))}
                </Swiper>
            </Container>
        </section>
    )
}