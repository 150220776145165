import './menumobile.css'
import { Link } from "react-router-dom"
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { AiFillHome, AiFillPhone } from 'react-icons/ai'
import { FaFlag } from 'react-icons/fa'
import { IoCloseCircle } from "react-icons/io5"
import { ImPriceTags } from 'react-icons/im'

interface MenuMobileProps{
    openMenu: () => void;
}

export default function MenuMobile({ openMenu }: MenuMobileProps){
    return(
        <nav className='menumobile-box'>
            <div>
                <button onClick={openMenu}>
                    <IoCloseCircle size={40} color='#fff' /> 
                </button>
            </div>
            <ul>
                <li>
                    <AiFillHome color="#fff" size={25}/>
                    <Link to='/'>HOME</Link>
                </li>
                <li> 
                    <ImPriceTags color="#fff" size={25}/>
                    <AnchorLink href='#planos' onClick={openMenu}>PLANOS</AnchorLink>
                </li>
                <li> 
                    <FaFlag color="#FFF" size={20}/>
                    <Link to='/quemsomos'>QUEM SOMOS</Link>
                </li>
                <li> 
                    <AiFillPhone color="#fff" size={25}/>
                    <AnchorLink href='#contato' onClick={openMenu}>CONTATO</AnchorLink>
                </li>
            </ul>
        </nav>
    )
}