import { FormEvent, useEffect, useState, ChangeEvent, useContext } from 'react'
import './index.css'
import { useParams } from 'react-router-dom'
import { AuthContext } from '../../contexts/AuthContext'
import Header from "../../components/Header"
import BarraLateral from '../../components/BarraLateral'
import Footer from '../../components/Footer'
import Loading from '../../components/Loading'
import { FaUsers, FaSearch, FaClipboardList, FaRegSadTear } from 'react-icons/fa'
import img from '../../assets/semfoto.png'
import { Input } from '../../components/Input'
import { BsFillCheckCircleFill } from 'react-icons/bs'
import { MdCancel } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { IoArrowBack } from 'react-icons/io5'
import { IoIosCheckmark, IoMdSearch } from "react-icons/io"
import { setupApiClient } from '../../services/api'
import { toast } from 'react-toastify'
import { mask, unMask } from 'remask'
import { cepMask, telMask, cpfMask, converterDataParaTexto, emailTest } from '../../utils/utils'
import { cpf as validCPF, cnpj } from 'cpf-cnpj-validator'
import { calculaIdade } from '../../utils/utils'
import { Button } from '../../components/Button'
import { AiFillPrinter } from 'react-icons/ai'
import ModalOrcamento from '../../components/ModalOrcamentos'
import ModalAgenda, { AtendimentoProps } from '../../components/ModalAgenda'


export interface orcamentosPacienteProps{
    UUID: string
    DATA_AGENDA: string
    TRATAMENTO: string
    OBSERVACOES: string
    VALORCOBRADO: number,
    TOTALPAGAR: number
    TIPOATENDIMENTO: number
    ID_PACIENTE: number
    NOMEPACIENTE: string
    FORMAPAGAMENTO: string
    OBSPAGAMENTO: string
}

export default function NovoPaciente(){
    const { user } = useContext(AuthContext)
    const navigate = useNavigate()
    const { id } = useParams()

    const [placeHolder, setPlaceHolder] = useState('000.000.000-00')
    const [tabselecionada, setTabSelecionada] = useState(1)
    const [isLoading, setIsLoading] = useState(false)
    const [fotoPaciente, setFotoPaciente] = useState(null)
    const [dataCad, setDataCad] = useState('')
    const [data_atualizado, setData_atualizado] = useState('')
    const [url_foto, setUrl_Foto] = useState('')
    const [nome, setNome] = useState('')
    const [apelido, setApelido] = useState('')
    const [endereco, setEndereco] = useState('')
    const [numero, setNumero] = useState('')
    const [bairro, setBairro] = useState('')
    const [complemento, setComplemento] = useState('')
    const [cidade, setCidade] = useState('')
    const [cep, setCep] = useState('')
    const [ufSelected, setUfSelected] = useState('')
    const [telefone, setTelefone] = useState('')
    const [celular, setCelular] = useState('')
    const [tipoCliente, setTipoCliente] = useState('')
    const [cpf, setCPF] = useState('')
    const [rg, setRG] = useState('')
    const [nascimento, setNascimemto] = useState('')
    const [idadePaciente, setIdadePaciente] = useState(0)
    const [email, setEmail] = useState('')
    const [contato, setContato] = useState('')
    const [observacoes, setObservacoes] = useState('')
    const [cpfvalido, setCpfValido] = useState(false)
    
    const [cpfResp, setCPFResp] = useState('')
    const [cpfRespValido, setCPFRespValido] = useState(false)
    const [nomeResp, setNomeResp] = useState('')
    const [nascResp, setNascResp] = useState('')
    const [idadeResp, setIdadeResp] = useState(0)
    const [celularResp, setCelularResp] = useState('')

    const [datainicial, setDataInicial] = useState('')
    const [datafinal, setDataFinal] = useState('')
    const [orcTemporario, setOrcTemporario] = useState<orcamentosPacienteProps[]>([])
    const [orcamentos, setOrcamentos] = useState<orcamentosPacienteProps[]>([])
    const [orcamento, setOrcamento] = useState<orcamentosPacienteProps>()
    const [modalOrcamento, setModalOrcamento] = useState(false)
    const [modalAgenda, setModalAgenda] = useState(false)
    const [dadosConsulta, setDadosConulta] = useState<AtendimentoProps>()

    useEffect( () => {
        async function getPaciente(){
            if(id){
                try{
                    const email = localStorage.getItem('@emailMinhaClinica')
                    const apiCliente = setupApiClient()

                    const response = await apiCliente.get('/detalhepaciente',{
                        params:{
                            email: email, 
                            id: id
                        }
                    })

                    const { NOME, APELIDO, DATACADASTRO, ENDERECO, NUMERO,
                            BAIRRO, COMPLEMENTO, CIDADE, CEP, UF, TELEFONE,
                            CELULAR, TIPOCLIENTE, CPF, RG, NASCIMENTO, EMAIL,
                            CONTATO, OBSERVACOES, URLFOTO, ATUALIZADO, NOMERESP,
                            NASCRESP, CPFRESP, CELRESP } = response.data

                    let dtNasc = NASCIMENTO !== null ? NASCIMENTO.substring(0,10) : ''
                    let dtNascResp = NASCRESP !== null ? NASCRESP.substring(0,10) : ''

                    setNome(NOME)
                    setApelido(APELIDO)
                    setDataCad(converterDataParaTexto(DATACADASTRO))
                    setData_atualizado(ATUALIZADO === null ? '' : converterDataParaTexto(ATUALIZADO))
                    setEndereco(ENDERECO)
                    setNumero(NUMERO)
                    setBairro(BAIRRO)
                    setComplemento(COMPLEMENTO)
                    setCidade(CIDADE)
                    setCep(CEP)
                    setUfSelected(UF)
                    setTelefone(TELEFONE)
                    setCelular(CELULAR)
                    setTipoCliente(TIPOCLIENTE)
                    setCPF(CPF)
                    setRG(RG)
                    setNascimemto(dtNasc)
                    setEmail(EMAIL)
                    setContato(CONTATO)
                    setObservacoes(OBSERVACOES)
                    setUrl_Foto(URLFOTO)
                    setCpfValido(CPF ? true : false)
                    setNomeResp(NOMERESP)
                    setNascResp(dtNascResp)
                    setCPFResp(CPFRESP == null ? '' : CPFRESP)
                    setCelularResp(CELRESP)
                    setCPFRespValido(CPFRESP ? true : false)

                    dtNasc != '' ? setIdadePaciente(calculaIdade(new Date(NASCIMENTO), new Date())) : setIdadePaciente(0)   
                    dtNascResp != '' ? setIdadeResp(calculaIdade(new Date(NASCRESP), new Date())) : setIdadeResp(0)    

                } catch(err){
                    console.log(err.response)
                    toast.error('Erro buscando dados do paciente!')
                }

            } else {
                setDataCad(new Date().toLocaleDateString('pt-br'))
            }
        }

        getPaciente()

    },[])

    useEffect(() => {
        let arr: orcamentosPacienteProps[] = []
        if(orcTemporario.length > 0){
            orcTemporario.map( (or) => {

                const idx = arr.findIndex(o => o?.UUID == or?.UUID)
                if(idx == -1){
                    arr.push(or)
                }
            })
        }
        setOrcamentos(arr)

    },[orcTemporario])

    async function handleCep(){
        if(cep == ''){
            return
        }

        await fetch(`https://viacep.com.br/ws/${cep.replace('-','').replace('.','')}/json/`)
        .then( (response) => response.json()) 
        .then( (response) => {
            setEndereco(response?.logradouro.toUpperCase())
            setBairro(response?.bairro.toUpperCase())    
            setCidade(response?.localidade.toUpperCase())
            setUfSelected(response?.uf)
            document.getElementById('num').focus()
        })
        .catch( () => {
            setEndereco('')
            setBairro('')
            setCidade('')
            toast.warn('Erro buscando o cep!')
        })     
    }

    function setIdade(e:string, idadeResp: boolean){
        if(idadeResp){
            setIdadeResp(calculaIdade(new Date(e), new Date()))
            setNascResp(e)
        } else {
            setIdadePaciente(calculaIdade(new Date(e), new Date()))
            setNascimemto(e)
        }
    }

    function handleCancelar(){
        navigate('/pacientes')
    }

    async function handleSalvar(e: FormEvent){
        e.preventDefault()

        if(nome === ''){
            toast.warn('Digite nome do paciente!')
            document.getElementById('nome').focus()
            return
        }

        if(cpf !== ''){
            if(!cpfvalido){
                toast.warn('CPF/CNPJ inválido!')
                document.getElementById('cpf').focus()
                return                
            }
        }

        if(cpfResp !== ''){
            if(!cpfRespValido){
                toast.warn('CPF do responsável inválido!')
                return                
            }
        }

        if(email !== ''){
            if(!emailTest.test(email)){
                toast.warn('E-mail inválido!')
                document.getElementById('email').focus()
                return
            }
        }

        try{
            setIsLoading(true)
            const apiClient = setupApiClient()

            let dtNasc = ''
            let dtNascResp = ''

            if (nascimento !== ''){
                const nasc = nascimento.split('-')
    
                if (nasc.length !== 0){
                    dtNasc = nasc[2]+'/'+nasc[1]+'/'+nasc[0]
                }
            }

            if (nascResp !== ''){
                const nasc = nascResp.split('-')
    
                if (nasc.length !== 0){
                    dtNascResp = nasc[2]+'/'+nasc[1]+'/'+nasc[0]
                }
            }

            if(id){
                const req = new FormData()
                req.append('userid', user?.id)
                req.append('id', id)
                req.append('nome', nome)
                req.append('apelido', apelido)
                req.append('endereco', endereco)
                req.append('numero', numero)
                req.append('bairro', bairro)
                req.append('complemento', complemento)
                req.append('cidade', cidade)
                req.append('cep', cep)
                req.append('uf', ufSelected)
                req.append('telefone', telefone)
                req.append('celular', celular)
                req.append('tipocliente', tipoCliente)
                req.append('cpf', cpf)
                req.append('rg', rg)
                req.append('nascimento', dtNasc)
                req.append('email', email)
                req.append('contato', contato)
                req.append('observacoes', observacoes)

                req.append('nomeresp', nomeResp)
                req.append('nascresp', dtNascResp)
                req.append('cpfresp', cpfResp)
                req.append('celresp', celularResp)

                if(fotoPaciente){
                    req.append('file', fotoPaciente)
                }

                const response = await apiClient.put('/paciente', req)

                if(response.data?.COD == 200){
                    toast.success('Paciente atualizado!')
                    navigate('/pacientes')
                    setIsLoading(false)               
                } else 
                if(response.data?.COD == 405){
                    setIsLoading(false)
                    toast.error('Esse CPF é de '+response.data?.NOME)
                    return  
                } else {
                    setIsLoading(false)
                    console.log(response.data?.COD+'-'+response.data?.MSG)
                    toast.error(response.data?.COD+'-'+response.data?.MSG)
                    return                     
                }
            } else {
                const req = new FormData()
                req.append('userid', user?.id)
                req.append('nome', nome)
                req.append('apelido', apelido)
                req.append('endereco', endereco)
                req.append('numero', numero)
                req.append('bairro', bairro)
                req.append('complemento', complemento)
                req.append('cidade', cidade)
                req.append('cep', cep)
                req.append('uf', ufSelected)
                req.append('telefone', telefone)
                req.append('celular', celular)
                req.append('tipocliente', tipoCliente)
                req.append('cpf', cpf)
                req.append('rg', rg)
                req.append('nascimento', dtNasc)
                req.append('email', email)
                req.append('contato', contato)
                req.append('observacoes', observacoes)

                req.append('nomeresp', nomeResp)
                req.append('nascresp', dtNascResp)
                req.append('cpfresp', cpfResp)
                req.append('celresp', celularResp)

                if(fotoPaciente){
                    req.append('file', fotoPaciente)
                }

                const response = await apiClient.post('/paciente', req)

                if(response.data?.COD == 201){
                    toast.success('Paciente cadastrado!')
                    navigate('/pacientes')
                    setIsLoading(false)               
                } else {
                    setIsLoading(false)
                    console.log(response.data?.COD+'-'+response.data?.MSG)
                    toast.error('Esse CPF é de '+response.data?.NOME)
                    return  
                }
            }

        } catch(err){
            console.log(err.response)
            setIsLoading(false)  
            return  
        }
    }

    function handleFoto(e: ChangeEvent<HTMLInputElement>){

        if(!e.target.files){
            return
        }

        const img = e.target.files[0];

        if (!img){
            return;
        }

        const imgSize = img.size / 1024;

        if(imgSize > 1024){
            toast.error('Ops, tamanho da imagem acima de 1mb, reduza o tamanho!')
            return
        }

        if (img.type === 'image/jpeg' || img.type === 'image/png' || img.type === 'image/jpg'){
            setFotoPaciente(img)
            setUrl_Foto(URL.createObjectURL(img))
        } else {
            toast.error('Imagens suportadas, JPEG, PNG, JPG!')
            return            
        }      
    }

    function onChangeCep(e: ChangeEvent<HTMLInputElement>){
        const originalValue = unMask(e.target.value)
        const maskedValue = mask(originalValue, cepMask)
        setCep(maskedValue)
    }

    function onChangeFone(e: ChangeEvent<HTMLInputElement>){
        const originalValue = unMask(e.target.value)
        const maskedValue = mask(originalValue, telMask)
        setTelefone(maskedValue)
    }

    function onChangeCel(e: ChangeEvent<HTMLInputElement>, celResp: boolean){
        const originalValue = unMask(e.target.value)
        const maskedValue = mask(originalValue, telMask)

        if(celResp){
            setCelularResp(maskedValue)
        } else {
            setCelular(maskedValue)
        }
    }

    function onChangeTipo(e: ChangeEvent<HTMLSelectElement>){
        setTipoCliente(e.target.value)
        { e.target.value === 'FISICA' ? setPlaceHolder('000.000.000-00') : setPlaceHolder('00.000.000/0000-00') }
    }

    function onChangeCPF(e: ChangeEvent<HTMLInputElement>, cpfResp: boolean){
        const originalValue = unMask(e.target.value)
        const maskedValue = mask(originalValue, cpfMask)

        if(cpfResp){
            setCPFResp(maskedValue)
    
            if(originalValue.length > 11){
                cnpj.isValid(originalValue) ? setCPFRespValido(true) : setCPFRespValido(false) 
            } else {
                validCPF.isValid(originalValue) ? setCPFRespValido(true) : setCPFRespValido(false) 
            }
        } else {
            setCPF(maskedValue)
    
            if(originalValue.length > 11){
                cnpj.isValid(originalValue) ? setCpfValido(true) : setCpfValido(false) 
            } else {
                validCPF.isValid(originalValue) ? setCpfValido(true) : setCpfValido(false) 
            }
        }
    }

    function handleTab(value: number){
        let tablinks = document.getElementsByClassName('tablinks');
        for (let i = 0; i < tablinks.length; i++) {
          tablinks[i].className = tablinks[i].className.replace(' active', '');
        }

        setTabSelecionada(value)
        switch(value){
            case 1: 
                document.getElementById('tabpaciente').className += ' active';
                break;
            case 2:
                document.getElementById('tabinforesp').className += ' active';
                break;
            case 3:
                document.getElementById('taborcamento').className += ' active';
                break;
            default:
                document.getElementById('tabpaciente').className += ' active';
                break;                  
        }
    }

    async function handlePesquisar(){
        if(datafinal == '' && datafinal == ''){
            toast.warning('Escolha o período de busca!')
            return 
        }

        try{
            setOrcamentos([])
            setIsLoading(true)
            const apiClient = setupApiClient()

            const response = await apiClient.get('/orcamentospaciente',{
                params:{
                    idempresa: user?.id,
                    idpaciente: id,
                    datainicio: converterDataParaTexto(datainicial),
                    datafinal: converterDataParaTexto(datafinal)
                }
            })

            setOrcTemporario(response.data)
            setIsLoading(false)
           
        } catch(err){
            console.log(err)
            toast.error('Erro buscando orçamentos! :(')
            setIsLoading(false)
        }        
    }

    function handleOrcamento(orc: orcamentosPacienteProps){
        setOrcamento(orc)
        setModalOrcamento(true)
    }

    function handleAprovar(orc: orcamentosPacienteProps){
        const userid = sessionStorage.getItem('@userid_clinica')
        let obj: AtendimentoProps = null

        obj = {
            DATA_AGENDA: new Date().toISOString(),
            ID_EMPRESA: Number(userid),
            ID_PACIENTE: orc?.ID_PACIENTE,
            OBSERVACOES: orc?.OBSERVACOES,
            TIPOATENDIMENTO: String(orc?.TIPOATENDIMENTO),
            TRATAMENTO: orc?.TRATAMENTO,
            UUID: orc?.UUID
        }

        setDadosConulta(obj)
        setModalAgenda(true)
    }

    if(isLoading){
        return(
            <main>
                <Header/>
                <BarraLateral/>            
                <Loading/>
                <Footer/>
            </main>
        )
    }
    
    return(
        <main>
            <Header/>
            <BarraLateral/>
            <section className='content-listapaciente'>

                <div className='container-titulo-paciente'>
                    <div className='textheader'>
                        <button className='btnBack' title='VOLTAR' onClick={handleCancelar}>
                            <IoArrowBack color='#555' size={25}/>
                        </button>
                        <FaUsers color='#555' size={25}/>
                        <h3>{id ? 'Você está editando cadastro' : 'Você está em novo cadastro'}</h3> 
                    </div>               
                </div>

                <div className='tabs'>
                    <button
                        className='tablinks active'
                        id='tabpaciente'
                        onClick={() => handleTab(1)}
                        >
                        DADOS DO PACIENTE
                    </button>

                    <button
                        className='tablinks'
                        id='tabinforesp'
                        onClick={() => handleTab(2)}
                    >
                        INFORMAÇÕES DO RESPONSÁVEL
                    </button>

                    <button
                        className='tablinks'
                        id='taborcamento'
                        onClick={() => handleTab(3)}
                        >
                        ORÇAMENTOS REALIZADOS
                    </button>
                </div> 

                { tabselecionada == 1 && (
                    <form className='formpaciente' autoComplete='none' onSubmit={handleSalvar}>
                        <div className='form-div-linha'>
                            <label className='foto-paciente'>
                                <img src={url_foto ? url_foto : img} alt='Foto' width={90}/>
                                <input type='file' accept="image/png, image/jpeg, image/jpg" onChange={handleFoto}/> 
                            </label>

                            <div className='form-linha'>

                                <div className='div-inputs'>
                                    <label htmlFor="nome">NOME</label>
                                    <Input
                                        style={ {width:'630px'} } 
                                        id='nome' 
                                        placeholder='DIGITE O NOME'
                                        type='text'
                                        value={nome}
                                        onChange={ (e) => setNome(e.target.value)}
                                    />
                                </div>

                                <div className='form-linha1'>

                                    <div className='div-inputs'>
                                        <label htmlFor="apelido">APELIDO</label>
                                        <Input 
                                            style={{width:'350px'}}
                                            id='apelido' 
                                            placeholder='Digite o apelido "opcional"'
                                            type='text'
                                            value={apelido}
                                            onChange={ (e) => setApelido(e.target.value)}
                                        />
                                    </div>

                                    <div className='div-inputs'>
                                        <label htmlFor="data">PACIENTE DESDE</label>
                                        <Input 
                                            disabled={true}
                                            style={ {width:'120px', cursor:'not-allowed'} } 
                                            id='data' 
                                            placeholder='00/00/0000'
                                            type='text'
                                            value={dataCad}
                                        />                                
                                    </div>

                                    <div className='div-inputs'>
                                        <label htmlFor="dataat">ÚLTIMA ATUALIZAÇÃO</label>
                                        <Input 
                                            disabled={true}
                                            style={ {width:'120px', cursor:'not-allowed'} } 
                                            id='dataat' 
                                            placeholder='00/00/0000'
                                            type='text'
                                            value={data_atualizado}
                                        />                                
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='form-div-normal'>
                            <div className='div-inputs'>
                                <label htmlFor="end">ENDEREÇO</label>
                                <Input 
                                    style={{width:'313px'}}
                                    id='end' 
                                    placeholder='Endereço'
                                    type='text'
                                    value={endereco}
                                    onChange={ (e) => setEndereco(e.target.value)}
                                />
                            </div> 

                            <div className='div-inputs'>
                                <label htmlFor="num">NÚMERO</label>
                                <Input 
                                    style={ {width:'70px'}}
                                    id='num' 
                                    placeholder='nº'
                                    type='text'
                                    value={numero}
                                    onChange={ (e) => setNumero(e.target.value)}
                                />
                            </div>

                            <div className='div-inputs'>
                                <label htmlFor="bairro">BAIRRO</label>
                                <Input 
                                    style={ {width:'200px'}}
                                    id='bairro' 
                                    placeholder='Bairro'
                                    type='text'
                                    value={bairro}
                                    onChange={ (e) => setBairro(e.target.value)}
                                />
                            </div>

                            <div className='div-inputs'>
                                <label htmlFor="comp">COMPLEMENTO</label>
                                <Input 
                                    style={ {width:'120px'}}
                                    id='comp' 
                                    placeholder='Complemento'
                                    type='text'
                                    value={complemento}
                                    onChange={ (e) => setComplemento(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className='form-div-normal'>
                            <div className='div-inputs'>
                                <label htmlFor="cidade">CIDADE</label>
                                <Input 
                                    style={ {width:'250px'}}
                                    id='cidade' 
                                    placeholder='Cidade'
                                    type='text'
                                    value={cidade}
                                    onChange={ (e) => setCidade(e.target.value)}
                                />
                            </div> 

                            <div className='div-inputs'>
                                <label htmlFor="cep">CEP</label>
                                <div className='div-cpf'>
                                    <Input 
                                        style={ {width:'90px'}}
                                        id='cep' 
                                        placeholder='00.000-000'
                                        type='text'
                                        value={cep}
                                        onChange={onChangeCep}
                                    />
                                    <IoMdSearch 
                                        size={20} 
                                        id='search-cep' 
                                        color='#000' 
                                        onClick={handleCep} 
                                        title='Buscar CEP'
                                    />
                                </div>
                            </div>

                            <div className='div-inputs'>
                                <label htmlFor="uf">UF</label>
                                <select 
                                    style={ {width:'60px',borderColor:'#ccc'}}
                                    id='uf' 
                                    value={ufSelected}
                                    onChange={ (e) => setUfSelected(e.target.value)}
                                >
                                    <option value="AC">AC</option>
                                    <option value="AL">AL</option>
                                    <option value="AP">AP</option>
                                    <option value="AM">AM</option>
                                    <option value="BA">BA</option>
                                    <option value="CE">CE</option>
                                    <option value="ES">ES</option>
                                    <option value="GO">GO</option>
                                    <option value="MA">MA</option>
                                    <option value="MT">MT</option>
                                    <option value="MS">MS</option>
                                    <option value="MG">MG</option>
                                    <option value="PA">PA</option>
                                    <option value="PB">PB</option>
                                    <option value="PR">PR</option>
                                    <option value="PE">PE</option>
                                    <option value="PI">PI</option>
                                    <option value="RJ">RJ</option>
                                    <option value="RN">RN</option>
                                    <option value="RS">RS</option>
                                    <option value="RO">RO</option>
                                    <option value="RR">RR</option>
                                    <option value="SC">SC</option>
                                    <option value="SP">SP</option>
                                    <option value="SE">SE</option>
                                    <option value="TO">TO</option>
                                    <option value="DF">DF</option>
                                </select>
                            </div>

                            <div className='div-inputs'>
                                <label htmlFor="fone">TELEFONE</label>
                                <Input 
                                    style={ {width:'130px'}}
                                    id='fone' 
                                    placeholder='(00) 0000-0000'
                                    type='text'
                                    value={telefone}
                                    onChange={onChangeFone}
                                />
                            </div>

                            <div className='div-inputs'>
                                <label htmlFor="cel">CELULAR</label>
                                <Input 
                                    style={ {width:'130px'}}
                                    id='cel' 
                                    placeholder='(00) 0 0000-0000'
                                    type='text'
                                    value={celular}
                                    onChange={(e) => onChangeCel(e, false)}
                                />
                            </div>
                        </div>

                        <div className='form-div-normal'>
                            <div className='div-inputs'>
                                <label htmlFor="tipo">TIPO CLIENTE</label>
                                <select 
                                    style={ {width:'176px',borderColor:'#ccc'}}
                                    id='tipo' 
                                    value={tipoCliente}
                                    onChange={onChangeTipo}
                                >
                                    <option value="FISICA">FISICA</option>
                                    <option value="JURIDICA">JURIDICA</option>
                                </select>
                            </div>

                            <div className='div-inputs'>
                                <label htmlFor="cpf">CPF / CNPJ</label>
                                <div className='div-cpf'>
                                    <Input 
                                        style={ {width:'155px'}}
                                        id='cpf' 
                                        placeholder={placeHolder}
                                        type='text'
                                        value={cpf}
                                        onChange={(e) => onChangeCPF(e, false)}
                                    />
                                    { cpfvalido ? (
                                        <IoIosCheckmark size={25} color='green'/>
                                    ) : (
                                        <MdCancel color='red' size={25}/>
                                    )}
                                </div>
                            </div>

                            <div className='div-inputs'>
                                <label htmlFor="rg">RG / IE</label>
                                <Input 
                                    style={ {width:'195px'}}
                                    id='rg' 
                                    placeholder='000000000'
                                    type='text'
                                    value={rg}
                                    onChange={ (e) => setRG(e.target.value)}
                                />
                            </div>

                            <div className='div-inputs'>
                                <label htmlFor="nasc">NASCIMENTO</label>
                                <Input 
                                    style={ {width:'150px'}}
                                    id='nasc' 
                                    placeholder='00/00/0000'
                                    type='date'
                                    value={nascimento}
                                    onChange={ (e) => setIdade(e.target.value, false)}
                                />
                                <span>{idadePaciente > 0 ? `${idadePaciente} anos de idade` : ''}</span>
                            </div>
                        </div>

                        <div className='form-div-normal'>
                            <div className='div-inputs'>
                                <label htmlFor="email">E-MAIL</label>
                                <Input 
                                    style={ {width:'350px',textTransform:'lowercase'}}
                                    id='email' 
                                    placeholder='nome@dominio.com'
                                    type='email'
                                    value={email}
                                    onChange={ (e) => setEmail(e.target.value)}
                                />
                            </div>

                            <div className='div-inputs'>
                                <label htmlFor="rg">CONTATO</label>
                                <Input 
                                    style={ {width:'395px'}}
                                    id='rg' 
                                    placeholder='Contato'
                                    type='text'
                                    value={contato}
                                    onChange={ (e) => setContato(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className='form-div-linha'>
                            <div className='div-inputs'>
                                <label htmlFor="obs">OBSERVAÇÕES</label>
                                <textarea 
                                    id='obs' 
                                    placeholder='Observações'
                                    value={observacoes}
                                    onChange={ (e) => setObservacoes(e.target.value)}
                                />
                            </div>

                        </div>

                        <div className='div-btn-paciente'>
                            <button 
                                id='salvar'
                                type='submit'
                            >
                                <BsFillCheckCircleFill color='#FFF' size={20}/>
                                SALVAR
                            </button>

                            <button onClick={handleCancelar}>
                                <MdCancel color='#FFF' size={20}/>
                                CANCELAR
                            </button>
                        </div>

                    </form>  
                )}

                { tabselecionada == 2 && (
                    <div className='div-tab2'>
                        <div className='div-inputs'>
                            <label htmlFor="nomeresp">NOME DO RESPONSÁVEL</label>
                            <Input 
                                style={ {width:'250px'}}
                                id='nomeresp' 
                                placeholder='Nome'
                                type='text'
                                value={nomeResp}
                                onChange={ (e) => setNomeResp(e.target.value)}
                            />
                        </div>

                        <div className='div-inputs'>
                            <label htmlFor="nascresp">DT NASCIMENTO</label>
                            <Input 
                                style={ {width:'130px'}}
                                id='nascresp' 
                                placeholder='00/00/0000'
                                type='date'
                                value={nascResp}
                                onChange={(e) => setIdade(e.target.value, true)}
                            />
                            <span>{idadeResp > 0 ? `${idadeResp} anos de idade` : ''}</span>
                        </div>

                        <div className='div-inputs'>
                            <label htmlFor="cpfresp">CPF</label>
                            <div className='div-cpf'>
                                <Input 
                                    style={ {width:'120px'}}
                                    id='cpfresp' 
                                    placeholder={placeHolder}
                                    type='text'
                                    value={cpfResp}
                                    onChange={(e) => onChangeCPF(e, true)}
                                />
                                { cpfRespValido ? (
                                    <IoIosCheckmark size={25} color='green'/>
                                ) : (
                                    <MdCancel color='red' size={25}/>
                                )}
                            </div>
                        </div>

                        <div className='div-inputs'>
                            <label htmlFor="cel">CELULAR</label>
                            <Input 
                                style={ {width:'120px'}}
                                id='cel' 
                                placeholder='(00) 0 0000-0000'
                                type='text'
                                value={celularResp}
                                onChange={(e) => onChangeCel(e, true)}
                            />
                        </div>
                    </div>
                )}

                { tabselecionada == 3 && (
                    <div className='div-tab3'>
                        <div className='container-filtro-prontuario'>
                            <div className='div-filtro-inputs'>
                                <div className='div-inputs'>
                                    <label htmlFor="nasc">DATA INICIAL</label>
                                    <Input 
                                        style={ {width:'120px'}}
                                        id='dtinicial' 
                                        placeholder='00/00/0000'
                                        type='date'
                                        value={datainicial}
                                        onChange={ (e) => setDataInicial(e.target.value)}
                                    />
                                </div>

                                <div className='div-inputs'>
                                    <label htmlFor="nasc">DATA FINAL</label>
                                    <Input 
                                        style={ {width:'120px'}}
                                        id='dtfinal' 
                                        placeholder='00/00/0000'
                                        type='date'
                                        value={datafinal}
                                        onChange={ (e) => setDataFinal(e.target.value)}
                                    />
                                </div>
                            </div>

                            <div className='container-prontbtn'>
                                <Button id='btnPesqPront' onClick={handlePesquisar}>
                                    <FaSearch color='#fff' size={15}/>
                                    PESQUISAR
                                </Button>

                                <Button id='btnPrintPront' >
                                    <AiFillPrinter color='#fff' size={20}/>
                                    IMPRIMIR
                                </Button>
                            </div>

                            <div className='div-qtdatendimento'>
                                <span>QUANTIDADE DE ORÇAMENTOS: {orcamentos.length}</span>
                            </div>
                        </div>

                        <div className='div-container-cards'>
                            <div className='card-linha'>
                                {orcamentos.length <= 0 ? (
                                    <div className='div-semprontuario'>
                                        <FaRegSadTear color='#555' size={100}/>
                                        <span>Não existe orçamentos!</span>
                                    </div>
                                ) : orcamentos.map( (atend) => {
                                    let data = atend?.DATA_AGENDA.split('-')
                                    return(
                                        <div key={atend?.UUID} className='card'>
                                            <FaClipboardList color='blue' size={40}/> 
                                            <span>{atend?.TRATAMENTO}</span>
                                            <span style={{fontSize:'16px'}}>{data[2].substring(0,2)+'/'+data[1]+'/'+data[0]}</span>
                                            <button onClick={() => handleOrcamento(atend)}>
                                                VER DETALHES
                                            </button>
                                            <button className='btn-aprovar' onClick={() => handleAprovar(atend)}>
                                                APROVAR
                                            </button>     
                                        </div> 
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                )}

                { modalAgenda && (
                    <ModalAgenda
                        date=''
                        dadosatendimento={dadosConsulta}
                        onClose={() => setModalAgenda(false)}
                    />                                
                )}

                { modalOrcamento && (
                    <ModalOrcamento
                        orcamento={orcamento}
                        onClose={() => setModalOrcamento(false)}
                    />
                )}    

            </section>
            <Footer/>
        </main>
    )
}