import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { UserProps } from "../../contexts/AuthContext";


interface PdfProps{
    dadosClinica: UserProps
    logob64?: string
    dadosPaciente: PacientePDFProps
}

interface PacientePDFProps{
    nome: string
    datacad: string
    data_at: string
    dataass: string
    saudeGeral: SaudeGeralProps
    saudeOP: SaudeOpcoesProps
    saudeOdon: saudeOdontologica
}

interface SaudeGeralProps{
    sa1: string
    sa2: string
    sa3: string
    sa4: string
    sa5: string
    sa6: string
    sa7: string
    sa8: string
    sa9: string
    sa10: string
    sa11: string
    sa12: string
    sa13: string
}

interface SaudeOpcoesProps{
    saop1: boolean
    saop2: boolean
    saop3: boolean
    saop4: boolean
    saop5: boolean
    saop6: boolean
    saop7: boolean
    saop8: boolean
    saop9: boolean
    saop10: boolean
    saop11: boolean
    saop12: boolean
    saop13: boolean  
    saop14: boolean 
    saop15: boolean 
    saop16: boolean 
    saop17: boolean   
}

interface saudeOdontologica{
    od1: string
    od2: string
    od3: string
    od4: string
    od5: string
    od6: string
    od7: string
    od8: string
    od9: string
    od10: string
    od11: string
    od12: string
    od13: string    
}

export default function GerarPDF({ dadosClinica, logob64, dadosPaciente }: PdfProps){
    
    pdfMake.vfs = pdfFonts.pdfMake.vfs

    pdfMake.createPdf({
        pageSize:'A4',
        pageMargins: [15,10,15,30],
        content: [
          {
            text: dadosClinica?.nome,
            alignment:'center',
            fontSize:18,
            style:'header',
            italics:true,
            bold:true
          },
          {
            text: dadosClinica?.endereco+', '+dadosClinica?.numero+' - '+dadosClinica?.bairro,
            alignment:'center',
            fontSize:10,
            italics:true,
            bold:false
          },
          {
            text: dadosClinica?.cidade+'/'+dadosClinica?.uf+' - CEP: '+dadosClinica?.cep,
            alignment:'center',
            fontSize:10,
            italics:true,
            bold:false
          },
          {
            text: 'Telefone: '+dadosClinica?.telefone+' - Celular: '+dadosClinica?.celular,
            alignment:'center',
            fontSize:10,
            italics:true,
            bold:false,
            marginBottom:30
          },
          {
            text: 'Ficha Anamnese',
            marginBottom: 15,
            alignment:'center',
            fontSize:14,
            italics:true,
          },
          {
			      text: 'Paciente',
			      style: 'header',
            fontSize:11,
            italics:true
		      },
          {
            text: dadosPaciente?.nome,
            fontSize:9,
          },
          {
			      text: 'Data de cadastro e atualização',
			      style: 'header',
            marginTop:10,
            fontSize:11,
            italics: true
		      }, 
          {
            text: 'Cadastro: '+dadosPaciente?.datacad + ' - Última atualização: '+ dadosPaciente?.data_at,
            fontSize: 9        
          },
          {
			      text: 'Saúde geral',
			      style: 'header',
            marginTop:15,
            marginBottom:10,
            fontSize:12,
            italics: true,
            bold: true,
            alignment:'center'
		      },
          { 
            text: '1. Recebeu tratamento médico nos últimos 2 anos?\n'+
                  'Informe abaixo em que especialidade, motivo, nome do médico e quanto tempo hospitalizado!',
            fontSize: 10 ,
            marginBottom:2,
            bold:true       
          },
          {
            text: 'Resp: '+dadosPaciente?.saudeGeral?.sa1,
            fontSize: 9  
          },
          { 
            text: '2. Toma medicamentos, quais?',
            fontSize: 10 ,
            marginTop:10,
            marginBottom:2,
            bold: true       
          },
          {
            text: 'Resp: '+dadosPaciente?.saudeGeral?.sa2,
            fontSize: 9  
          },
          { 
            text: '3. Tem alergia a algum medicamento ou anestésico, qual?',
            fontSize: 10 ,
            marginTop:10,
            marginBottom:2,
            bold: true      
          },
          {
            text: 'Resp: '+dadosPaciente?.saudeGeral?.sa3,
            fontSize: 9  
          },
          { 
            text: '4. Já fez alguma cirurgia e cirurgias bucais, quando?',
            fontSize: 10 ,
            marginTop:10,
            marginBottom:2,
            bold:true       
          },
          {
            text: 'Resp: '+dadosPaciente?.saudeGeral?.sa4,
            fontSize: 9  
          },
          { 
            text: '5. Costuma sangrar muito quando se machuca e demora para cicatrizar?',
            fontSize: 10 ,
            marginTop:10,
            marginBottom:2,
            bold: true       
          },
          {
            text: 'Resp: '+dadosPaciente?.saudeGeral?.sa5,
            fontSize: 9  
          },
          { 
            text: '6. Já fez transfusão de sangue?',
            fontSize: 10 ,
            marginTop:10,
            marginBottom:2,
            bold: true      
          },
          {
            text: 'Resp: '+dadosPaciente?.saudeGeral?.sa6,
            fontSize: 9  
          },
          { 
            text: '7. Você fuma, quantos cigarros/dia?',
            fontSize: 10 ,
            marginTop:10,
            marginBottom:2,
            bold: true       
          },
          {
            text: 'Resp: '+dadosPaciente?.saudeGeral?.sa7,
            fontSize: 9  
          },
          { 
            text: '8. Toma bebidas alcoólicas frequentemente?',
            fontSize: 10 ,
            marginTop:10,
            marginBottom:2,
            bold: true      
          },
          {
            text: 'Resp: '+dadosPaciente?.saudeGeral?.sa8,
            fontSize: 9  
          },
          { 
            text: '9. Costuma a sentir tontura ou desmaio?',
            fontSize: 10 ,
            marginTop:10,
            marginBottom:2,
            bold: true      
          },
          {
            text: 'Resp: '+dadosPaciente?.saudeGeral?.sa9,
            fontSize: 9  
          },
          { 
            text: '10. Tem ou teve algumas doenças abaixo?',
            fontSize: 10 ,
            marginTop:10,
            marginBottom:2,
            bold: true      
          },
          {
            alignment:'left',
            columns:[
                {
                    text:`Problemas cardíacos? - ${dadosPaciente?.saudeOP?.saop1 ? 'SIM' : 'NÃO'}\n`+
                        `Hepatite? - ${dadosPaciente?.saudeOP?.saop2 ? 'SIM' : 'NÃO'}\n`+
                        `Febre reumática? - ${dadosPaciente?.saudeOP?.saop3 ? 'SIM' : 'NÃO'}\n`+
                        `Problemas nervosos? - ${dadosPaciente?.saudeOP?.saop4 ? 'SIM' : 'NÃO'}\n`+
                        `Problemas respiratórios? - ${dadosPaciente?.saudeOP?.saop5 ? 'SIM' : 'NÃO'}\n`+
                        `HIV? - ${dadosPaciente?.saudeOP?.saop6 ? 'SIM' : 'NÃO'}`,
                        fontSize:9
                },
                {
                    text:`Pressão alta? - ${dadosPaciente?.saudeOP?.saop7 ? 'SIM' : 'NÃO'}\n`+
                        `Diabetes? - ${dadosPaciente?.saudeOP?.saop8 ? 'SIM' : 'NÃO'}\n`+
                        `Icterícia? - ${dadosPaciente?.saudeOP?.saop9 ? 'SIM' : 'NÃO'}\n`+
                        `Hepático? - ${dadosPaciente?.saudeOP?.saop10 ? 'SIM' : 'NÃO'}\n`+
                        `Tuberculose? - ${dadosPaciente?.saudeOP?.saop11 ? 'SIM' : 'NÃO'}\n`+
                        `Outras? - ${dadosPaciente?.saudeOP?.saop12 ? 'SIM' : 'NÃO'}`,
                        fontSize:9
                },
                {
                    text:`Anemia? - ${dadosPaciente?.saudeOP?.saop13 ? 'SIM' : 'NÃO'}\n`+
                        `Epilipsia? - ${dadosPaciente?.saudeOP?.saop14 ? 'SIM' : 'NÃO'}\n`+
                        `Reumatismo? - ${dadosPaciente?.saudeOP?.saop15 ? 'SIM' : 'NÃO'}\n`+
                        `Problemas renais? - ${dadosPaciente?.saudeOP?.saop16 ? 'SIM' : 'NÃO'}\n`+
                        `Depressão? - ${dadosPaciente?.saudeOP?.saop17 ? 'SIM' : 'NÃO'}\n`+
                        'Especifique no campo abaixo!',
                        fontSize:9
                }    
            ]
          },
          {
            text: 'Resp: '+dadosPaciente?.saudeGeral?.sa10,
            fontSize: 9,
            marginTop:5
          },
          { 
            text: '11. Tem fobias?',
            fontSize: 10 ,
            marginTop:10,
            marginBottom:2,
            bold: true       
          },
          {
            text: 'Resp: '+dadosPaciente?.saudeGeral?.sa11,
            fontSize: 9  
          },
          { 
            text: '12. Problemas alimentares?',
            fontSize: 10 ,
            marginTop:10,
            marginBottom:2,
            bold: true       
          },
          {
            text: 'Resp: '+dadosPaciente?.saudeGeral?.sa12,
            fontSize: 9  
          },
          { 
            text: '13. Está grávida, quanto tempo?',
            fontSize: 10 ,
            marginTop:10,
            marginBottom:2,
            bold: true       
          },
          {
            text: 'Resp: '+dadosPaciente?.saudeGeral?.sa13,
            fontSize: 9  
          },
          {
			      text: 'Odontológico',
			      style: 'header',
            marginTop:15,
            marginBottom:10,
            fontSize:12,
            italics: true,
            bold: true,
            alignment:'center'
		      },
          { 
            text: '1. Tem algum dente mole?',
            fontSize: 10 ,
            marginTop:10,
            marginBottom:2,
            bold: true      
          },
          {
            text: 'Resp: '+dadosPaciente?.saudeOdon?.od1,
            fontSize: 9  
          },
          { 
            text: '2. Mastiga dos dois lados da boca?',
            fontSize: 10 ,
            marginTop:10,
            marginBottom:2,
            bold: true       
          },
          {
            text: 'Resp: '+dadosPaciente?.saudeOdon?.od2,
            fontSize: 9  
          },
          { 
            text: '3. Seus dentes doem, quando?',
            fontSize: 10 ,
            marginTop:10,
            marginBottom:2,
            bold: true       
          },
          {
            text: 'Resp: '+dadosPaciente?.saudeOdon?.od3,
            fontSize: 9  
          },
          { 
            text: '4. Sente gosto ruim, mau hálito, quando?',
            fontSize: 10 ,
            marginTop:10,
            marginBottom:2,
            bold: true       
          },
          {
            text: 'Resp: '+dadosPaciente?.saudeOdon?.od4,
            fontSize: 9  
          },
          { 
            text: '5. Os alimentos se prendem entre os dentes, usa fio dental?',
            fontSize: 10 ,
            marginTop:10,
            marginBottom:2,
            bold: true       
          },
          {
            text: 'Resp: '+dadosPaciente?.saudeOdon?.od5,
            fontSize: 9  
          },
          { 
            text: '6. Que tipo de escova dental usa, e escova quantas vezes ao dia?',
            fontSize: 10 ,
            marginTop:10,
            marginBottom:2,
            bold: true      
          },
          {
            text: 'Resp: '+dadosPaciente?.saudeOdon?.od6,
            fontSize: 9  
          },
          { 
            text: '7. Sua gengiva sangra com facilidade, você já tratou?',
            fontSize: 10 ,
            marginTop:10,
            marginBottom:2,
            bold: true       
          },
          {
            text: 'Resp: '+dadosPaciente?.saudeOdon?.od7,
            fontSize: 9  
          },
          { 
            text: '8. Você morde os lábios, objetos, costuma roer unhas ou tem outro costume?',
            fontSize: 10 ,
            marginTop:10,
            marginBottom:2,
            bold: true       
          },
          {
            text: 'Resp: '+dadosPaciente?.saudeOdon?.od8,
            fontSize: 9  
          },
          { 
            text: '9. Repira normalmente pelo nariz, pela boca?',
            fontSize: 10 ,
            marginTop:10,
            marginBottom:2,
            bold: true       
          },
          {
            text: 'Resp: '+dadosPaciente?.saudeOdon?.od9,
            fontSize: 9  
          },
          { 
            text: '10. Range os dentes quando dorme, costuma apertar os dentes?',
            fontSize: 10 ,
            marginTop:10,
            marginBottom:2,
            bold: true       
          },
          {
            text: 'Resp: '+dadosPaciente?.saudeOdon?.od10,
            fontSize: 9  
          },
          { 
            text: '11. Ao abrir a boca sente dificuldade, dor ou ruído?',
            fontSize: 10 ,
            marginTop:10,
            marginBottom:2,
            bold: true       
          },
          {
            text: 'Resp: '+dadosPaciente?.saudeOdon?.od11,
            fontSize: 9  
          },
          { 
            text: '12. Sente dor na articulação, face ou ouvido?',
            fontSize: 10 ,
            marginTop:10,
            marginBottom:2,
            bold:true       
          },
          {
            text: 'Resp: '+dadosPaciente?.saudeOdon?.od12,
            fontSize: 9  
          },
          { 
            text: '13. Teve ou tem algum problema de saúde não mencionado neste questionário ou alguma outra informação que julgue importante?',
            fontSize: 10 ,
            marginTop:10,
            marginBottom:2,
            bold:true       
          },
          {
            text: 'Resp: '+dadosPaciente?.saudeOdon?.od13,
            fontSize: 9  
          },
          { 
            text: 'Declaro que as informações prestadas acima são verdadeiras!\n'+
            'Autorizo o tratamento odontológico, fotos e radiográfias necessárias para a manutenção e/ou recuperação da minha saúde bucal.',
            fontSize: 10 ,
            marginTop:30,
            marginBottom:2       
          },
          { 
            text: dadosPaciente?.dataass,
            fontSize: 10,
            marginTop:20,
            marginBottom:2       
          },
          { 
            text: '_______________________________________\n'+
            'Assinatura do paciente ou responsável',
            fontSize: 10,
            margin:[0,20,10,10],
            alignment:'right'       
          }  

        ],

        footer: function(currentPage, pageCount){
            return [
                {
                    text:'Sistema Minha clínica - pagina '+ currentPage.toString() + ' de ' + pageCount,
                    alignment:'right',
                    fontSize:7,
                    margin:[0,10,20,0]
                }
            ]
        }

    }).print();
}