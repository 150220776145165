import './index.css'
import { FaWindowClose } from 'react-icons/fa'
import { ProntuariosProps } from '../../pages/Prontuario'

interface modalProntProps{
    atendimento: ProntuariosProps
    onClose: () => void
}

export default function ModalProntuario({ atendimento, onClose }: modalProntProps){
    
    const data = atendimento?.DATA_AGENDA.split('-')
    
    return(
        <div className='box-modal'>
            <div className='content-modalprontuario'>

                <div className='grupo-btn'>
                    <button className='btn-close' onClick={onClose}>
                        <FaWindowClose size={20} color='#FFF'/>
                    </button>
                    <span>Atendimento do dia - {data[2].substring(0,2)+'/'+data[1]+'/'+data[0]}</span>
                </div>

                <div className='div-tratamento'>
                    <span>Tratamento a ser realizado</span>
                    <p>{atendimento?.TRATAMENTO}</p>
                        
                    <span>Observações</span>
                    <p>{atendimento?.OBSERVACOES}</p>
                </div>

                <div className='div-realizado'>
                    <span>Tratamento realizado</span>
                    <p>{atendimento?.TRATAMENTO_REALIZADO}</p>
                </div>


            </div>
        </div>
    )
}