import './header.css'
import { FaRegLaughWink } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { AiFillHome, AiFillPhone } from 'react-icons/ai'
import { FaUserCheck, FaUserPlus, FaFlag } from 'react-icons/fa'
import { ImPriceTags } from 'react-icons/im'
import { BiTime } from 'react-icons/bi'
import { FiMenu } from 'react-icons/fi'
import AnchorLink from 'react-anchor-link-smooth-scroll'


interface HeaderProps{
    openMenu: () => void;
}

export default function HeaderHome({ openMenu }: HeaderProps){
    const navigate = useNavigate()

    function home(){
        navigate('/')
    }

    return(
        <header className='headerhome-container'>
            <div className='headerhome-topo'>
                <ul>
                    <li>
                        <AiFillPhone color="#FFF" size={20}/>
                       <span>(19) 3546-5686</span>
                    </li>
                    <li>
                        <BiTime color='#FFF' size={20} />
                        <span>Segunda a sexta-feira das 08h00 às 18h00 (Horário de Brasília)</span>
                    </li>
                    <li className='acessar'>
                        <FaUserCheck color="#FFF" size={18}/>
                        <Link to='/login' target='_blank'>ACESSAR</Link>                       
                    </li>
                    <li>
                        <Link to='/cadastrouser' target='_blank'>TESTAR GRÁTIS</Link>                    
                    </li>
                </ul>
            </div>
            <div className='headerhome-full'>
                <div className='headerhome-Logo' onClick={home}>
                    <FaRegLaughWink color='#FFF' size={60}/>
                    <h3>Minha clínica</h3>                      
                </div>

                <div className='headerhome-menu'>
                    <nav>
                        <ul>
                            <li> 
                                <AiFillHome color="#FFF" size={20}/> 
                                <Link to='/'>HOME</Link>
                            </li>
                            <li>
                                <FaUserCheck color="#FFF" size={20}/>
                                <Link to='/login' target='_blank'>ACESSAR</Link>                       
                            </li>
                            <li> 
                                <FaUserPlus color="#FFF" size={20}/> 
                                <Link to='/cadastrouser' target='_blank'>TESTE GRÁTIS</Link>
                            </li>
                            <li> 
                                <ImPriceTags color="#FFF" size={20}/>
                                <AnchorLink href='#planos'>PLANOS</AnchorLink>
                            </li>
                            <li> 
                                <FaFlag color="#FFF" size={20}/>
                                <Link to='/quemsomos'>QUEM SOMOS</Link>
                            </li>
                            <li> 
                                <AiFillPhone color="#FFF" size={20}/>
                                <AnchorLink href='#contato'>CONTATO</AnchorLink>
                            </li>
                        </ul>
                    </nav>
                </div>

                <div className='headerhome-menu-mobile'>
                    <FiMenu size={45} color='#FFF' onClick={openMenu}/>
                </div> 
            </div>

        </header>
    )
}