import './index.css'
import { FaWindowClose } from 'react-icons/fa'
import { HistoricoPagamentoProps } from '../../pages/ContasReceber'
import { converterDataParaTexto } from '../../utils/utils'

interface ModalHistoricoProps{
    data: HistoricoPagamentoProps[]
    onClose: () => void
}


export default function ModalHistoricoPagamento({ data, onClose }: ModalHistoricoProps){
    return(
        <div className='box-modal'>
            <div className='content-modal-historico'>
                <div className='btnCloseModal'>
                    <button onClick={onClose}>
                        <FaWindowClose size={20} color='#FFF'/>
                    </button>
                    <div className='div-span'>
                        <span>HISTÓRICO DE PAGAMENTO</span>
                    </div>
                </div> 

                <div className='div-historico'>
                    
                    <div className='div-header-historico'>
                        <span>PARCELA</span>
                        <span>DATA PAGTO</span>
                        <span>DESCRICAO</span>
                        <span>VALOR PAGO</span>                        
                    </div>

                    { data.map( (hist) => {
                        return(
                            <div className='div-valores-historico'>
                                <span>{hist?.PARCELA}</span>
                                <span>{converterDataParaTexto(hist?.DATAPAGTO)}</span>
                                <span>{hist?.DESCRICAO}</span>
                                <span>{hist?.VALORPAGO.toLocaleString('pt-BR',{style:'currency', currency:'BRL'})}</span>
                            </div>
                        )
                    })}
                </div>
            </div>


        </div>
    )
}