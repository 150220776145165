import './index.css'
import { FaWindowClose, FaQuestionCircle } from 'react-icons/fa'
import { ContasRecebidasProps } from '../../pages/ContasReceber'
import { BsFillCheckCircleFill } from 'react-icons/bs'
import { MdCancel } from 'react-icons/md'

interface ModalProps{
    data: ContasRecebidasProps
    onClose: () => void
    onRequest: () => Promise<void>
}

export default function ModalDesfazerPagto({ data, onRequest, onClose }: ModalProps){
    return(
        <div className='box-modal'>
            <div className='content-modal-desfazer'>
                <div className='btnCloseModal'>
                    <button onClick={onClose}>
                        <FaWindowClose size={20} color='#FFF'/>
                    </button>
                </div> 
              
                <div className='headerModal'>
                    <FaQuestionCircle size={80} color='#ED1C24'/>
                    <h3>Deseja desfazer o pagamento?</h3>
                    <h2>{data?.NOMEPACIENTE}</h2>
                    <h2>{data?.VALOR.toLocaleString('pt-BR',{style:'currency', currency:'BRL'})}</h2>
                </div> 

                <div className='contentButton'>
                    <button id='ok' onClick={onRequest}>
                        <BsFillCheckCircleFill color='#FFF' size={16}/>
                        DESFAZER
                    </button>

                    <button id='cancel' onClick={onClose}>
                        <MdCancel color='#FFF' size={18}/>
                        CANCELAR
                    </button>
                </div>   
            </div>
        </div>
    )
}