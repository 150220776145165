import { useState, FormEvent, useContext, useEffect, ChangeEvent} from 'react'
import './index.css'
import { useParams } from 'react-router-dom'
import Header from '../../components/Header'
import BarraLateral from '../../components/BarraLateral'
import Loading from '../../components/Loading'
import Footer from '../../components/Footer'
import { useNavigate } from 'react-router-dom'
import { IoArrowBack } from 'react-icons/io5'
import { FaSitemap } from "react-icons/fa"
import { Input } from '../../components/Input'
import img from '../../assets/product.png'
import { BsFillCheckCircleFill } from 'react-icons/bs'
import { MdCancel } from 'react-icons/md'
import { toast } from 'react-toastify'
import { setupApiClient } from '../../services/api'
import { AuthContext } from '../../contexts/AuthContext'
import { converterDataParaTexto } from '../../utils/utils'

export default function NovoProduto(){
    const { user } = useContext(AuthContext)
    const navigate = useNavigate()
    const { id } = useParams()
    const [isLoading, setIsLoading] = useState(false)
    const [dataCad, setDataCad] = useState('')
    const [url_foto, setUrl_Foto] = useState('')
    const [descricao, setDescricao] = useState('')
    const [estoque, setEstoque] = useState('')
    const [valor, setValor] = useState('')
    const [fotoproduto, setFotoProduto] = useState(null)

    useEffect( () => {
        async function getProduto(){
            if(id){
                try{
                    const apiCliente = setupApiClient()

                    const response = await apiCliente.get('/produto',{
                        params:{
                            userid: user?.id, 
                            id: id
                        }
                    })

                    const { URL_FOTO, DATACAD, DESCRICAO, ESTOQUE, VALOR } = response.data

                    setDescricao(DESCRICAO)
                    setEstoque(ESTOQUE)
                    setValor(VALOR)
                    setUrl_Foto(URL_FOTO)
                    setDataCad(converterDataParaTexto(DATACAD))

                } catch(e){
                    console.log(e.response)
                    toast.error('Erro buscando o produto!')
                }
            } else {
                setDataCad(new Date().toLocaleDateString('pt-br'))
            }
        }

        getProduto()
    },[])


    function handleCancelar(){
        navigate('/produtos')
    }

    function handleFoto(e: ChangeEvent<HTMLInputElement>){
        if(!e.target.files){
            return
        }

        const img = e.target.files[0];

        if (!img){
            return;
        }

        const imgSize = img.size / 1024;

        if(imgSize > 1024){
            toast.error('Ops, tamanho da imagem acima de 1mb, reduza o tamanho!')
            return
        }

        if (img.type === 'image/jpeg' || img.type === 'image/png' || img.type === 'image/jpg'){
            setFotoProduto(img)
            setUrl_Foto(URL.createObjectURL(img))
        } else {
            toast.error('Imagens suportadas, JPEG, PNG, JPG!')
            return            
        }         
    }

    async function handleSalvar(e: FormEvent){
        e.preventDefault()

        if(estoque == ''){
            setEstoque('0')
        }

        if(descricao == ''){
            toast.warn('Digite a descrição do material!')
            document.getElementById('descricao').focus()
            return
        }

        if(valor == ''){
            toast.warn('Digite o valor do material!')
            document.getElementById('valor').focus()
            return
        }

        try{
            setIsLoading(true)
            const apiClient = setupApiClient()

            if(id){
                const req = new FormData()
                req.append('userid', user?.id)
                req.append('id', id)
                req.append('descricao', descricao)
                req.append('estoque', estoque)
                req.append('valor', valor)

                if(fotoproduto){
                    req.append('file', fotoproduto)
                }

                const response = await apiClient.put('/produto', req)

                if(response.data?.COD == 200){
                    toast.success('Produto atualizado!')
                    navigate('/produtos')
                    setIsLoading(false)               
                } else {
                    setIsLoading(false)
                    toast.error(response.data?.COD+' - '+response.data?.MSG)
                    return  
                }
            } else {
                const req = new FormData()
                req.append('userid', user?.id)
                req.append('descricao', descricao)
                req.append('estoque', estoque)
                req.append('valor', valor)

                if(fotoproduto){
                    req.append('file', fotoproduto)
                }

                const response = await apiClient.post('/produto', req)

                if(response.data?.COD == 201){
                    toast.success('Produto cadastrado!')
                    navigate('/produtos')
                    setIsLoading(false)               
                } else {
                    setIsLoading(false)
                    toast.error(response.data?.COD+' - '+response.data?.MSG)
                    return  
                }
            }

        } catch(e){
            console.log(e.response)
            setIsLoading(false)  
            return  
        }
    }

    if(isLoading){
        return(
            <main>
                <Header/>
                <BarraLateral/>            
                <Loading/>
                <Footer/>
            </main>
        )
    }

    return(
        <main>
            <Header/>
            <BarraLateral/>   
            <section className='content-listapaciente'>
                <div className='container-titulo-paciente'>
                    <div className='textheader'>
                        <button className='btnBack' title='VOLTAR' onClick={handleCancelar}>
                            <IoArrowBack color='#555' size={25}/>
                        </button>
                        <FaSitemap color='#555' size={25}/>
                        <h3>{id ? 'Editando cadastro' : 'Novo cadastro'}</h3> 
                    </div>               
                </div>  

                <form className='formpaciente' autoComplete='none' onSubmit={handleSalvar}>
                    <div className='form-div-linha'>
                        <label className='foto-paciente'>
                            <img src={url_foto ? url_foto : img} alt='Foto' width={90}/>
                            <input type='file' accept="image/png, image/jpeg, image/jpg" onChange={handleFoto}/> 
                        </label>

                        <div className='form-linha'>
                            <div className='div-inputs'>
                                <label htmlFor="descricao">DESCRIÇÃO DO ITEM</label>
                                <Input
                                    style={ {width:'400px'} } 
                                    id='descricao' 
                                    placeholder='DIGITE A DESCRIÇÃO'
                                    type='text'
                                    value={descricao}
                                    onChange={ (e) => setDescricao(e.target.value)}
                                />
                            </div>

                            <div className='div-linha-novoproduto'>
                                <div className='div-inputs'>
                                    <label htmlFor="data">DATA CADASTRO</label>
                                    <Input 
                                        disabled={true}
                                        style={ {width:'120px', cursor:'not-allowed'} } 
                                        id='data' 
                                        placeholder='00/00/0000'
                                        type='text'
                                        value={dataCad}
                                    />  
                                </div>
                                
                                <div className='div-inputs'>
                                    <label htmlFor="estoque">ESTOQUE</label>
                                    <Input 
                                        style={ {width:'120px'} } 
                                        id='estoque' 
                                        placeholder='0'
                                        type='text'
                                        value={estoque}
                                        onChange={ (e) => setEstoque(e.target.value)}
                                    />
                                </div>                               

                                <div className='div-inputs'>
                                    <label htmlFor="valor">VALOR</label>
                                    <Input 
                                        style={ {width:'120px'} } 
                                        id='valor' 
                                        placeholder='R$ 0,00'
                                        type='text'
                                        value={valor}
                                        onChange={ (e) => setValor(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='div-btn-paciente'>
                        <button 
                            id='salvar'
                            type='submit'
                        >
                            <BsFillCheckCircleFill color='#FFF' size={20}/>
                            SALVAR
                        </button>

                        <button onClick={handleCancelar}>
                            <MdCancel color='#FFF' size={20}/>
                            CANCELAR
                        </button>
                    </div>

                </form>


            </section>

            <Footer/>
        </main>
    )
}
