import { FormEvent, useState, useContext, useEffect } from 'react'
import './index.css'
import { FaRegLaughWink } from 'react-icons/fa';
import { Input } from '../../components/Input';
import { Button } from '../../components/Button';
import { MdMarkEmailRead } from 'react-icons/md'
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai'
import { IoCheckmarkDoneSharp, IoReloadOutline, IoKey } from "react-icons/io5"
import { toast } from 'react-toastify'
import { AuthContext } from '../../contexts/AuthContext';
import { Link } from 'react-router-dom';
import { setupApiClient } from '../../services/api';

export default function Login(){
    const { signIn, versao, validarEmail, validaremail } = useContext(AuthContext)
    const [email, setEmail] = useState('')
    const [senha, setSenha] = useState('')
    const [verpass, setVerPass] = useState(false)
    const [loading, setLoading] = useState(false)
    const [codigoValidacao, setCodigoValidacao] = useState('')
    const [ano, setAno] = useState(new Date().getFullYear())

    useEffect( () => {
        const email = localStorage.getItem('@emailMinhaClinica')
        if(email){
            setEmail(email)
        }
    },[])

    async function handleLogin(e: FormEvent ){
        e.preventDefault()

        if(email === '' || senha === ''){
            toast.warn('Preencha os campos!')
            return
        }

        try{
            setLoading(true)

            await signIn({
                email: email,
                senha: senha
            })

            setLoading(false)
        } catch(err){
            console.log(err)
            setLoading(false)
        }
    }

    async function handleValidarCodigo(){
        if(codigoValidacao == ''){
            toast.warn('Digite o código de validação!')
            return
        }

        try{
            const apiClient = setupApiClient();

            let obj = {
                email: email,
                codigovalidacao: codigoValidacao
            }

            const response = await apiClient.post('/validaremail', obj,{
                headers:{
                    Authorization: 'Basic Auth'
                },
                auth:{
                    username: process.env.REACT_APP_USER,
                    password: process.env.REACT_APP_PASS
                }
            })

            if(response.data?.COD == 200){
                toast.success('Email validado, faça o login! :)')
                validaremail(false)
                setCodigoValidacao('')
            } else {
                validaremail(true) 
                toast.error(response.data?.COD+' - '+response.data?.MSG)
                return 
            }
        } catch(e){
            console.log(e)
            validaremail(true)
            return
        }
    }


    return(
        <main className='loginboxContainer'>
            <section className='loginsection'>
                <div className='div-login'>
                    <form className='loginboxform' onSubmit={handleLogin}>
                        <div className='loginbox-text-info'>
                            <FaRegLaughWink color='#000' size={50}/>
                            <h4>Acesso ao sistema</h4>
                            <h6>Digite suas credenciais de acesso</h6>
                        </div>    

                        <div className='logincontainerinput'>
                            <div className='div-input'>
                                <MdMarkEmailRead size={35} color='#2322AE'/>
                                <Input
                                    type='email'
                                    placeholder='Seu email...'
                                    value={email}
                                    onChange={ (e) => setEmail(e.target.value)}
                                    required
                                />
                            </div>
                        </div>

                        <div className='logincontainerinput'>
                            <div className='div-input'>
                                { verpass ? (
                                    <AiFillEye size={35} color='#2322AE' onClick={() => setVerPass(!verpass)}/>
                                ) : (
                                    <AiFillEyeInvisible size={35} color='#2322AE' onClick={() => setVerPass(!verpass)}/>
                                )}
                                <Input
                                    id='inputpass'
                                    type={verpass ? 'text' :'password'}
                                    placeholder='Sua senha...'
                                    value={senha}
                                    onChange={ (e) => setSenha(e.target.value)}
                                    required
                                />
                            </div>
                        </div>

                        <Button
                            type='submit'
                         >
                            { loading ? <IoReloadOutline className='load-icon' size={22} color='#FFF'/> : (
                                <>
                                <IoCheckmarkDoneSharp size={22} color='#FFF'/>
                                <span>Acessar</span>
                                </>
                            ) }
                        </Button>

                        <Link to='/cadastrouser'>
                            Não tem uma conta! <span>Cadastre-se</span>
                        </Link>
                    </form>   

                    { validarEmail && (
                        <div className='div-input input-codigo'>
                            <IoKey size={35} color='#2322AE'/>
                            <Input
                                placeholder='código de validação recebido...'
                                type='text'
                                value={codigoValidacao}
                                onChange={ (e) => setCodigoValidacao(e.target.value)}
                            />
                            <button className='btn-validaremail' onClick={handleValidarCodigo}>
                                VALIDAR
                            </button>  
                        </div>
                    )}
                </div>

                <div className='img-login'>
                    <FaRegLaughWink color='#FFF' size={100}/>
                    <h3>Minha clínica</h3>
                    <h6>Bem vindo(a) ao sistema</h6>
                    <span><Link to={'https://site.wgainformatica.com.br'} target='_blank'>WGA Informática®</Link> COPYRIGHT © {ano} - versão {versao}</span>
                </div>
            </section>
        </main>
    )
}