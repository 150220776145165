import './index.css'
import { FaWindowClose, FaQuestionCircle } from 'react-icons/fa'
import { PacientesProps } from '../../pages/ListaPaciente'
import { BsFillCheckCircleFill } from 'react-icons/bs'
import { MdCancel } from 'react-icons/md'

interface ModalProps{
    data: PacientesProps
    onClose: () => void
    onRequest: (id: string) => Promise<void>
}

export default function ModalDelete({ data, onClose, onRequest }: ModalProps){
    return(
        <div className='box-modal'>
            <div className='content-modal'>
                
                <div className='btnCloseModal'>
                    <button onClick={onClose}>
                        <FaWindowClose size={20} color='#FFF'/>
                    </button>
                </div>                

                <div className='headerModal'>
                    <FaQuestionCircle size={90} color='#ED1C24'/>
                    <h3>Deseja excluir o paciente?</h3>
                    <h2>{data?.NOME}</h2>
                </div>               

                <div className='contentButton'>
                    <button id='ok' onClick={ () => onRequest(data?.ID_PACIENTE)}>
                        <BsFillCheckCircleFill color='#FFF' size={18}/>
                        EXCLUIR
                    </button>

                    <button id='cancel' onClick={onClose}>
                        <MdCancel color='#FFF' size={22}/>
                        CANCELAR
                    </button>
                </div>            
            </div>
        </div>
    )
}