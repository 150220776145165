import './index.css'
import { useState, FormEvent, useContext } from 'react'
import { Button } from "../../components/Button"
import { Input } from "../../components/Input"
import { FaRegLaughWink } from 'react-icons/fa'
import { FaHospitalUser } from "react-icons/fa"
import { MdMarkEmailRead } from 'react-icons/md'
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai'
import { IoCheckmarkDoneSharp, IoReloadOutline } from "react-icons/io5"
import { setupApiClient } from '../../services/api'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import ReCAPTCHA from 'react-google-recaptcha'
import { emailTest } from '../../utils/utils'
import { AuthContext } from '../../contexts/AuthContext'


export default function CadastroUsuario(){
    const navigate = useNavigate()
    const { versao } = useContext(AuthContext)
    const [nome, setNome] = useState('')
    const [email, setEmail] = useState('')
    const [senha, setSenha] = useState('')
    const [verpass, setVerPass] = useState(false)
    const [recaptcha, setRecaptcha] = useState('')
    const [loading, setLoading] = useState(false)
    const [ano, setAno] = useState(new Date().getFullYear())

    async function handleCadastrar(e: FormEvent) {
        e.preventDefault()
        
        if(nome === '' || email === '' || senha === ''){
            toast.warn('Preencha todos os campos!')
            return
        }

        if(!recaptcha){
            toast.warn('Marque o captcha!')
            return;             
        }

        if(!emailTest.test(email)){
            toast.warn('E-mail inválido!')
            return
        }

        try{
            setLoading(true)

            const apiClient = setupApiClient()

            const response = await apiClient.post('/cadastroestabelecimento',{
                nome: nome.toUpperCase(),
                email: email.toLowerCase(),
                senha: senha,
                validacao: Math.floor(Math.random() * (9000 - 1000) + 1000)
            })

            if(response.data?.COD == 201){
                setNome('')
                setEmail('')
                setSenha('')
                setLoading(false)
                navigate('/login')
                toast.success('Conta criada, verifique seu email! :)')
            } else {
                setLoading(false)
                toast.error(response.data?.COD+' - '+response.data?.MSG)
                return 
            }
        } catch(err){
            console.log(err)
            setLoading(false)
        }
    }

    function onRecaptcha(value: string){
        setRecaptcha(value)
    }

    return(
        <main className='cadboxContainer' onContextMenu={(e) => e.preventDefault()}>
            <section className='cadcontainersection'>
                <div className='caddiv-login'>
                    <form className='cadboxform' onSubmit={handleCadastrar}>
                        <div className='cadbox-text-info'>
                            <FaRegLaughWink color='#000' size={50}/>
                            <h4>Cadastro de acesso</h4>
                            <h6>Crie suas credenciais de acesso</h6>
                        </div> 

                        <div className='cadcontainerinput'>
                            <div className='caddiv-input'>
                                <FaHospitalUser size={35} color='#2322AE'/>
                                <Input
                                    type='text'
                                    placeholder='Seu nome ou estabelecimento...'
                                    value={nome}
                                    onChange={ (e) => setNome(e.target.value)}
                                    required
                                />
                            </div>
                        </div>   

                        <div className='cadcontainerinput'>
                            <div className='caddiv-input'>
                                <MdMarkEmailRead size={35} color='#2322AE'/>
                                <Input
                                    type='email'
                                    placeholder='Seu email...'
                                    value={email}
                                    onChange={ (e) => setEmail(e.target.value)}
                                    required
                                />
                            </div>
                        </div>

                        <div className='cadcontainerinput'>
                            <div className='caddiv-input'>
                                { verpass ? (
                                    <AiFillEye size={35} color='#2322AE' onClick={() => setVerPass(!verpass)}/>
                                ) : (
                                    <AiFillEyeInvisible size={35} color='#2322AE' onClick={() => setVerPass(!verpass)}/>
                                )}
                                <Input
                                    type={verpass ? 'text' :'password'}
                                    placeholder='Sua senha...'
                                    value={senha}
                                    onChange={ (e) => setSenha(e.target.value)}
                                    required
                                />
                            </div>
                        </div>

                        <div className='recaptcha'>
                            <ReCAPTCHA
                                theme='light'
                                sitekey="6LdZD2ElAAAAAIftCMwg4Y6Z0bMwy3L4iITcWRyr"
                                onChange={onRecaptcha}
                            />
                        </div>

                        <Button
                            type='submit'
                         >
                            { loading ? <IoReloadOutline className='load-icon' size={22} color='#FFF'/> : (
                                <>
                                <IoCheckmarkDoneSharp size={22} color='#FFF'/>
                                <span>Cadastrar</span>
                                </>
                            ) }
                        </Button>

                        <Link to='/login'>
                            Já possuo uma conta! <span>Fazer login</span>
                        </Link>
                    </form>   
                </div>

                <div className='img-login'>
                    <FaRegLaughWink color='#FFF' size={100}/>
                    <h3>Minha clínica</h3>
                    <h6>Bem vindo(a) ao sistema</h6>
                    <span><Link to={'https://site.wgainformatica.com.br'} target='_blank'>WGA Informática®</Link> COPYRIGHT © {ano} - versão {versao}</span>
                </div>
            </section>




        </main>
    )
}