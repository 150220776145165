import { useState, useEffect, ChangeEvent, useContext } from 'react'
import Header from '../../components/Header'
import BarraLateral from '../../components/BarraLateral'
import Loading from '../../components/Loading'
import Footer from '../../components/Footer'
import './index.css'
import { Link } from 'react-router-dom'
import { MdAdd } from 'react-icons/md'
import { FaSitemap } from "react-icons/fa"
import { Input } from '../../components/Input'
import foto from '../../assets/product.png'
import { FaEdit, FaTrash } from 'react-icons/fa'
import { setupApiClient } from '../../services/api'
import { useNavigate } from 'react-router-dom'
import ModalDeleteGenerico, { dataModalProps }  from '../../components/ModalDeleteGenerico'
import { toast } from 'react-toastify'
import { AuthContext } from '../../contexts/AuthContext'
import { CgSmileSad } from 'react-icons/cg'

export interface ProdutosProps{
    ID_PRODUTO: number
    URL_FOTO: string
    DESCRICAO: string
    ESTOQUE: number | null
    VALOR: number
}

export default function ListaProdutos(){
    const { user } = useContext(AuthContext)
    const navigate = useNavigate()
    const [isLoaging, setIsLoading] = useState(false)
    const [produtos, setProdutos] = useState<ProdutosProps[]>([])
    const [openModal, setOpenModal] = useState(false)
    const [deleteProduto, setDeleteProduto] = useState<dataModalProps>()
    const [refresh, setRefresh] = useState(false)

    useEffect( () => {
        getProdutos()
    },[refresh])

    async function getProdutos(){
        try{
            setIsLoading(true)
            const userid = sessionStorage.getItem('@userid_clinica')
            const apiClient = setupApiClient()

            const response  = await apiClient.get('/produtos',{
                params:{
                    userid: userid
                }
            })

            setProdutos(response.data)
            setIsLoading(false)
        } catch(err) {
            console.log(err)
            setIsLoading(false)
        }
    }

    function handleEdit(produto: ProdutosProps){
        navigate(`/novoproduto/${produto?.ID_PRODUTO}`)
    }

    function handleDelete(produto: ProdutosProps){
        let data = {
            ID: produto.ID_PRODUTO,
            DESCRICAO: produto.DESCRICAO
        }
        setDeleteProduto(data)
        setOpenModal(true)
    }

    async function DeleteProduto(id: number){
        try{
            setIsLoading(true)
            const apiClient = setupApiClient()

            const response = await apiClient.delete('/produto',{
                params:{
                    userid: user?.id,
                    id: id
                }
            })

            if(response.data?.COD == 200){
                toast.success('Produto excluído!')
                setRefresh(!refresh)
                setIsLoading(false)
                setOpenModal(false)              
            } else {
                setIsLoading(false)
                toast.error(response.data?.COD+' - '+response.data?.MSG)
                return  
            }
        } catch(err){
            console.log(err.response)
            toast.error('Erro excluindo produto!')
            setIsLoading(false)
            setOpenModal(false)
        }
    }

    function pesquisaProduto(e: ChangeEvent<HTMLInputElement>) {
        let filtrar: string, texto: string
        const prod = document.getElementById('gridview-body-product').getElementsByClassName('div-linha-prod')

        filtrar = e.target.value.toUpperCase()

        for(let i=0; i < prod.length; i++){
            texto = prod[i].children[1].innerHTML

            if(texto.toUpperCase().indexOf(filtrar) > -1){
                prod[i].classList.remove('ocultarLinha')
            } else {
                prod[i].classList.add('ocultarLinha')  
            }
        }
    }

    if(isLoaging){
        return(
            <main>
                <Header/>
                <BarraLateral/>            
                <Loading/>
                <Footer/>
            </main>
        )
    }

    return(
        <main>
            <Header/>  
            <BarraLateral/>
            <section className='content-listapaciente'>
                <div className='container-titulo-paciente'>
                    <div className='div-titulo-tela'>
                        <div className='textheader'>
                            <FaSitemap   color='#555' size={25}/>
                            <h3>Você está em materiais cadastrados</h3>   
                        </div>   
                        <div className='div-btn-novo'>
                            <Link to='/novoproduto'>
                                <MdAdd size={20} color='#FFF'/>
                                Novo
                            </Link>
                        </div>
                    </div>
                </div>

                <div className='container-filtro-paciente'>
                    <div className='div-campos-input'>
                        <div className='div-filtrar'>
                            <label htmlFor="inputPesquisa">PESQUISA POR DESCRIÇÃO</label>
                            <Input
                                id='inputPesquisa'
                                placeholder='Digite a descrição...'
                                onChange={(e) => pesquisaProduto(e)}
                                type='search'
                            />
                        </div>
                    </div>                    
                </div>

                { produtos.length <= 0 ? (
                    <div className='div-sempaciente'>
                        <CgSmileSad size={120} color='#bbb'/>
                        <span>Nenhum material cadastrado!</span>    
                    </div>
                    ) : (
                        <div className='div-gridview'>
                            <div className='gridview-header-prod'>
                                <span className='p1'>FOTO</span>
                                <span className='p2'>DESCRIÇÃO</span>
                                <span className='p3'>ESTOQUE</span>
                                <span className='p4'>VALOR</span>
                                <span className='p5'>AÇÕES</span>
                            </div>

                            <div className='gridview-body-product' id='gridview-body-product'>
                                { produtos.map( (produto) => { 
                                    return(
                                        <div key={produto?.ID_PRODUTO} className='div-linha-prod'>
                                            <span className='p1'>
                                                <img src={produto?.URL_FOTO ? produto?.URL_FOTO : foto} alt='Foto' />
                                            </span>  
                                            <span className='p2 span-generico'>{produto?.DESCRICAO}</span>
                                            <span className='p3 span-generico right'>{produto?.ESTOQUE.toFixed(2).replace('.',',')}</span>
                                            <span className='p4 span-generico right'>{produto?.VALOR.toLocaleString('pt-BR',{style:'currency', currency:'BRL'})}</span>
                                            <div className="p5 div-btn-acao">
                                                <button className='btn-acao' title='EDITAR' onClick={ () => handleEdit(produto)}>
                                                    <FaEdit size={20} color='#4E9438'/>
                                                </button>
                                                <button className='btn-acao' title='EXCLUIR' onClick={ () => handleDelete(produto)}>
                                                    <FaTrash size={20} color='#FF0000'/>
                                                </button>
                                            </div>
                                        </div>
                                    )
                                })}                                
                            </div>
                        </div>
                    )}
            </section>

            { openModal && (
                <ModalDeleteGenerico
                    data={deleteProduto}
                    title='Produto'
                    onClose={ () => setOpenModal(!openModal)}
                    onRequest={ (id: number) => DeleteProduto(id)}
                />
            )}

            <Footer/>
        </main>
    )
}