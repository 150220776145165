import { createContext, ReactNode, useState, useEffect } from 'react'
import { setCookie, destroyCookie, parseCookies } from 'nookies'
import { toast } from 'react-toastify'
import { setupApiClient } from '../services/api'
import { useNavigate } from 'react-router-dom'
import { converterDataParaTexto } from '../utils/utils'
import { validaDataModuloTeste } from '../utils/utils'

type AuthContextData = {
    user: UserProps
    isAuthenticated: boolean
    versao: string
    dtUpdate: string
    validarEmail: boolean
    signIn: (credenciais: LoginProps) => Promise<void>
    signOut: () => Promise<void>
    updateUser: (data: UserProps) => void
    validaremail: (value: boolean) => void
}

type LoginProps = {
    email: string
    senha: string
}

export interface UserProps {
    id: string
    nome: string
    email: string
    urlfoto?: string
    url_logo?: string
    datacriado?: string
    endereco?: string
    numero?: string
    bairro?: string
    complemento?: string
    cep?: string
    cidade?: string
    uf?: string
    telefone?: string
    celular?: string
    cnpj?: string
    ie?: string
}

type AuthProviderProps = {
    children : ReactNode
}

export const AuthContext = createContext({} as AuthContextData)

export async function signOut(){
    try{
        destroyCookie(null,'@MinhaClinicaToken');
        localStorage.removeItem('@MinhaClinica')
    } catch(error) {
        console.log(error)
        toast.error('Erro ao deslogar!')
    }
}
    
export function AuthProvider({ children }: AuthProviderProps ){
    const navigate = useNavigate()

    const [user, setUser] = useState<UserProps>()
    const isAuthenticated = !!user
    const [validarEmail, setValidarEmail] = useState(false)
    const [versao, setVersao] = useState('1.0.1.6')
    const [dtUpdate, setDtUpdate] = useState('12/03/2025')

    useEffect( () => {
        async function getUser(){
            const cookie = parseCookies()
            const token = cookie['@MinhaClinicaToken']
            
            if (token){
                const email = localStorage.getItem('@emailMinhaClinica')

                const apiClient = setupApiClient();

                await apiClient.get('/me',{
                    params:{
                        email: email
                    }
                })
                .then( (response) => {
                    const { CODIGO, NOME, EMAIL, ENDERECO, NUMERO, BAIRRO,
                            COMPLEMENTO, CIDADE, CEP, UF, TELEFONE, CELULAR, 
                            CNPJ, IE, CRIADO, URLFOTO, URL_LOGO } = response.data
                    
                    setUser({
                        id: CODIGO,
                        nome: NOME,
                        email: EMAIL,
                        datacriado: converterDataParaTexto(CRIADO),
                        urlfoto: URLFOTO,
                        url_logo: URL_LOGO,
                        endereco: ENDERECO,
                        numero: NUMERO,
                        bairro: BAIRRO,
                        complemento: COMPLEMENTO,
                        cep: CEP,
                        cidade: CIDADE,
                        uf: UF,
                        telefone: TELEFONE,
                        celular: CELULAR,
                        cnpj: CNPJ,
                        ie: IE
                    })

                    sessionStorage.setItem('@userid_clinica',CODIGO)
                })
                .catch( () => {
                    signOut()
                    navigate('/')
                })
            } 
        }

        getUser();       
    },[])

    function updateUser(data: UserProps){
        setUser(data)
    }

    function validaremail(value: boolean){
        setValidarEmail(value)
    }

    async function signIn({ email, senha }: LoginProps){
        try{
            const apiClient = setupApiClient();

            const response = await apiClient.post('/login', {
                email: email,
                senha: senha
            })

            if(response.data?.COD == 105){
                toast.warn(`Email/senha não encontrado! :(`)
                return                  
            }

            if(response.data?.COD == 401){
                toast.warn(`Email/senha inválidos! :(`)
                return                  
            }

            const { CODIGO, NOME, EMAIL, BLOQUEADO, VALIDADO, CRIADO, MODULO,
                    URLFOTO, URL_LOGO, LOCAL, TOKEN, ENDERECO, NUMERO, BAIRRO,
                    COMPLEMENTO, CIDADE, CEP, UF, TELEFONE, CELULAR, CNPJ, IE } = response.data;

            if(BLOQUEADO == process.env.REACT_APP_BLOQUEADO){
                toast.warn(`Estabelecimento bloqueado! :(`)
                return    
            }

            if(VALIDADO == process.env.REACT_APP_VALIDADO){
                setValidarEmail(true)
                toast.warn(`Email não verificado, valide seu email! :(`)
                return    
            }

            if(String(MODULO) == process.env.REACT_APP_MODULO && validaDataModuloTeste(CRIADO) <= 0){
                toast.warn(`Seu período de teste acabou, entre em contato conosco! :(`)
                return
            } 

            setCookie(null,'@MinhaClinicaToken', TOKEN, {
                maxAge: 60 * 60 * 24 * 30,
                path: "/"
            })

            localStorage.setItem('@MinhaClinica',LOCAL)
            localStorage.setItem('@emailMinhaClinica',EMAIL)
            sessionStorage.setItem('@userid_clinica',CODIGO)

            setUser({
                id: CODIGO,
                nome: NOME,
                email: EMAIL,
                datacriado: converterDataParaTexto(CRIADO),
                urlfoto: URLFOTO,
                url_logo: URL_LOGO,
                endereco: ENDERECO,
                numero: NUMERO,
                bairro: BAIRRO,
                complemento: COMPLEMENTO,
                cep: CEP,
                cidade: CIDADE,
                uf: UF,
                telefone: TELEFONE,
                celular: CELULAR,
                cnpj: CNPJ,
                ie: IE
            })

            toast.success(`Bem vindo de volta ${NOME} :)`)
            navigate('/dashboard')        

       } catch(error) {
            if(error.response.status === 400){
                toast.error('Email/senha inválidos!')
            } else {
                toast.error('Internal server error!')
                console.log(error.response)

            }
        }
    }

    return(
        <AuthContext.Provider value={ {user, isAuthenticated, versao, dtUpdate, validarEmail, signIn, signOut, updateUser, validaremail } }>
            {children}
        </AuthContext.Provider>
    )
}