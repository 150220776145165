import './modalOrcamentos.css'
import { FaWindowClose } from 'react-icons/fa'
import { orcamentosPacienteProps } from '../../pages/NovoPaciente'

interface modalProntProps{
    orcamento: orcamentosPacienteProps
    onClose: () => void
}

export default function ModalOrcamento({ orcamento, onClose }: modalProntProps){
    
    const data = orcamento?.DATA_AGENDA.split('-')
    
    return(
        <div className='box-modal'>
            <div className='content-modalprontuario'>

                <div className='grupo-btn'>
                    <button className='btn-close' onClick={onClose}>
                        <FaWindowClose size={20} color='#FFF'/>
                    </button>
                    <span>ORÇAMENTO {orcamento?.NOMEPACIENTE} DIA {data[2].substring(0,2)+'/'+data[1]+'/'+data[0]}</span>
                </div>

                <div className='div-tratamento'>
                    <span>TRATAMENTO ORÇADO</span>
                    <p>{orcamento?.TRATAMENTO}</p>
                </div>

                <div className='div-tratamento'>
                    <span>OBSERVAÇÕES</span>
                    <p>{orcamento?.OBSERVACOES}</p>
                </div>

                <div className='div-orcamento-pagamento'>
                    <div className='div-pagamento'>
                        <span>FORMA DE PAGAMENTO</span>
                        <p>{orcamento?.FORMAPAGAMENTO}</p>
                    </div>
                    <div className='div-pagamento'>
                        <span>VALOR COBRADO</span>
                        <p>{orcamento?.VALORCOBRADO.toLocaleString('pt-BR',{style:'currency', currency:'BRL'})}</p>
                    </div>
                    <div className='div-pagamento'>
                        <span>TOTAL A PAGAR</span>
                        <p>{orcamento?.TOTALPAGAR.toLocaleString('pt-BR',{style:'currency', currency:'BRL'})}</p>
                    </div>
                </div>

                <div className='div-realizado'>
                    <span>OBSERVAÇÃO DO PAGAMENTO</span>
                    <p>{orcamento?.OBSPAGAMENTO}</p>
                </div>


            </div>
        </div>
    )
}