
export const cepMask = ['99.999-999']
export const telMask = ['(99) 9999-9999', '(99) 9 9999-9999']
export const cpfMask = ['999.999.999-99','99.999.999/9999-99']
export const valMask = ['999.999,99']

export const emailTest = /^[a-z0-9.]+@[a-z0-9]+\.[a-z]+(\.[a-z]+)?$/i

export function converterTextoParaData(data: string){
    let newData = data.split('/')
    
    if(newData.length === 0){
        return null
    } else {
        return new Date(parseInt(newData[2]), parseInt(newData[1]) -1, parseInt(newData[0]))
    }
}

export function converterDataParaTexto(data: any): string{
    let newData = data.split('-')
    
    if(newData.length === 0){
        return null
    } else {
        return newData[2].substring(0,2)+'/'+newData[1]+'/'+newData[0]
    }
}

export function validaDataModuloTeste(data: string){
    const dataNow = new Date().getTime()
    const userCriado = new Date(data).getTime()
    const msegundos = dataNow - userCriado 
    const dias = msegundos / (1000 * 3600 * 24)

    if(dias > 7){
        return 0 
    } else {
        return dias
    }
}

export function formataDataHoraToString(data: string): string{
    let newData = data.split('-')

    if(newData.length === 0){
        return null
    } else {
        let stringData = newData[2].substring(0,2)+'/'+newData[1]+'/'+newData[0]+' '+newData[2].substring(3,8)
        return stringData
    }
}

export function isNumber(value: string){
    const num = /^[0-9]+$/
    return num.test(value)
}

export function isFloat(value: string){
    const num = /^[0-9]+\.[0-9]{2}$|[0-9]+\.[0-9]{2}[^0-9]/
    return num.test(value)
}

export function calculaIdade(nascimento: any, hoje:any){
    return Math.floor(Math.ceil(Math.abs(nascimento.getTime() - hoje.getTime()) / (1000 * 3600 * 24)) / 365.25);
}

export function retornaDataConsulta(data: string):string{
    return
  
}