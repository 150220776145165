import { useState, useEffect } from 'react'
import './index.css'
import { FaWindowClose } from 'react-icons/fa'
import { BsFillCalendarCheckFill } from 'react-icons/bs'
import { TextArea } from '../Input'
import { setupApiClient } from '../../services/api'
import { toast } from 'react-toastify'
import { BiLoader } from 'react-icons/bi'
import { PacientesProps } from '../../pages/ListaPaciente'
import { v4 as uuidv4 } from 'uuid'

interface ModalAgendaProps{
    date: string
    onClose: () => void
    dadosatendimento?: AtendimentoProps | undefined
}

export interface AtendimentoProps{
    UUID: string
    ID_EMPRESA: number
    ID_PACIENTE: number | string
    DATA_AGENDA: string
    TRATAMENTO: string
    OBSERVACOES: string
    TIPOATENDIMENTO: string
}

export default function ModalAgenda({ date, onClose, dadosatendimento }: ModalAgendaProps){

    const [loading, setLoading] = useState(false)
    const [pacientes, setPacientes] = useState<PacientesProps[]>([])
    const [pacienteSelecionado, setPacienteSelecionado] = useState(0)
    const [atendimento, setAtendimento] = useState(date)
    const [horaAtendimento, setHoraAtendimento] = useState('')
    const [tratamento, setTratamento] = useState('')
    const [observacoes, setObservacoes] = useState('')
    const [tipoatendimento, setTipoAtendimento] = useState('2')

    useEffect( () => {

        async function getClientes(){
            setLoading(true)

            try{
                const email = localStorage.getItem('@emailMinhaClinica')
                const apiClient = setupApiClient()
    
                const response = await apiClient.get('/pacientes',{
                    params:{
                        email: email
                    }
                })

                setPacientes(response.data)
                setLoading(false)
            } catch(err){
                console.log(err.data)
                toast.error('Erro buscando pacientes!')
                setLoading(false)
            }
            
        }

        getClientes()

    },[])

    useEffect( () => {
        if(dadosatendimento){
            setTratamento(dadosatendimento?.TRATAMENTO)
            setHoraAtendimento(dadosatendimento?.DATA_AGENDA.substring(11,16))
            setAtendimento(dadosatendimento?.DATA_AGENDA.substring(0,10))
            setObservacoes(dadosatendimento?.OBSERVACOES)
            setTipoAtendimento(dadosatendimento?.TIPOATENDIMENTO)

            const idIndex = pacientes.findIndex( (paciente) => paciente?.ID_PACIENTE === dadosatendimento?.ID_PACIENTE)
            setPacienteSelecionado(idIndex)
        }
    },[pacientes])


    async function handleSalvar(){
        if(horaAtendimento == ''){
            toast.warn('Defina o horário do atendimento!')
            return
        }

        if(tratamento == ''){
            toast.warn('Descreva o tratamento a ser realizado!')
            return            
        }

        let dtAtendimento = atendimento.split('-')
        let hrAtendimento = horaAtendimento.split(':')
        let dataAtendimento = dtAtendimento[2]+'/'+dtAtendimento[1]+'/'+dtAtendimento[0]
        let horario = hrAtendimento[0]+':'+hrAtendimento[1]
        
        if(dadosatendimento){
            try{
                const userid = sessionStorage.getItem('@userid_clinica')
                const apiClient = setupApiClient()
    
                let obj = {
                    uuid: dadosatendimento?.UUID,
                    userid: userid,
                    idpaciente: pacientes[pacienteSelecionado]?.ID_PACIENTE,
                    data_agenda: dataAtendimento+' '+horario,
                    tratamento: tratamento,
                    observacoes: observacoes,
                    tipoatendimento: tipoatendimento                  
                }

                const response = await apiClient.put('/agenda', obj)
                
                if(response.data?.COD == 200){
                    onClose()
                    toast.success('Atendimento atualizado!')
                } else {
                    toast.error(response.data?.COD+' - '+response.data?.MSG)
                    return 
                }
            } catch(err){
                console.log(err)
                toast.error('Erro atualizando atendimento!')
            }              

        } else {
            try{
                const id = sessionStorage.getItem('@userid_clinica')
                let obj = {
                    uuid: uuidv4(),
                    userid: id,
                    idpaciente: pacientes[pacienteSelecionado]?.ID_PACIENTE,
                    data: dataAtendimento+' '+horario,
                    tratamento: tratamento,
                    observacoes: observacoes,
                    tipoatendimento: tipoatendimento 
                }

                const apiClient = setupApiClient()
    
                const response = await apiClient.post('/agenda', obj)
    
                if(response.data?.COD == 201){
                    onClose()
                    toast.success('Paciente agendado!')
                } else {
                    toast.error(response.data?.COD+' - '+response.data?.MSG)
                    return 
                }
            } catch(err){
                console.log(err)
                toast.error('Erro cadastrando atendimento!')
            }       
        }
    }

    function handlePacienteSelecionado(e: any){
        setPacienteSelecionado(e.target.value)
    }

    function handleTipoAtendimento(e: any){
        setTipoAtendimento(e.target.value)
    }

    return(
        <main className='box-modal'>
            <div className='content-agenda'>
           
                <div className='grupo-btn-agenda'>
                    <button className='btnClose' onClick={onClose}>
                        <FaWindowClose size={20} color='#FFF'/>
                    </button>
                    
                    <button className='btnAgendar' onClick={handleSalvar}>
                        <BsFillCalendarCheckFill size={20} color='#FFF'/>
                        Confirmar
                    </button>
                </div>  

                <form className='form-agenda'>
                    <div className='header-agenda'>
                        <div className='nome-paciente'>
                            <div className='div-select-nome'>
                            <label htmlFor="select-nome">PACIENTES</label>
                                { loading ? (
                                    <div className='aguarde-paciente'>
                                        <span>
                                            <BiLoader color='#000' size={20}/>
                                            <span>Carregando pacientes...</span> 
                                        </span>
                                    </div>
                                ) : (
                                    <>
                                    { pacientes.length > 0 && (
                                        <select value={pacienteSelecionado} id='select-nome' onChange={handlePacienteSelecionado}>
                                            { pacientes.map( (item, index) => {
                                                return(
                                                    <option key={item?.ID_PACIENTE} value={index} className={item.CONSIDERADO == 2 ? `considerado negrito` : ``}>
                                                        {item?.NOME}
                                                    </option>
                                                )
                                            })}
                                        </select> 
                                    )}
                                    </>
                                )}
                            </div>
                            <div className='div-select-tipo'>
                                <label htmlFor="select-tipo">ATENDIMENTO</label>
                                <select value={tipoatendimento} id="select-tipo" onChange={handleTipoAtendimento}>
                                    <option value="1" style={{background:'#6060E0', color:'#fff'}}>PRIORITÁRO</option>
                                    <option value="2" style={{background:'#6DE04A'}}>NORMAL</option>
                                    <option value="3" style={{background:'yellow'}}>MODERADO</option>
                                    <option value="4" style={{background:'brown', color:'#fff'}}>COMPLEXO</option>
                                </select>
                            </div>        
                        </div>

                        <div className='data-atendimento'>
                            <span>DATA</span>
                            <input 
                                type="date" 
                                value={atendimento}
                                onChange={ (e) => setAtendimento(e.target.value)}
                            />
                            <input 
                                type="time" 
                                value={horaAtendimento}
                                onChange={ (e) => setHoraAtendimento(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className='servico-executar'>
                        <span>TRATAMENTO A REALIZAR</span>
                        <TextArea
                            value={tratamento}
                            onChange={(e) => setTratamento(e.target.value)}
                        />
                    </div>

                    <div className='servico-executar'>
                        <span>OBSERVAÇÕES</span>
                        <TextArea
                            value={observacoes}
                            onChange={(e) => setObservacoes(e.target.value)}
                        />
                    </div>
                </form>
            </div>    

        </main>
    )
}