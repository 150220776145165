import { useState, useEffect, ChangeEvent } from 'react'
import './novoprocedimento.css'
import Header from '../../components/Header'
import BarraLateral from '../../components/BarraLateral'
import Footer from '../../components/Footer'
import { useParams } from 'react-router-dom'
import { IoArrowBack } from 'react-icons/io5'
import { FaProcedures } from "react-icons/fa"
import { useNavigate } from 'react-router-dom'
import img from '../../assets/procedimento.png'
import { Input } from '../../components/Input'
import { MdAdd } from "react-icons/md"
import foto from '../../assets/product.png'
import { FaTrash } from 'react-icons/fa'
import { setupApiClient } from '../../services/api'
import { ProdutosProps } from '../ListaProdutos'
import Loading from '../../components/Loading'
import { converterDataParaTexto } from '../../utils/utils'
import ModalDeleteGenerico, { dataModalProps } from '../../components/ModalDeleteGenerico'
import { BsFillCheckCircleFill } from 'react-icons/bs'
import { MdCancel } from 'react-icons/md'
import { toast } from 'react-toastify'
import { CgSmileSad } from 'react-icons/cg'

interface ItensProcedimentoProps{
    ID_ITEM: number
    ID_PRODUTO: number
    URL_FOTO?: string
    DESCRICAO: string
    QUANTIDADE: number
    VALOR: number
    TOTAL: number
}

export default function NovoProcedimento(){
    const navigate = useNavigate()
    const { id } = useParams()
    const [openModal, setOpenModal] = useState(false)
    const [deleteProduto, setDeleteProduto] = useState<dataModalProps>()
    const [produtos, setProdutos] = useState<ProdutosProps[]>([])
    const [itensprocedimento, setItensProcedimento] = useState<ItensProcedimentoProps[]>([])
    const [url_foto, setUrl_Foto] = useState('')
    const [descricaoProcedimento, setDescricaoProcedimento] = useState('')
    const [datacad, setDataCad] = useState('')
    const [valorProcedimento, setValorProcedimento] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [prodSelecionado, setProdSelecionado] = useState(0)
    const [quant, setQuant] = useState('1')
    const [valorItem, setValorItem] = useState('')
    const [fotoprocedimento, setFotoProcedimento] = useState(null)
    const [desabilitaBtnAdd, setDesabilitaBtnAdd] = useState(true)
    const [desabilitaBtnSalvar, setDesabilitaBtnSalvar] = useState(false)
    const [refresh, setRefresh] = useState(false)

    useEffect( () =>{
        getProdutos()
    },[])

    useEffect( () => {
        if(id){
            getProcedimento(id)
            sessionStorage.setItem('@newID', id)
            setDesabilitaBtnAdd(false)
            setDesabilitaBtnSalvar(false)
        } else {
            getProcedimento(sessionStorage.getItem('@newID'))
        }
    },[refresh])

    async function getProcedimento(idProc: string){
        try{
            setIsLoading(true)
            const userid = sessionStorage.getItem('@userid_clinica')
            const apiClient = setupApiClient()

            const response  = await apiClient.get('/procedimento',{
                params:{
                    idempresa: userid,
                    idprocedimento: idProc
                }
            })

            const { URL_FOTO, DESCRICAO, DATACAD, VALOR, ITENS } = response.data
            
            setUrl_Foto(URL_FOTO)
            setDescricaoProcedimento(DESCRICAO)    
            setDataCad(converterDataParaTexto(DATACAD))
            setValorProcedimento(VALOR?.toFixed(2))
            setItensProcedimento(ITENS)
            setIsLoading(false)
        } catch(err) {
            console.log(err)
            setIsLoading(false)
        }        
    }

    async function getProdutos(){
        try{
            setIsLoading(true)
            const userid = sessionStorage.getItem('@userid_clinica')
            const apiClient = setupApiClient()

            const response  = await apiClient.get('/produtos',{
                params:{
                    userid: userid
                }
            })

            setProdutos(response.data)
            setValorItem(response.data[0]?.VALOR.toFixed(2))
            setIsLoading(false)
        } catch(err) {
            console.log(err)
            setIsLoading(false)
        }
    }

    async function handleSalvar(){
        if(descricaoProcedimento == ''){
            toast.warning('Digite o nome do procedimento!')
            return
        }

        try{
            setIsLoading(true)
            const userid = sessionStorage.getItem('@userid_clinica')

            if(id){
                const req = new FormData()
                req.append('userid', userid)
                req.append('descricao', descricaoProcedimento)
                req.append('idprocedimento',id)

                if(fotoprocedimento){
                    req.append('file', fotoprocedimento)
                }
    
                const apiClient = setupApiClient()
    
                const response  = await apiClient.put('/procedimento', req)
    
                if(response.data?.COD == 200){
                   toast.success('Procedimento atualizado!')
                   sessionStorage.setItem('@newID',id)
                   setIsLoading(false) 
                   setRefresh(!refresh)
                   setDesabilitaBtnAdd(false)
                   setDesabilitaBtnSalvar(true)              
                } else {
                    setIsLoading(false)
                    setDesabilitaBtnAdd(true)
                    setDesabilitaBtnSalvar(false)  
                    toast.error(response.data?.COD+' - '+response.data?.MSG)
                    return  
                }
            } else {
                const req = new FormData()
                req.append('userid', userid)
                req.append('descricao', descricaoProcedimento)
    
                if(fotoprocedimento){
                    req.append('file', fotoprocedimento)
                }
    
                const apiClient = setupApiClient()
    
                const response  = await apiClient.post('/procedimento', req)
    
                if(response.data?.COD == 201){
                   toast.success('Procedimento cadastrado!')
                   sessionStorage.setItem('@newID',response.data?.ID)
                   setIsLoading(false) 
                   setRefresh(!refresh)
                   setDesabilitaBtnAdd(false)
                   setDesabilitaBtnSalvar(true)              
                } else {
                    setIsLoading(false)
                    setDesabilitaBtnAdd(true)
                    setDesabilitaBtnSalvar(false)  
                    toast.error(response.data?.COD+' - '+response.data?.MSG)
                    return  
                }
            }
            
        } catch(err) {
            console.log(err)
            setIsLoading(false)
        }         
    }

    async function handleDeletarItem(id: number){
        try{
            setIsLoading(true)
            const userid = sessionStorage.getItem('@userid_clinica')
            const apiClient = setupApiClient()

            const response  = await apiClient.delete('/itemprocedimento',{
                params:{
                    idempresa: userid,
                    idprocedimento: sessionStorage.getItem('@newID'),
                    iditem: id 
                }
            })

            if(response.data?.COD == 200){
                toast.success('Item removido!')
                setRefresh(!refresh)
                setOpenModal(false)
                setIsLoading(false)               
            } else {
                setIsLoading(false)
                setOpenModal(false)
                toast.error(response.data?.COD+' - '+response.data?.MSG)
                return  
            }            
        } catch(err) {
            console.log(err)
            setIsLoading(false)
        }       
    }

    async function handleAddItem(){
        if(quant == ''){
            toast.warning('Quantidade inválida!')
            return
        }

        if(parseFloat(quant) <= 0){
            toast.error('Quantidade inválida!')
            return
        }

        if(valorItem == ''){
            toast.warning('Valor do item inválido!')
            return
        }

        if(parseFloat(valorItem) <= 0){
            toast.error('Valor do item inválido!')
            return
        } 
        
        const idx = itensprocedimento.findIndex(item => item?.ID_PRODUTO == produtos[prodSelecionado]?.ID_PRODUTO)

        if(idx > -1){
            toast.warning('Esse item já consta na lista!')
            return           
        }

        try{
            setIsLoading(true)
            const userid = sessionStorage.getItem('@userid_clinica')
            
            let obj = {
                userid: userid, 
                idprocedimento: sessionStorage.getItem('@newID'), 
                idproduto: produtos[prodSelecionado]?.ID_PRODUTO, 
                descricao: produtos[prodSelecionado]?.DESCRICAO, 
                url_foto: produtos[prodSelecionado]?.URL_FOTO,  
                quantidade: quant, 
                valor: valorItem, 
                total: parseFloat(quant) * parseFloat(valorItem)
            }
            
            const apiClient = setupApiClient()

            const response  = await apiClient.post('/itemprocedimento', obj)

            if(response.data?.COD == 201){
                toast.success('Item adicionado!')
                setRefresh(!refresh)
                setIsLoading(false)               
            } else {
                setIsLoading(false)
                toast.error(response.data?.COD+' - '+response.data?.MSG)
                return  
            }
        } catch(err) {
            console.log(err)
            setIsLoading(false)
        }          

    }

    function handleFoto(e: ChangeEvent<HTMLInputElement>){
        if(!e.target.files){
            return
        }

        const img = e.target.files[0];

        if (!img){
            return;
        }

        const imgSize = img.size / 1024;

        if(imgSize > 1024){
            toast.error('Ops, tamanho da imagem acima de 1mb, reduza o tamanho!')
            return
        }

        if (img.type === 'image/jpeg' || img.type === 'image/png' || img.type === 'image/jpg'){
            setFotoProcedimento(img)
            setUrl_Foto(URL.createObjectURL(img))
        } else {
            toast.error('Imagens suportadas, JPEG, PNG, JPG!')
            return            
        }         
    }

    function handleDeletar(prod: ItensProcedimentoProps){   
        let data = {
            ID: prod?.ID_ITEM,
            DESCRICAO: prod?.DESCRICAO
        }
        setDeleteProduto(data)
        setOpenModal(!openModal)
    }

    function handleProdSelecionado(e: any){
        setProdSelecionado(e.target.value)
        setValorItem(String(produtos[e.target.value]?.VALOR.toFixed(2)))
        setQuant('1')
    }

    function handleCancelar(){
        sessionStorage.removeItem('@newID')
        navigate('/procedimentos')
    }

    if(isLoading){
        return(
            <main>
                <Header/>
                <BarraLateral/>            
                <Loading/>
                <Footer/>
            </main>
        )
    }

    return(
        <main>
            <Header/>
            <BarraLateral/> 
            <section className='content-listapaciente'>

                <div className='container-titulo-paciente'>
                    <div className='textheader'>
                        <button className='btnBack' title='VOLTAR' onClick={handleCancelar}>
                            <IoArrowBack color='#555' size={25}/>
                        </button>
                        <FaProcedures color='#555' size={25}/>
                        <h3>{id ? 'Editando procedimento' : 'Novo procedimento'}</h3> 
                    </div>               
                </div>  

                <div className='div-container-proc'>
                    <div className='form-div-linha'>
                        <label className='foto-paciente'>
                            <img src={url_foto ? url_foto : img} alt='Foto' width={90}/>
                            <input type='file' accept="image/png, image/jpeg, image/jpg" onChange={handleFoto}/> 
                        </label>
                        
                        <div className='form-linha'>
                            <div className='div-inputs'>
                                <label htmlFor="descricao">NOME DO PROCEDIMENTO</label>
                                <Input
                                    style={ {width:'400px'} } 
                                    id='descricao' 
                                    placeholder='Digite a descrição...'
                                    type='text'
                                    value={descricaoProcedimento}
                                    onChange={(e) => setDescricaoProcedimento(e.target.value)}
                                />                            
                            </div>                     
                        
                            <div className='div-linha-novoproduto'>
                                <div className='div-inputs'>
                                    <label htmlFor="data">DATA CADASTRO</label>
                                    <Input 
                                        disabled={true}
                                        style={ {width:'120px', cursor:'not-allowed'} } 
                                        id='data' 
                                        placeholder='00/00/0000'
                                        type='text'
                                        value={datacad}
                                    />                                 
                                </div>        

                                <div className='div-inputs'>
                                    <label htmlFor="valor">VALOR</label>
                                    <Input 
                                        style={ {width:'150px'} } 
                                        id='valor' 
                                        placeholder='R$ 0,00'
                                        type='text'
                                        disabled={true}
                                        value={valorProcedimento}
                                    />                                 
                                </div>  
                            </div>
                        </div>
                    </div>

                    <div className='div-btn-paciente'>
                        <button id='salvar' onClick={handleSalvar} disabled={desabilitaBtnSalvar} style={{cursor: desabilitaBtnSalvar ? 'not-allowed' : 'pointer'}}>
                            <BsFillCheckCircleFill color='#FFF' size={20}/>
                            SALVAR
                        </button>

                        <button onClick={handleCancelar}>
                            <MdCancel color='#FFF' size={20}/>
                            CANCELAR
                        </button>
                    </div>

                    {/* INICIO DOS ITENS */}

                    <div className='div-itens-proc'>
                        <div className='div-inputs'>
                            <label htmlFor='select-forma'>PRODUTOS DO PROCEDIMENTO</label>
                            <select value={prodSelecionado} id="select-forma" style={{width:'400px'}} onChange={handleProdSelecionado}>
                                { produtos.length > 0 && produtos.map( (prod, index) => {
                                    return(
                                        <option key={prod?.ID_PRODUTO} value={index}>
                                            {prod?.DESCRICAO}
                                        </option>
                                    )
                                })}    
                            </select>
                        </div>  
                        
                        <div className='div-inputs'>
                            <label htmlFor='qtd-item'>QUANTIDADE</label>
                            <Input
                                id='qtd-item'
                                style={{width:'150px'}}
                                type='text'
                                placeholder='0,00' 
                                value={quant}
                                onChange={(e) => setQuant(e.target.value)}
                            />
                        </div>

                        <div className='div-inputs'>
                            <label htmlFor='valor-item'>VALOR</label>
                            <Input
                                id='valor-item'
                                style={{width:'150px'}}
                                type='text'
                                placeholder='R$ 0,00'
                                value={valorItem}
                                onChange={(e) => setValorItem(e.target.value)}
                            />
                        </div>

                        <div className='div-btn-add'>
                            <button disabled={desabilitaBtnAdd} style={{cursor: desabilitaBtnAdd ? 'not-allowed' : 'pointer'}} onClick={handleAddItem}>
                                <MdAdd size={20} color='#FFF'/>ADD 
                            </button>
                        </div>
                    </div>

                    { itensprocedimento.length <= 0 ? (
                        <div className='div-sempaciente'>
                            <CgSmileSad size={100} color='#bbb'/>
                            <span>Nenhum item cadastrado!</span>    
                        </div>
                    ) : (
                        <div className='div-gridview'>
                            <div className='gridview-header-procedimento'>
                                <span className='pr1'>FOTO</span>
                                <span className='pr2'>DESCRIÇÃO</span>
                                <span className='pr3'>QUANT</span>
                                <span className='pr4'>VALOR</span>
                                <span className='pr5'>TOTAL</span>
                                <span className='pr6'>AÇÕES</span>
                            </div>

                            <div className='gridview-body-procedimento' id='gridview-body-product'>
                                {itensprocedimento.map( (produto) => { 
                                    return(
                                        <div key={produto?.ID_ITEM} className='div-linha-prod'>
                                            <span className='pr1 center'>
                                                <img src={produto?.URL_FOTO ? produto?.URL_FOTO : foto} alt='Foto' width={30} />
                                            </span>  
                                            <span className='pr2 span-generico'>{produto?.DESCRICAO}</span>
                                            <span className='pr3 span-generico right'>{produto?.QUANTIDADE.toFixed(2).replace('.',',')}</span>
                                            <span className='pr4 span-generico right'>{produto?.VALOR.toLocaleString('pt-BR',{style:'currency', currency:'BRL'})}</span>
                                            <span className='pr5 span-generico right'>{produto?.TOTAL.toLocaleString('pt-BR',{style:'currency', currency:'BRL'})}</span>
                                            <div className='pr6 span-generico center'>
                                                <button className='btn-acao' title='EXCLUIR' onClick={() => handleDeletar(produto)}>
                                                    <FaTrash size={25} color='#FF0000'/>
                                                </button>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    )}
                </div>
            </section>

            { openModal && (
                <ModalDeleteGenerico
                    data={deleteProduto}
                    title='Produto'
                    onClose={ () => setOpenModal(!openModal)}
                    onRequest={ (id: number) => handleDeletarItem(id)}
                />
            )}

            <Footer/> 
        </main>
    )
}