import './index.css'
import { Link } from 'react-router-dom'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { AiFillCheckSquare } from 'react-icons/ai'
import { Container } from '../Container'
import { FaRegCheckCircle } from "react-icons/fa"
import { IoIosRocket, IoMdCloseCircle } from "react-icons/io"


export default function Planos(){
    return(
        <>
            <section className='planos-container' id='planos'>
                <Container>
                    <div className='planos-texto-container'>
                        <h1>Preços que cabem no seu bolso</h1>
                        <h3>Experimente grátis por 7 dias, não é necessário inserir cartão de crédito.</h3>
                        <h4>Um software web novo e que está sendo atualizado constantemente por nossa equipe de desenvolvedores em conciliação com os usuários.</h4>
                    </div>
                </Container>
            </section>
            <section className='planos-valores'>
                <Container>
                    <div className='div-planos-valores'>
                        <div className='div-plano1'>
                            <div className='plano-mod3'>
                                <h2>Básico</h2>
                                <IoIosRocket size={50} color='#737373'/>
                            </div>
                            <div className='plano-valores'>
                                <div className='plano-div-valores'>
                                    <span className='spanvalor'>R$ 49,90</span>
                                    <span className='spanmes'> por mês</span>
                                </div>
                                <Link to='/cadastrouser' target='_blank'>
                                    TESTAR GRÁTIS
                                </Link>
                                <div className='planos-txtbtn'>
                                    <span>Teste grátis pelo seu computador.</span>   
                                </div> 
                                <ul>
                                    <li>
                                        <FaRegCheckCircle color='green' size={25} />
                                        <span>Cadastro de pacientes</span>
                                    </li>
                                    <li>
                                        <FaRegCheckCircle color='green' size={25}/>
                                        <span>Ficha anamnese</span>
                                    </li>
                                    <li>
                                        <FaRegCheckCircle color='green' size={25}/>
                                        <span>Prontuário do paciente</span>
                                    </li>
                                    <li>
                                        <FaRegCheckCircle color='green' size={25}/>
                                        <span>Cadastro de materiais</span>
                                    </li>
                                    <li>
                                        <FaRegCheckCircle color='green' size={25}/>
                                        <span>Cadastro de procedimentos</span>
                                    </li>
                                    <li>
                                        <FaRegCheckCircle color='green' size={25}/>
                                        <span>Agenda de pacientes</span>
                                    </li>
                                    <li>
                                        <IoMdCloseCircle color='#B50003' size={25}/>
                                        <span>Contas a pagar </span>
                                    </li>
                                    <li>
                                        <IoMdCloseCircle color='#B50003' size={25}/>
                                        <span>Contas a receber e faturamento</span>
                                    </li>
                                    <li>
                                        <IoMdCloseCircle color='#B50003' size={25}/>
                                        <span>Página exclusiva minha clínica</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className='div-plano2'>
                            <div className='plano-mod3'>
                                <h2>Controle</h2>
                                <IoIosRocket size={50} color='#737373'/>
                            </div>
                            <div className='plano-valores'>
                                <div className='plano-div-valores'>
                                    <span className='spanvalor'>R$ 99,90</span>
                                    <span className='spanmes'> por mês</span>
                                </div>
                                <Link to='/cadastrouser' target='_blank'>
                                    TESTAR GRÁTIS
                                </Link>
                                <div className='planos-txtbtn'>
                                    <span>Teste grátis pelo seu computador.</span>   
                                </div> 
                                <ul>
                                    <li>
                                        <FaRegCheckCircle color='green' size={25} />
                                        <span>Cadastro de pacientes</span>
                                    </li>
                                    <li>
                                        <FaRegCheckCircle color='green' size={25}/>
                                        <span>Ficha anamnese</span>
                                    </li>
                                    <li>
                                        <FaRegCheckCircle color='green' size={25}/>
                                        <span>Prontuário do paciente</span>
                                    </li>
                                    <li>
                                        <FaRegCheckCircle color='green' size={25}/>
                                        <span>Cadastro de materiais</span>
                                    </li>
                                    <li>
                                        <FaRegCheckCircle color='green' size={25}/>
                                        <span>Cadastro de procedimentos</span>
                                    </li>
                                    <li>
                                        <FaRegCheckCircle color='green' size={25}/>
                                        <span>Agenda de pacientes</span>
                                    </li>
                                    <li>
                                        <FaRegCheckCircle color='green' size={25}/>
                                        <span>Contas a pagar </span>
                                    </li>
                                    <li>
                                        <FaRegCheckCircle color='green' size={25}/>
                                        <span>Contas a receber e faturamento</span>
                                    </li>
                                    <li>
                                        <IoMdCloseCircle color='#B50003' size={25}/>
                                        <span>Página exclusiva minha clínica</span>
                                    </li>
                                </ul>
                            </div>
                        </div> 
                        <div className='div-plano3'>
                            <div className='plano-mod3'>
                                <h2>Avançado</h2>
                                <IoIosRocket size={50} color='#e9e9e9'/>
                            </div>
                            <div className='plano-valores'>
                                <div className='plano-div-valores'>
                                    <span className='spanvalor3'>R$ 149,90</span>
                                    <span className='spanmes3'> por mês</span>
                                </div>
                                <Link to='/cadastrouser' target='_blank'>
                                    TESTAR GRÁTIS
                                </Link>
                                <div className='planos-txtbtn'>
                                    <span>Teste grátis pelo seu computador.</span>   
                                </div> 
                                <ul className='ul-mod3'>
                                    <li>
                                        <FaRegCheckCircle color='green' size={25} />
                                        <span>Cadastro de pacientes</span>
                                    </li>
                                    <li>
                                        <FaRegCheckCircle color='green' size={25}/>
                                        <span>Ficha anamnese</span>
                                    </li>
                                    <li>
                                        <FaRegCheckCircle color='green' size={25}/>
                                        <span>Prontuário do paciente</span>
                                    </li>
                                    <li>
                                        <FaRegCheckCircle color='green' size={25}/>
                                        <span>Cadastro de materiais</span>
                                    </li>
                                    <li>
                                        <FaRegCheckCircle color='green' size={25}/>
                                        <span>Cadastro de procedimentos</span>
                                    </li>
                                    <li>
                                        <FaRegCheckCircle color='green' size={25}/>
                                        <span>Agenda de pacientes</span>
                                    </li>
                                    <li>
                                        <FaRegCheckCircle color='green' size={25}/>
                                        <span>Contas a pagar </span>
                                    </li>
                                    <li>
                                        <FaRegCheckCircle color='green' size={25}/>
                                        <span>Contas a receber e faturamento</span>
                                    </li>
                                    <li>
                                        <FaRegCheckCircle color='green' size={25}/>
                                        <span>Página exclusiva minha clínica</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
        </>
    )
}