import { useState, useContext, useEffect, ChangeEvent } from 'react'
import './contasreceber.css'
import { AuthContext } from '../../contexts/AuthContext'
import Header from '../../components/Header'
import BarraLateral from '../../components/BarraLateral'
import Loading from '../../components/Loading'
import Footer from '../../components/Footer'
import { GiCash, GiStamper } from 'react-icons/gi'
import { MdOutlineHistoryEdu } from "react-icons/md"
import { Input } from '../../components/Input'
import { IoMdPaper } from "react-icons/io"
import { BiUndo } from "react-icons/bi"
import { setupApiClient } from '../../services/api'
import { converterDataParaTexto, isFloat } from '../../utils/utils'
import { CgSmileSad } from 'react-icons/cg'
import ModalProntuario from '../../components/ModalProntuario'
import { ProntuariosProps } from '../Prontuario'
import { toast } from 'react-toastify';
import ModalBaixar, { ValorProps } from '../../components/ModalBaixar'
import ModalHistoricoPagamento from '../../components/ModalHistorico'
import ModalDesfazerPagto from '../../components/ModalDesfazerPagto'

export interface ContasReceberProps{
    UUID_AGENDA: string
    ID_PACIENTE: number
    NOMEPACIENTE: string
    EMISSAO: string
    PARCELA: string
    VENCIMENTO: string
    VALOR: number
    FORMAPAGAMENTO: string
    OBSPAGAMENTO: string
    ADIANTADO: number
}

export interface ContasRecebidasProps{
    UUID_AGENDA: string
    ID_PACIENTE: number
    NOMEPACIENTE: string
    VENCIMENTO: string
    PARCELA: string
    DATAPAGTO: string
    VALOR: number
    FORMAPAGAMENTO: string
    JUROS: number
}

export interface HistoricoPagamentoProps{
    UUID_AGENDA: string
    IDEMPRESA: number
    IDPACIENTE: number
    PARCELA: string
    DATAPAGTO: string
    DESCRICAO: string
    VALORPAGO: number
}

export default function ContasReceber(){
    const { user } = useContext(AuthContext)
    const [isLoaging, setIsLoading] = useState(false)
    const [tabselecionada, setTabSelecionada] = useState(1)
    const [contasreceber, setContasReceber] = useState<ContasReceberProps[]>([])
    const [contasrecidas, setContasRecebidas] = useState<ContasRecebidasProps[]>([])
    const [totalreceber, setTotalReceber] = useState(0)
    const [totalrecebido, setTotalRecebido] = useState(0)
    const [modalDetalhe, setModalDetalhe] = useState(false)
    const [detalheAtendimento, setDetalheAtendimento] = useState<ProntuariosProps>()
    const [modalBaixar, setModalBaixar] = useState(false)
    const [contaBaixar, setContaBaixar] = useState<ContasReceberProps>()
    const [refresh, setRefresh] = useState(false)
    const [modalHistorico, setModalHistorico] = useState(false)
    const [historicopagamento, setHistoricoPagamento] = useState<HistoricoPagamentoProps[]>([])
    const [modaldesfazer, setModalDesfazer] = useState(false)
    const [desfazerBaixa, setDesfazerBaixa] = useState<ContasRecebidasProps>()

    useEffect( () => {
        getContasreceber()
    },[refresh])

    useEffect( () => {
        getContasrecidas()
    },[contasreceber])
    
    async function getContasreceber(){
        try{
            setIsLoading(true)
            const apiClient = setupApiClient()

            const response  = await apiClient.get('/contasreceber',{
                params:{
                    userid: user?.id
                }
            })

            if(response.data.length > 0){
                let soma = 0
                response.data.map( (conta: ContasReceberProps) => {
                    soma += (conta?.VALOR - conta?.ADIANTADO)
                })

                setTotalReceber(soma)
            }

            setContasReceber(response.data)
            setIsLoading(false)
        } catch(err) {
            console.log(err)
            setIsLoading(false)
        }
    }

    async function getContasrecidas(){
        try{
            setIsLoading(true)
            const apiClient = setupApiClient()

            const response  = await apiClient.get('/contasrecebidas',{
                params:{
                    userid: user?.id
                }
            })

            if(response.data.length > 0){
                let soma = 0
                response.data.map( (conta: ContasRecebidasProps) => {
                    soma += (conta?.VALOR - conta?.JUROS)
                })
                
                setTotalRecebido(soma)
            }

            setContasRecebidas(response.data)
            setIsLoading(false)
        } catch(err) {
            console.log(err)
            setIsLoading(false)
        }
    }

    async function requestDesfazerPagamento(){
        try{
            setIsLoading(true)
            const apiClient = setupApiClient()

            let obj = {
                uuid: desfazerBaixa?.UUID_AGENDA, 
                idempresa: user?.id, 
                idpaciente: desfazerBaixa?.ID_PACIENTE,
                parcela: desfazerBaixa?.PARCELA,
                valorpago: desfazerBaixa?.VALOR
            }

            const response  = await apiClient.put('/desfazerbaixa', obj)

            if(response.data?.COD == 200){
                toast.success('Baixa desfeita com sucesso!')
                setRefresh(!refresh)
            } else {
                toast.error('Erro desfazendo a baixa!')
            }

            setIsLoading(false)
            setModalDesfazer(false)
        } catch(err) {
            console.log(err)
            setIsLoading(false)
            setModalDesfazer(false)
        }        
    }

    async function requestBaixarConta(conta: ContasReceberProps, valor: ValorProps){
        let baixar = ''

        if(!isFloat(valor?.valorpago)){
            toast.warn('Valor pago inválido!')
            return
        }

        if( parseFloat(valor?.valorpago) >= (conta?.VALOR - conta?.ADIANTADO) ){
            baixar = 'S'
        } else {
            baixar = 'N'
        }

        try{
            setIsLoading(true)

            let obj = {
                uuid: conta?.UUID_AGENDA,
                idempresa: user?.id,
                idpaciente: conta?.ID_PACIENTE,
                valorpago: valor?.valorpago,
                juros: valor?.juros,
                parcela: conta?.PARCELA,
                baixar: baixar
            }

            const apiClient = setupApiClient()

            const response = await apiClient.put('/baixarconta', obj)

            if(response.data?.COD == 200){
                if(baixar == 'S'){
                    toast.success('Conta baixada com sucesso!')
                } else {
                    toast.success('Pagamento registrado!')
                }

                setRefresh(!refresh)
                setIsLoading(false)
                setModalBaixar(false)
            } else {
                console.log(response.data?.COD+'-'+response.data?.MSG)
                toast.error('Erro baixando a conta!')
                setModalBaixar(false)
                setIsLoading(false)
                return  
            }
           
           
        } catch(err){
            console.log(err)
            toast.error('Erro baixando conta! :(')
            setIsLoading(false)
        } 
    }

    async function handleDetalhe(conta: ContasReceberProps){
        try{
            setIsLoading(true)
            const apiClient = setupApiClient()

            const response = await apiClient.get('/prontuario',{
                params:{
                    userid: user?.id,
                    idpaciente: conta?.ID_PACIENTE,
                    uuid: conta?.UUID_AGENDA
                }
            })
            
            setIsLoading(false)
            setDetalheAtendimento(response.data)
            setModalDetalhe(true)           
        } catch(err){
            console.log(err)
            toast.error('Erro buscando os detalhes! :(')
            setIsLoading(false)
            setModalDetalhe(false)
        }
    }

    async function handleDesfazer(conta: ContasRecebidasProps){
        setDesfazerBaixa(conta)
        setModalDesfazer(true)
    }

    async function handleHistorico(conta: any){
        try{
            setIsLoading(true)
            const apiClient = setupApiClient()

            const response = await apiClient.get('/historicopagamento',{
                params:{
                    userid: user?.id,
                    idpaciente: conta?.ID_PACIENTE,
                    uuid: conta?.UUID_AGENDA,
                    parcela: conta?.PARCELA
                }
            })
            
            setIsLoading(false)
            
            if(response.data.length > 0){
                setHistoricoPagamento(response.data)
                setModalHistorico(true)
            } else {
                toast.warn('Não existe histórico de pagamento!')
            }
        } catch(err){
            console.log(err)
            toast.error('Erro buscando o histórico! :(')
            setIsLoading(false)
            setModalHistorico(false)
        }        
    }

    function handleBaixar(conta: ContasReceberProps){
        setContaBaixar(conta)
        setModalBaixar(true)
    }

    function handleTab(value: number){
        let tablinks = document.getElementsByClassName('tablinks');
        for (let i = 0; i < tablinks.length; i++) {
          tablinks[i].className = tablinks[i].className.replace(' active', '');
        }

        switch(value){
            case 1: 
                document.getElementById('tabreceber').className += ' active';
                break;
            case 2:
                document.getElementById('tabrecebido').className += ' active';
                break;
            default:
                document.getElementById('tabreceber').className += ' active';
                break;                  
        }

        setTabSelecionada(value)    
    }

        function pesquisaContas(e: ChangeEvent<HTMLInputElement>) {
            let filtrar: string, texto: string
            const prod = document.getElementById('gridview-body').getElementsByClassName('div-linha-receber')
    
            filtrar = e.target.value.toUpperCase()
    
            for(let i=0; i < prod.length; i++){
                texto = prod[i].innerHTML
    
                if(texto.toUpperCase().indexOf(filtrar) > -1){
                    prod[i].classList.remove('ocultarLinha')
                } else {
                    prod[i].classList.add('ocultarLinha')  
                }
            }
        }
    
    if(isLoaging){
        return(
            <main>
                <Header/>
                <BarraLateral/>            
                <Loading/>
                <Footer/>
            </main>
        )
    }

    return(
        <main>
            <Header/>
            <BarraLateral/>
            <section className='content-listapaciente'>

                <div className='container-titulo-paciente'>
                    <div className='div-titulo-tela'>
                        <div className='textheader'>
                            <GiCash color='#555' size={25}/>
                            <h3>Você está em contas a receber</h3>   
                        </div>   
                    </div>
                </div>  

                <div className='container-filtro-contasreceber'>
                    <div className='div-input-filtro'>
                        <div className='div-filtrar'>
                            <label htmlFor="inputNome">PESQUISAR POR NOME</label>
                            <Input
                                id='inputNome'
                                placeholder='Digite o nome...'
                                type='search'
                                onChange={(e) => pesquisaContas(e)}
                            />
                        </div> 

                        <div className='div-total'>
                            { tabselecionada == 1 ? (
                                <>
                                    <label>TOTAL A RECEBER</label>
                                    <span>{totalreceber.toLocaleString('pt-BR',{style:'currency', currency:'BRL'})}</span>
                                </>
                            ) : (
                                <>
                                    <label>TOTAL RECEBIDO</label>
                                    <span>{totalrecebido.toLocaleString('pt-BR',{style:'currency', currency:'BRL'})}</span>
                                </>
                            )}
                        </div>
                    </div>
                </div>

                <div className='tabscontas'>
                    <button
                        className='tablinks active'
                        id='tabreceber'
                        onClick={() => handleTab(1)}
                        >
                        CONTAS A RECEBER
                    </button>

                    <button
                        className='tablinks'
                        id='tabrecebido'
                        onClick={() => handleTab(2)}
                    >
                        CONTAS RECEBIDAS
                    </button>
                </div> 

                { tabselecionada == 1 && (
                    <section className='tab-receber'>
                        { contasreceber.length <= 0 ? (
                            <div className='div-sempaciente'>
                                <CgSmileSad size={120} color='#bbb'/>
                                <span>Nenhuma conta encontrada!</span>    
                            </div>
                        ) : (
                            <>
                                <div className='gridview-header'>
                                    <span className='cr1'>NOME PACIENTE</span>
                                    <span className='cr2'>EMISSÃO</span>
                                    <span className='cr3'>PARCELA</span>
                                    <span className='cr4'>VENCIMENTO</span>
                                    <span className='cr5'>FORMA DE PAGTO</span>
                                    <span className='cr6'>ADIANTADO</span>
                                    <span className='cr7'>VALOR</span>
                                    <span className='cr8'>SALDO</span>
                                    <span className='cr9'>AÇÕES</span>
                                </div>

                                <div className='gridview-body' id='gridview-body'>
                                    { contasreceber.map( (conta, index) => {
                                        return(
                                            <div key={index} className='div-linha-receber'>
                                                <span className='cr1 span-generico'>{conta?.NOMEPACIENTE}</span>
                                                <span className='cr2 span-generico'>{converterDataParaTexto(conta?.EMISSAO)}</span>
                                                <span className='cr3 span-generico'>{conta?.PARCELA}</span>
                                                <span className='cr4 span-generico'><strong>{converterDataParaTexto(conta?.VENCIMENTO)}</strong></span>
                                                <span className='cr5 span-generico'>{conta?.FORMAPAGAMENTO}</span>
                                                <span className='cr6 span-generico alinha-valor'>{conta?.ADIANTADO.toLocaleString('pt-BR',{style:'currency', currency:'BRL'})}</span>
                                                <span className='cr7 span-generico alinha-valor'>{conta?.VALOR.toLocaleString('pt-BR',{style:'currency', currency:'BRL'})}</span>
                                                <span className='cr8 span-generico saldo'>{(conta?.VALOR - conta?.ADIANTADO).toLocaleString('pt-BR',{style:'currency', currency:'BRL'})}</span>
                                                <div className='cr9 div-btn-baixar'>
                                                    <button className='btn-det' title='DETALHE AGENDA' onClick={() => handleDetalhe(conta)}>
                                                        <IoMdPaper color='#fff' size={15} />
                                                    </button>
                                                    <button className='btn-det' title='DETALHE PAGAMENTO' onClick={() => handleHistorico(conta)}>
                                                        <MdOutlineHistoryEdu color='#fff' size={15} />
                                                    </button>
                                                    <button className='btn-baixar' onClick={() => handleBaixar(conta)}>
                                                        <GiStamper color='#fff' size={15} />
                                                        BAIXAR
                                                    </button>
                                                </div>
                                            </div>
                                        )
                                    })}
                                    <span className='sp-qtd-registro'>QUANTIDADE DE REGISTROS: {contasreceber.length}</span>
                                </div>
                            </>
                        )}
                    </section>
                )}

                { tabselecionada == 2 && (
                    <section className='tab-receber'>
                        { contasrecidas.length <= 0 ? (
                            <div className='div-sempaciente'>
                                <CgSmileSad size={120} color='#bbb'/>
                                <span>Nenhuma conta encontrada!</span>    
                            </div>
                        ) : (
                            <>
                                <div className='gridview-header'>
                                    <span className='cr1'>NOME PACIENTE</span>
                                    <span className='cr2'>VENCIMENTO</span>
                                    <span className='cr3'>PARCELA</span>
                                    <span className='cr4'>DATA PAGTO</span>
                                    <span className='cr5'>FORMA DE PAGTO</span>
                                    <span className='cr6'>VALOR</span>
                                    <span className='cr7'>JUROS</span>
                                    <span className='cr8'>VALOR RECEBIDO</span>
                                    <span className='cr9'>AÇÕES</span>
                                </div>
                            
                                <div className='gridview-body' id='gridview-body'>
                                    { contasrecidas.map( (conta, index) => {
                                        return(
                                            <div key={index} className='div-linha-receber'>
                                                <span className='cr1 span-generico'>{conta?.NOMEPACIENTE}</span>
                                                <span className='cr2 span-generico'>{converterDataParaTexto(conta?.VENCIMENTO)}</span>
                                                <span className='cr3 span-generico'>{conta?.PARCELA}</span>
                                                <span className='cr4 span-generico'><strong>{converterDataParaTexto(conta?.DATAPAGTO)}</strong></span>
                                                <span className='cr5 span-generico'>{conta?.FORMAPAGAMENTO}</span>
                                                <span className='cr6 span-generico alinha-valor'>{conta?.VALOR.toLocaleString('pt-BR',{style:'currency', currency:'BRL'})}</span>
                                                <span className='cr7 span-generico alinha-valor'>{conta?.JUROS.toLocaleString('pt-BR',{style:'currency', currency:'BRL'})}</span>
                                                <span className='cr8 span-generico saldo'>{(conta?.VALOR + conta?.JUROS).toLocaleString('pt-BR',{style:'currency', currency:'BRL'})}</span>
                                                <div className='cr9 div-btn-baixar'>
                                                    <button className='btn-det' title='DETALHE PAGAMENTO'  onClick={() => handleHistorico(conta)}>
                                                        <MdOutlineHistoryEdu color='#fff' size={15} />
                                                    </button>
                                                    <button className='btn-desfazer' onClick={() => handleDesfazer(conta)}>
                                                        <BiUndo color='#fff' size={15} />
                                                        DESFAZER
                                                    </button>
                                                </div>
                                            </div>
                                        )
                                    })}
                                    <span className='sp-qtd-registro'>QUANTIDADE DE REGISTROS: {contasrecidas.length} - RESULTADO DOS ÚLTMOS 90 DIAS</span>
                                </div>                                                
                            </> 
                        )}
                    </section>
                )}

            </section>

            { modalDetalhe && (
                <ModalProntuario
                    atendimento={detalheAtendimento}
                    onClose={() => setModalDetalhe(false)}
                />
            )}

            { modalBaixar && (
                <ModalBaixar
                    data={contaBaixar}
                    onRequest={(conta, valorpago) => requestBaixarConta(conta, valorpago)}
                    onClose={() => setModalBaixar(false)}
                />
            )}

            { modalHistorico && (
                <ModalHistoricoPagamento
                    data={historicopagamento}
                    onClose={() => setModalHistorico(false)}
                />
            )}

            { modaldesfazer && (
                <ModalDesfazerPagto
                    data={desfazerBaixa}
                    onRequest={requestDesfazerPagamento}
                    onClose={() => setModalDesfazer(false)}
                />
            )}

            <Footer/>
        </main>
    )
}