import { useState, useEffect, useContext } from 'react'
import { useParams } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import BarraLateral from "../../components/BarraLateral";
import { IoArrowBack } from 'react-icons/io5'
import { FaClipboardList, FaSearch, FaRegSadTear } from 'react-icons/fa'
import { AiFillPrinter } from 'react-icons/ai'
import { useNavigate } from "react-router-dom";
import './index.css'
import { Input } from '../../components/Input';
import { Button } from '../../components/Button';
import Loading from '../../components/Loading';
import { toast } from 'react-toastify';
import { setupApiClient } from '../../services/api';
import ModalProntuario from '../../components/ModalProntuario';
import GerarProntuarioPDF from '../../reports/Prontuario';
import { AuthContext } from '../../contexts/AuthContext';
import { converterDataParaTexto } from '../../utils/utils';

export interface ProntuariosProps {
    UUID: string
    ID_EMPRESA: number
    ID_PACIENTE: number
    DATA_AGENDA: string
    TRATAMENTO: string
    OBSERVACOES: string
    TRATAMENTO_REALIZADO: string
    FINALIZADO: string
    NOME: string
}

export default function Prontuario(){
    const { user } = useContext(AuthContext)
    const { id, paciente } = useParams()
    const navigate = useNavigate()

    const [isLoaging, setIsLoading] = useState(false)
    const [prontuarios, setProntuarios] = useState<ProntuariosProps[]>([])
    const [detalheAtendimento, setDetalheAtendimento] = useState<ProntuariosProps>()
    const [nome, setNome] = useState('')
    const [modalProntuario, setModalProntuario] = useState(false)
    const [datainicial, setDataInicial] = useState('')
    const [datafinal, setDataFinal] = useState('')

    useEffect( () => {
        setNome(paciente)
    },[])

    async function getProntuarios(){
        try{
            setProntuarios([])
            setIsLoading(true)
            const apiClient = setupApiClient()

            const response = await apiClient.get('/prontuarios',{
                params:{
                    userid: user?.id,
                    idpaciente: id,
                    datainicial: converterDataParaTexto(datainicial),
                    datafinal: converterDataParaTexto(datafinal)
                }
            })
            
            setProntuarios(response.data)
            setIsLoading(false)
           
        } catch(err){
            console.log(err)
            toast.error('Erro buscando prontuários! :(')
            setIsLoading(false)
        }
    }

    function handlePesquisar(){
        if(datafinal == '' && datafinal == ''){
            toast.warning('Escolha o período de busca!')
            return 
        }

        getProntuarios()
    }

    function handleDetalhes(atend: ProntuariosProps){
        setDetalheAtendimento(atend)
        setModalProntuario(true)
    }

    function ImprimiProntuario(){
        if(prontuarios.length <= 0 ){
            toast.warn('Não existe prontuários para imprimir!')
            return
        }

        let obj = {
            dadosClinica: user,
            caminhoLogo:'',
            dadosPaciente: prontuarios
        }

        GerarProntuarioPDF(obj)
    }

    if(isLoaging){
        return(
            <main>
                <Header/>
                <BarraLateral/>            
                <Loading/>
                <Footer/>
            </main>
        )
    }

    return(
        <main>
            <Header/>
            <BarraLateral/> 
            <section className='content-listapaciente'>
                <div className='container-titulo-paciente'>
                    <div className='textheader'>
                        <button className='btnBack' title='VOLTAR' onClick={ () => navigate('/pacientes')}>
                            <IoArrowBack color='#555' size={25}/>
                        </button>
                        <FaClipboardList color='#555' size={20}/>
                        <h3>Prontuário - {nome}</h3>  
                    </div>               
                </div>

                <div className='container-filtro-prontuario'>
                    <div className='div-filtro-inputs'>
                        <div className='div-inputs'>
                            <label htmlFor="nasc">DATA INICIAL</label>
                            <Input 
                                style={ {width:'120px'}}
                                id='dtinicial' 
                                placeholder='00/00/0000'
                                type='date'
                                value={datainicial}
                                onChange={ (e) => setDataInicial(e.target.value)}
                            />
                        </div>

                        <div className='div-inputs'>
                            <label htmlFor="nasc">DATA FINAL</label>
                            <Input 
                                style={ {width:'120px'}}
                                id='dtfinal' 
                                placeholder='00/00/0000'
                                type='date'
                                value={datafinal}
                                onChange={ (e) => setDataFinal(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className='container-prontbtn'>
                        <Button id='btnPesqPront' onClick={handlePesquisar}>
                            <FaSearch color='#fff' size={15}/>
                            PESQUISAR
                        </Button>

                        <Button id='btnPrintPront' onClick={ImprimiProntuario}>
                            <AiFillPrinter color='#fff' size={20}/>
                            IMPRIMIR
                        </Button>
                    </div>

                    <div className='div-qtdatendimento'>
                        <span>QUANTIDADE DE ANTENDIMENTOS: {prontuarios.length}</span>
                    </div>
                </div>

                <div className='div-container-cards'>
                    <div className='card-linha'>
                        {prontuarios.length <= 0 ? (
                            <div className='div-semprontuario'>
                                <FaRegSadTear color='#555' size={100}/>
                                <span>Paciente sem prontuário!</span>
                            </div>
                        ) : prontuarios.map( (atend) => {
                            let data = atend?.DATA_AGENDA.split('-')
                            return(
                                <div key={atend?.UUID} className='card'>
                                    <FaClipboardList color='blue' size={40}/> 
                                    <span>{atend?.TRATAMENTO}</span>
                                    <span style={{fontSize:'16px'}}>{data[2].substring(0,2)+'/'+data[1]+'/'+data[0]}</span>
                                    <button onClick={() => handleDetalhes(atend)}>
                                        VER DETALHES
                                    </button>    
                                </div> 
                            )
                        })}
                    </div>
                </div>


            </section>

            { modalProntuario && (
                <ModalProntuario
                    atendimento={detalheAtendimento}
                    onClose={() => setModalProntuario(false)}
                />
            )}
            
            <Footer/> 
        </main>
    )
}

