import React from 'react'
import MenuMobile from '../../components/Home/MenuMobile'
import HeaderHome from "../../components/Home/Header"
import BannerCarousel from '../../components/Home/Carousel'
import BoasVindas from '../../components/Home/BoasVindas'
import Planos from '../../components/Home/Planos'
import Contato from '../../components/Home/Contato'
import FooterHome from '../../components/Home/FooterHome'

export default function Home(){

    function handletoggle(){
        const nav = document.querySelector('.menumobile-box')
        nav.classList.toggle('active')   
    }

    return(
        <>
            <HeaderHome
                openMenu={handletoggle}
            />
            <BoasVindas/>   
            <BannerCarousel/>
            <Planos/>
            <Contato/>
            <FooterHome/>

            <MenuMobile
                openMenu={handletoggle}   
            />
        </>
    )
}