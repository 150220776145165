import { useState } from 'react'
import './modalbaixar.css'
import { FaWindowClose } from 'react-icons/fa'
import { Input } from '../Input'
import { GiStamper } from 'react-icons/gi'
import { MdCancel } from 'react-icons/md'
import { ContasReceberProps } from '../../pages/ContasReceber'

export interface ValorProps{
    valorpago: string
    juros: string
}

interface ModalProps{
    data: ContasReceberProps
    onClose: () => void
    onRequest: (conta: ContasReceberProps, valor: ValorProps) => Promise<void>
}


export default function ModalBaixar({ data, onRequest, onClose }: ModalProps){
    const [juros, setJuros] = useState('0')
    const [totalpagar, setTotalPagar] = useState((data?.VALOR - data?.ADIANTADO))
    const [valorpago, setValorPago] = useState((data?.VALOR - data?.ADIANTADO).toString())

    function handleCalcJuros(e: any){

        let total = 0

        if(e.target.value == ''){
            setTotalPagar( (data?.VALOR - data?.ADIANTADO) )
            total = (data?.VALOR - data?.ADIANTADO)
            setValorPago(total.toString())
            setJuros('')
        } else {
            setJuros(e.target.value)
            setTotalPagar( (data?.VALOR - data?.ADIANTADO) + parseFloat(e.target.value) )
            total = (data?.VALOR - data?.ADIANTADO) + parseFloat(e.target.value)
            setValorPago(total.toString())
        }

    }

    return(
        <div className='box-modal'>
            <div className='content-modal-baixar'>
                <div className='btnCloseModal'>
                    <button onClick={onClose}>
                        <FaWindowClose size={20} color='#FFF'/>
                    </button>
                </div>   

                <div className='div-container-valores'>
                    <div className='div-valor'>
                        <label>VALOR DA CONTA:</label>
                        <span id='valorconta'>{data?.VALOR.toLocaleString('pt-BR',{style:'currency', currency:'BRL'})}</span>
                    </div>

                    <div className='div-valor'>
                        <label>VALOR ADIANTADO:</label>
                        <span id='valoradiantado'>{data?.ADIANTADO.toLocaleString('pt-BR',{style:'currency', currency:'BRL'})}</span>
                    </div>

                    <div className='div-valor'>
                        <label>VALOR JUROS:</label>
                        <Input
                            type='text'
                            placeholder='Juros cobrado...'
                            value={juros}
                            onChange={handleCalcJuros}
                        />
                    </div>

                    <div className='div-valor'>
                        <label>TOTAL A PAGAR:</label>
                        <span id='valorpagar'>{totalpagar.toLocaleString('pt-BR',{style:'currency', currency:'BRL'})}</span>
                    </div>

                    <div className='div-valor'>
                        <label>VALOR PAGO:</label>
                        <Input
                            type='text'
                            placeholder='Valor cobrado...'
                            value={valorpago}
                            onChange={(e) => setValorPago(e.target.value)}
                        />
                    </div>
                </div>

                <div className='div-cont-btn'>
                    <button
                        id='btn-baixar'
                        onClick={() => onRequest(data, {
                            valorpago: valorpago, 
                            juros: juros == '' ? '0' : juros} )}
                    >
                        <GiStamper color='#fff' size={15} />
                        BAIXAR
                    </button>

                    <button
                        id='btn-cancelar'
                        onClick={onClose}
                    >
                        <MdCancel color='#FFF' size={20}/>
                        CANCELAR
                    </button>
                </div>
            </div>
        </div>
    )
}