import { useNavigate } from 'react-router-dom'

export default function ItensRelatorios(){
    const navigate = useNavigate()

    return(
        <ul className='ul-itens'>
            <li>RELAT DE PACIENTES</li>
            <li>RELAT DE MATERIAIS</li>
            <li>RELAT DE PROCEDIMENTOS</li>
            <span></span>
            <li>RELAT DA AGENDA</li>
            <span></span>
            <li>RELAT DE FATURAMENTO</li>
            <li>RELAT DE CONTAS A RECEBER</li>
            <li>RELAT DE CONTAS RECEBIDAS</li>
            <li>RELAT DE CONTAS A PAGAR</li>
        </ul>
    )
}