import axios, { AxiosError } from 'axios'
import { parseCookies } from 'nookies'
import { AuthErrorToken } from './errors/AuthErrorToken'
import { signOut } from '../contexts/AuthContext'

export function setupApiClient(){

    let cookies = parseCookies()

    const api = axios.create({
        baseURL: process.env.REACT_APP_HOST,
        headers:{
            Authorization: `Bearer ${cookies['@MinhaClinicaToken']}`
        }
    })

    api.interceptors.response.use(response => {
        return response;
    }, (error: AxiosError) => {
        if(error.response.status === 401){
            if (typeof window !== undefined){
                signOut()
            } else {
                return Promise.reject(new AuthErrorToken())
            }
        }

        return Promise.reject(error)
    })

    return api;
}