import { useState, useContext } from 'react'
import './index.css'
import { FaUsers } from 'react-icons/fa'
import { MdNavigateNext } from 'react-icons/md'
import { GiHouseKeys, GiCash } from 'react-icons/gi'
import { MdCancel } from 'react-icons/md'
import { FiSettings } from 'react-icons/fi'
import { AuthContext } from '../../contexts/AuthContext'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { VscCalendar } from 'react-icons/vsc'
import { IoPrintSharp } from "react-icons/io5"

import ItensCadastro from './ItensCadastro'
import ItensAgenda from './ItensAgenda'
import ItensFinanceiro from './ItensFinanceiro'
import ItensOpcoes from './ItensOpcoes'
import ItensMinhaConta from './ItensMinhaConta'
import ItensRelatorios from './ItensRelatorios'

export default function OpcoesMenu(){
    const { signOut, user } = useContext(AuthContext)
    const navigate = useNavigate()

    const [menuCadastro, setMenuCadastro] = useState(false)
    const [menuAgenda, setMenuAgenda] = useState(false)
    const [menuFinanceiro, setMenuFinanceiro] = useState(false)
    const [menuOpcoes, setMenuOpcoes] = useState(false)
    const [menuMinhaConta, setMenuMinhaConta] = useState(false)
    const [menuRelatorios, setMenuRelatorios] = useState(false)

    async function handleSair(){
        await signOut()
        toast.info(`Até logo ${user?.nome}`)
        navigate('/')
    }

    function desabilitaItens(){
        setMenuCadastro(false)
        setMenuAgenda(false)
        setMenuFinanceiro(false)
        setMenuOpcoes(false)
        setMenuMinhaConta(false)
        setMenuRelatorios(false)
    }

    function handleMenu(op: number){
        desabilitaItens()

        switch (op) {
            case 1: 
                setMenuCadastro(true)
                break
            case 2:
                setMenuAgenda(true)
                break
            case 3:
                setMenuFinanceiro(true)
                break
            case 4:
                setMenuOpcoes(true)
                break
            case 5:
                setMenuMinhaConta(true)
                break
            case 6:
                setMenuRelatorios(true)
                break
            default:
                desabilitaItens()
                break
        }
    }

    return(
        <nav className='opcoes-menu'>
            <div className='divisao-menu'>
                <div className='opcoes'>
                    <div className='opcao' onClick={() => handleMenu(1)}>
                        <div>
                            <FaUsers size={20} color='#555'/>
                            <span>CADASTROS</span>
                        </div>
                        <MdNavigateNext size={15} color='#000'/>
                    </div>                   
                    <div className='opcao' onClick={() => handleMenu(2)}>
                        <div>
                            <VscCalendar size={20} color='#555'/>
                            <span>AGENDA</span>
                        </div>
                        <MdNavigateNext size={15} color='#000'/>
                    </div>

                    <div className='opcao' onClick={() => handleMenu(3)}>
                        <div>
                            <GiCash size={20} color='#555'/>
                            <span>FINANCEIRO</span>
                        </div>
                        <MdNavigateNext size={15} color='#000'/>
                    </div>
                    <div className='opcao'  onClick={() => handleMenu(4)}>
                        <div>
                            <FiSettings size={20} color='#555'/>
                            <span>OPÇÕES GERAIS</span>
                        </div>
                        <MdNavigateNext size={15} color='#000'/>
                    </div>
                    <div className='opcao'  onClick={() => handleMenu(6)}>
                        <div>
                            <IoPrintSharp size={20} color='#555'/>
                            <span>RELATÓRIOS</span>
                        </div>
                        <MdNavigateNext size={15} color='#000'/>
                    </div>
                    <div className='opcao foo' onClick={() => handleMenu(5)}>
                        <div>
                            <GiHouseKeys size={20} color='#454545'/>
                            <span>MINHA CONTA</span>
                        </div>
                        <MdNavigateNext size={15} color='#000'/>
                    </div>
                    <div className='opcao foo sair'>
                        <div onClick={handleSair}>
                            <MdCancel size={25} color='#FF3333'/>
                            <span>SAIR</span>
                        </div>
                        <MdNavigateNext size={15} color='transparent'/>
                    </div>
                </div>
                <div className='itens-menu'>
                    { menuCadastro && (
                        <ItensCadastro/>
                    )}

                    { menuAgenda && (
                        <ItensAgenda/>
                    )}

                    { menuFinanceiro && (
                        <ItensFinanceiro/>
                    )}

                    { menuOpcoes && (
                        <ItensOpcoes/>
                    )}

                    { menuRelatorios && (
                        <ItensRelatorios/>
                    )}

                    { menuMinhaConta && (
                        <ItensMinhaConta/>
                    )}
                </div>

            </div>
        </nav>
    )
}