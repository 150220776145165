import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { ProntuariosProps } from "../../pages/Prontuario";
import { UserProps } from "../../contexts/AuthContext";

type PdfProps = {
    dadosClinica: UserProps
    caminhoLogo?: string 
    dadosPaciente: ProntuariosProps[]
}

export default function GerarProntuarioPDF({ dadosClinica, caminhoLogo, dadosPaciente }: PdfProps){

    const data = new Date().toLocaleString('pt-BR',{timeZone:'America/Sao_Paulo'})

    pdfMake.vfs = pdfFonts.pdfMake.vfs

    pdfMake.createPdf({
        pageSize:'A4',
        pageOrientation:'landscape',
        pageMargins: [15,10,15,15],
        content:[
            {
                text: dadosClinica?.nome,
                alignment:'center',
                fontSize:18,
                style:'header',
                italics:true,
                bold:true
            },
            {
                text: dadosClinica?.endereco+', '+dadosClinica?.numero+' - '+dadosClinica?.bairro,
                alignment:'center',
                fontSize:10,
                italics:true,
                bold:false
            },
            {
                text: dadosClinica?.cidade+'/'+dadosClinica?.uf+' - CEP: '+dadosClinica?.cep,
                alignment:'center',
                fontSize:10,
                italics:true,
                bold:false
            },
            {
                text: 'Telefone: '+dadosClinica?.telefone+' - Celular: '+dadosClinica?.celular,
                alignment:'center',
                fontSize:10,
                italics:true,
                bold:false,
                marginBottom:20
            },
            {
                text: 'Prontuário odontológico',
                marginBottom: 20,
                alignment:'center',
                fontSize:14,
                italics:true,
                bold: true
            },
            {
                text: 'Paciente',
                style: 'header',
                fontSize:11,
                italics:true
            },
            {
                text: dadosPaciente[0]?.NOME,
                fontSize:9,
            },
            {
                text: 'Atendimentos realizados',
                style: 'header',
                alignment:'center',
                marginTop:10,
                marginBottom:15,
                fontSize:11,
                italics: true
            }, 
            {
                style: 'tableExample',
                table: {
                    headerRows: 1,
                    body:[
                        [
                            {text: 'Data atendimento', style: 'tableHeader', fontSize:10}, 
                            {text: 'Tratamento', style: 'tableHeader', fontSize:10}, 
                            {text: 'Tratamento realizado', style: 'tableHeader', fontSize:10}, 
                            {text: 'Observação', style: 'tableHeader', fontSize:10}
                        ],
                        ...dadosPaciente.reverse().map( (dados) => [
                            {text: new Date(dados?.DATA_AGENDA).toLocaleString('pt-BR',{timeZone:'UTC'}), fontSize:8},
                            {text:`${dados?.TRATAMENTO}`, fontSize:8},
                            {text:`${dados?.TRATAMENTO_REALIZADO}`, fontSize:8},
                            {text:`${dados?.OBSERVACOES}`, fontSize:8},
                        ])
                    ]
                }
            },
        ],
        footer: function(currentPage, pageCount){
            return [
                {
                    text:`Solicitado em ${data} - Sistema Minha clínica - pagina ${currentPage.toString()} de ${pageCount}`,
                    alignment:'right',
                    fontSize:7,
                    margin:[0,0,10,5]
                }
            ]
        }

    }).print();

}