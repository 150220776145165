import { useState, useEffect, ChangeEvent, useContext } from 'react'
import { AuthContext } from '../../contexts/AuthContext'
import './index.css'
import { useNavigate } from 'react-router-dom'
import { FaUsers } from 'react-icons/fa'
import Header from "../../components/Header"
import BarraLateral from '../../components/BarraLateral'
import Footer from '../../components/Footer'
import { MdAdd } from 'react-icons/md'
import { FaEdit, FaTrash, FaClipboardList } from 'react-icons/fa'
import foto from '../../assets/semfoto.png'
import { Link } from 'react-router-dom'
import Loading from '../../components/Loading'
import { setupApiClient } from '../../services/api'
import ModalDelete from '../../components/ModalDelete'
import { toast } from 'react-toastify'
import { Input } from '../../components/Input'
import { BsListCheck } from 'react-icons/bs'
import ModalAnamnese from '../../components/ModalAnamnese'
import { CgSmileSad } from 'react-icons/cg'

export interface PacientesProps{
    ID_PACIENTE: string
    NOME: string
    APELIDO?: string
    TELEFONE?: string
    CELULAR?: string
    URLFOTO?: string
    IDADE?: number
    CONSIDERADO?: number
}

export default function ListaPaciente(){
    const { user } = useContext(AuthContext)
    const navigate = useNavigate()

    const [isLoaging, setIsLoading] = useState(false)
    const [pacientes, setPacientes] = useState<PacientesProps[]>([])
    const [openModal, setOpenModal] = useState(false)
    const [deletePaciente, setDeletePaciente] = useState<PacientesProps>()
    const [refresh, setRefresh] = useState(false)
    const [openModalAnamnese, setOpenModalAnamnese] = useState(false)
    const [dadosPaciente, setDadosPaciente] = useState<PacientesProps>()

    useEffect( () => {
        async function getPacientes(){
            try{
                setIsLoading(true)
                const email = localStorage.getItem('@emailMinhaClinica')
                const apiClient = setupApiClient()

                const response  = await apiClient.get('/pacientes',{
                    params:{
                        email: email
                    }
                })

                setPacientes(response.data)
                setIsLoading(false)
            } catch(err) {
                console.log(err)
                setIsLoading(false)
            }
        }

        getPacientes()

    },[refresh])

    function handleEdit(paciente: PacientesProps){
        navigate(`/novopaciente/${paciente?.ID_PACIENTE}`)
    }

    function handleDelete(paciente: PacientesProps){
        setOpenModal(true)
        setDeletePaciente(paciente)
    }

    function handleAnamnese(paciente: PacientesProps){
        setDadosPaciente(paciente)
        setOpenModalAnamnese(true)
    }

    function handleProntuario(paciente: PacientesProps){
       navigate(`/prontuario/${paciente?.ID_PACIENTE}/${paciente?.NOME}`)
    }

    function handleCloseModalFicha(){
        setOpenModalAnamnese(false)
        setRefresh(!refresh)
    }
    
    async function DeletePaciente(id: string){
        try{
            setIsLoading(true)
            const apiClient = setupApiClient()

            const response = await apiClient.delete('/paciente',{
                params:{
                    userid: user?.id,
                    id: id
                }
            })

            if(response.data?.COD == 200){
                toast.success('Paciente excluído!')
                setRefresh(!refresh)
                setIsLoading(false)
                setOpenModal(false)              
            } else {
                setIsLoading(false)
                toast.error(response.data?.COD+' - '+response.data?.MSG)
                return  
            }
        } catch(err){
            console.log(err.response)
            toast.error('Erro excluindo paciente!')
            setIsLoading(false)
            setOpenModal(false)
        }
    }

    function PesquisaPaciente(e: ChangeEvent<HTMLInputElement>) {
        let filtrar: string, texto: string
        const pacientes = document.getElementById('gridview-body').getElementsByClassName('div-linha')

        filtrar = e.target.value.toUpperCase()

        for(let i=0; i < pacientes.length; i++){
            texto = pacientes[i].children[1].children[0].innerHTML

            if(texto.toUpperCase().indexOf(filtrar) > -1){
                pacientes[i].classList.remove('ocultarLinha')
            } else {
                pacientes[i].classList.add('ocultarLinha')  
            }
        }
    }

    function PesquisaApelido(e: ChangeEvent<HTMLInputElement>) {
        let filtrar: string, texto: string
        const pacientes = document.getElementById('gridview-body').getElementsByClassName('div-linha')

        filtrar = e.target.value.toUpperCase()

        for(let i=0; i < pacientes.length; i++){
            texto = pacientes[i].children[2].innerHTML

            if(texto.toUpperCase().indexOf(filtrar) > -1){
                pacientes[i].classList.remove('ocultarLinha')
            } else {
                pacientes[i].classList.add('ocultarLinha')  
            }
        }
    }


    if(isLoaging){
        return(
            <main>
                <Header/>
                <BarraLateral/>            
                <Loading/>
                <Footer/>
            </main>
        )
    }

    return(
        <main>
            <Header/>
            <BarraLateral/>
            <section className='content-listapaciente'>

                <div className='container-titulo-paciente'>
                    <div className='div-titulo-tela'>
                        <div className='textheader'>
                            <FaUsers color='#555' size={25}/>
                            <h3>Você está em pacientes cadastrados</h3>   
                        </div>   
                        <div className='div-btn-novo'>
                            <Link to='/novopaciente'>
                                <MdAdd size={20} color='#FFF'/>
                                Novo
                            </Link>
                        </div>
                    </div>
                </div>  

                <div className='container-filtro-paciente'>
                    <div className='div-campos-input'>
                        <div className='div-filtrar'>
                            <label htmlFor="inputNome">PESQUISAR POR NOME</label>
                            <Input
                                id='inputNome'
                                placeholder='Digite o nome...'
                                onChange={(e) => PesquisaPaciente(e)}
                                type='search'
                            />
                        </div>                    
                        <div className='div-filtrar'>
                        <label htmlFor="inputApelido">PESQUISAR POR APELIDO</label>
                            <Input
                                id='inputApelido'
                                placeholder='Digite o apelido...'
                                onChange={(e) => PesquisaApelido(e)}
                                type='search'
                            />
                        </div>
                    </div>  
                </div>

                {pacientes.length <= 0 ? (
                    <div className='div-sempaciente'>
                        <CgSmileSad size={120} color='#bbb'/>
                        <span>Nenhum paciente cadastrado!</span>    
                    </div>
                ) : (
                    <div className='div-gridview'>

                        <div className='gridview-header'>
                            <span className='c1'>FOTO</span>
                            <span className='c2'>NOME</span>
                            <span className='c3'>APELIDO</span>
                            <span className='c4'>TELEFONE</span>
                            <span className='c5'>CELULAR</span>
                            <span className='c6'>AÇÕES</span>
                        </div>

                        <div className='gridview-body' id='gridview-body'>
                            { pacientes.map( (paciente) => {
                            return(
                                <div key={paciente?.ID_PACIENTE} className={paciente?.CONSIDERADO == 2 ? `div-linha considerado` : `div-linha`}>
                                    <span className='linha c1 foto'>
                                        <img src={paciente?.URLFOTO ? paciente?.URLFOTO : foto} alt='Foto' />
                                    </span>
                                    <div className='container-nome-idade c2'>
                                        <h3 className={paciente?.CONSIDERADO == 2 ? `linha negrito` : `linha`}>{paciente?.NOME}</h3>
                                        <span>{paciente.IDADE > 0 ? `Esse paciente tem ${paciente?.IDADE} anos de idade.` : 'Paciente sem idade cadastrada!'}</span>
                                    </div>  
                                    <span className='linha c3 span-generico'>{paciente?.APELIDO}</span>
                                    <span className='linha c4 span-generico'>{paciente?.TELEFONE}</span>
                                    <span className='linha c5 span-generico'>{paciente?.CELULAR}</span>
                                    <div className="linha c6 div-btn-acao">
                                        <button className='btn-acao' title='EDITAR' onClick={ () => handleEdit(paciente)}>
                                            <FaEdit size={20} color='#4E9438'/>
                                        </button>
                                        <button className='btn-acao' title='EXCLUIR' onClick={ () => handleDelete(paciente)}>
                                            <FaTrash size={20} color='#FF0000'/>
                                        </button>
                                        <button className='btn-acao' title='FICHA ANAMNESE' onClick={ () => handleAnamnese(paciente)}>
                                            <BsListCheck size={20} color='#000'/>
                                        </button>
                                        <button className='btn-acao' title='PRONTUÁRIO MÉDICO' onClick={ () => handleProntuario(paciente)}>
                                            <FaClipboardList size={20} color='#2322AE'/>
                                        </button>
                                    </div>
                                </div>
                            )
                            })}
                        </div>
                    </div>
                )}
            </section>

            { openModal && (
                <ModalDelete
                    data={deletePaciente}
                    onClose={ () => setOpenModal(!openModal)}
                    onRequest={ (id: string) => DeletePaciente(id)}
                />
            )}
            
            { openModalAnamnese && (
                <ModalAnamnese
                    onClose={handleCloseModalFicha}
                    data={dadosPaciente}
                />
            )}

            <Footer/>

        </main>
    )
}

