import './index.css'
import { useNavigate } from 'react-router-dom'

export default function ItensCadastro(){
    const navigate = useNavigate()
    
    return(
        <ul className='ul-itens'>
            <li onClick={() => navigate('/pacientes')}>CADASTRO DE PACIENTES</li>
            <li onClick={() => navigate('/produtos')}>CADASTRO DE MATERIAIS</li>
            <li onClick={() => navigate('/procedimentos')}>CADASTRO DE PROCEDIMENTOS</li>
        </ul>
    )
}