import { useState, useEffect, ChangeEvent } from 'react'
import './procedimentos.css'
import Header from '../../components/Header'
import BarraLateral from '../../components/BarraLateral'
import Footer from '../../components/Footer'
import { Link } from 'react-router-dom'
import { MdAdd } from 'react-icons/md'
import { FaProcedures } from "react-icons/fa"
import { Input } from '../../components/Input'
import { FaEdit, FaTrash } from 'react-icons/fa'
import foto from '../../assets/procedimento.png'
import Loading from '../../components/Loading'
import { setupApiClient } from '../../services/api'
import { useNavigate } from 'react-router-dom'
import ModalDeleteGenerico from '../../components/ModalDeleteGenerico'
import { dataModalProps } from '../../components/ModalDeleteGenerico'
import { CgSmileSad } from 'react-icons/cg'
import { toast } from 'react-toastify'

export interface ProcedimentoProps{
    ID_PROCEDIMENTO: number
    URL_FOTO: string
    DESCRICAO: string
    VALOR: number
}


export default function ListaProcedimentos(){
    const navigate = useNavigate()
    const [procedimentos, setProcedimentos] = useState<ProcedimentoProps[]>([])
    const [refresh, setRefresh] = useState(false)
    const [isLoaging, setIsLoading] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [deleteProcedimento, setDeleteProcedimento] = useState<dataModalProps>()

    useEffect( () => {
        getProcedimentos()
    },[refresh])

    async function getProcedimentos(){
        try{
            setIsLoading(true)
            const userid = sessionStorage.getItem('@userid_clinica')
            const apiClient = setupApiClient()

            const response  = await apiClient.get('/procedimentos',{
                params:{
                    userid: userid
                }
            })

            setProcedimentos(response.data)
            setIsLoading(false)
        } catch(err) {
            console.log(err)
            setIsLoading(false)
        }
    }

    async function DeleteProcedimento(id: number){
        try{
            setIsLoading(true)
            const userid = sessionStorage.getItem('@userid_clinica')
            const apiClient = setupApiClient()

            const response  = await apiClient.delete('/procedimento',{
                params:{
                    userid: userid,
                    idprocedimento: id
                }
            })

            if(response.data?.COD == 200){
                toast.success('Procedimento excluído!')
                setRefresh(!refresh)
                setIsLoading(false)
                setOpenModal(false)              
            } else {
                setIsLoading(false)
                toast.error(response.data?.COD+' - '+response.data?.MSG)
                return  
            }
        } catch(err) {
            console.log(err)
            setIsLoading(false)
        }       
    }

    function handleEdit(procedimento: ProcedimentoProps){
        navigate(`/novoprocedimento/${procedimento?.ID_PROCEDIMENTO}`)
    }

    function handleDelete(procedimento: ProcedimentoProps){
        let data = {
            ID: procedimento?.ID_PROCEDIMENTO,
            DESCRICAO: procedimento?.DESCRICAO
        }
        setDeleteProcedimento(data)
        setOpenModal(true)
    }

    function pesquisaProcedimento(e: ChangeEvent<HTMLInputElement>) {
        let filtrar: string, texto: string
        const prod = document.getElementById('gridview-body-product').getElementsByClassName('div-linha-prod')

        filtrar = e.target.value.toUpperCase()

        for(let i=0; i < prod.length; i++){
            texto = prod[i].children[1].innerHTML

            if(texto.toUpperCase().indexOf(filtrar) > -1){
                prod[i].classList.remove('ocultarLinha')
            } else {
                prod[i].classList.add('ocultarLinha')  
            }
        }
    }

    if(isLoaging){
        return(
            <main>
                <Header/>
                <BarraLateral/>            
                <Loading/>
                <Footer/>
            </main>
        )
    }

    return(
        <main>
            <Header/>
            <BarraLateral/>
            <section className='content-listapaciente'>
                <div className='container-titulo-paciente'>
                    <div className='div-titulo-tela'>
                        <div className='textheader'>
                            <FaProcedures    color='#555' size={25}/>
                            <h3>Você está em procedimentos cadastrados</h3>   
                        </div>   
                        <div className='div-btn-novo'>
                            <Link to='/novoprocedimento'>
                                <MdAdd size={20} color='#FFF'/>
                                Novo
                            </Link>
                        </div>
                    </div>
                </div>
                
                <div className='container-filtro-paciente'>
                    <div className='div-campos-input'>
                        <div className='div-filtrar'>
                            <label htmlFor="inputPesquisa">PESQUISA POR DESCRIÇÃO</label>
                            <Input
                                id='inputPesquisa'
                                placeholder='Digite a descrição...'
                                onChange={(e) => pesquisaProcedimento(e)}
                                type='search'
                            />
                        </div>
                    </div>                    
                </div>

                { procedimentos.length <= 0 ? (
                        <div className='div-sempaciente'>
                            <CgSmileSad size={120} color='#bbb'/>
                            <span>Nenhum procedimento cadastrado!</span>    
                        </div>
                    ) : (
                        <div className='div-gridview'>
                            <div className='gridview-header-prod'>
                                <span className='pd1'>FOTO</span>
                                <span className='pd2'>DESCRIÇÃO</span>
                                <span className='pd3'>VALOR</span>
                                <span className='pd4'>AÇÕES</span>
                            </div>

                            <div className='gridview-body-product' id='gridview-body-product'>
                                { procedimentos.map( (procedimento) => { 
                                    return(
                                        <div key={procedimento?.ID_PROCEDIMENTO} className='div-linha-prod'>
                                            <span className='pd1'>
                                                <img src={procedimento?.URL_FOTO ? procedimento?.URL_FOTO : foto} alt='Foto' />
                                            </span>  
                                            <span className='pd2 span-generico'>{procedimento?.DESCRICAO}</span>
                                            <span className='pd3 span-generico right'>{procedimento?.VALOR.toLocaleString('pt-BR',{style:'currency', currency:'BRL'})}</span>
                                            <div className="pd4 div-btn-acao">
                                                <button className='btn-acao' title='EDITAR' onClick={ () => handleEdit(procedimento)}>
                                                    <FaEdit size={20} color='#4E9438'/>
                                                </button>
                                                <button className='btn-acao' title='EXCLUIR' onClick={ () => handleDelete(procedimento)}>
                                                    <FaTrash size={20} color='#FF0000'/>
                                                </button>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div> 
                        </div>
                    )}
 
            </section>

            { openModal && (
                <ModalDeleteGenerico
                    data={deleteProcedimento}
                    title='Procedimento'
                    onClose={ () => setOpenModal(!openModal)}
                    onRequest={ (id: number) => DeleteProcedimento(id)}
                />
            )}

            <Footer/>
        </main>
    )

  
}