import { useContext, useState } from 'react'
import './index.css'
import { AuthContext } from '../../contexts/AuthContext'

export default function Footer(){
    const [year, setYear] = useState(new Date().getFullYear())
    const { versao, dtUpdate } = useContext(AuthContext)

    return(
        <footer className='boxfooter'>
            <div className='footer'>
                <span>COPYRIGHT © {year} WGA Informática® - Sistema Minha clínica - Versão <strong>{versao}</strong> de {dtUpdate}</span>
            </div>
        </footer>
    )
}