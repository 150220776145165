import './index.css'
import { useState, FormEvent } from 'react'
import { Container } from '../Container'
import { setupApiClient } from '../../../services/api'
import { toast } from 'react-toastify'
import { emailTest } from '../../../utils/utils'
import { BiBorderRadius } from 'react-icons/bi'

export default function Contato(){
    const [nome, setNome] = useState('')
    const [email, setEmail] = useState('')
    const [fone, setFone] = useState('')
    const [cidade, setCidade] = useState('')
    const [mensagem, setMensagem] = useState('')

    async function handleEnviar(e: FormEvent){
        e.preventDefault()

        if(!emailTest.test(email)){
            toast.warn('Digite um e-mail válido!')
            return
        }

        let obj = {
            nome: nome,
            email: email,
            fone: fone,
            cidade: cidade,
            mensagem: mensagem
        }

        const apiClient = setupApiClient()
        const response = await apiClient.post('/contato',obj,{
            headers:{
                Authorization: 'Basic Auth'
            },
            auth:{
                username: process.env.REACT_APP_USER,
                password: process.env.REACT_APP_PASS
            }
        })
        
        if(response.status == 200){
            toast.success('Contato enviado!')
        } else {
            toast.error('Erro enviando contato!')
        }

        setNome('')
        setEmail('')
        setFone('')
        setCidade('')
        setMensagem('')
    }

    return(
        <>
            <section className='contato-container' id='contato'>
                <Container>
                    <div className='contato-texto-container'>
                        <h1>Entre em contato e tire suas dúvidas</h1>
                        <h3>Tem alguma dúvida, sugestão ou feedback? Ficaremos felizes em ajudar! Entre em contato conosco e nossa equipe estará pronta para lhe atender.</h3>
                    </div>
                </Container>
            </section>
            <section>
                <Container>
                    <div className='contato-form'>
                        <form onSubmit={handleEnviar}>
                            <div>
                                <label htmlFor="nome">Informe seu nome *</label>
                                <input 
                                    type="text" 
                                    id='nome'
                                    value={nome}
                                    onChange={(e) => setNome(e.target.value)}
                                    required
                                    placeholder='Informe seu nome...'
                                />
                            </div>
                            <div>
                                <label htmlFor="email">Informe seu e-mail *</label>
                                <input 
                                    type="email" 
                                    id='email'
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                    placeholder='Informe seu e-mail...'
                                />
                            </div>
                            <div>
                                <label htmlFor="fone">Informe seu telefone/WhatsApp</label>
                                <input 
                                    type="text"
                                    id='fone'
                                    value={fone}
                                    onChange={(e) => setFone(e.target.value)}
                                    placeholder='Informe seu telefone ou WhatsApp...'
                                />
                            </div>
                            <div>
                                <label htmlFor="cidade">Informe sua cidade e estado *</label>
                                <input 
                                    type="text" 
                                    id='cidade'
                                    value={cidade}
                                    onChange={(e) => setCidade(e.target.value)}
                                    required
                                    placeholder='Informe sua cidade/estado...'
                                />
                            </div>
                            <div className='form-textarea'>
                                <label htmlFor="mensagem">Informe sua mensagem *</label>
                                <textarea
                                    id='mensagem'
                                    value={mensagem}
                                    onChange={(e) => setMensagem(e.target.value)}
                                    required
                                    placeholder='Informe sua mensagem...'
                                />
                            </div>
                            <div>
                                <button type='submit'>
                                    ENVIAR
                                </button>
                                <span>*CAMPOS OBRIGATÓRIOS</span>
                            </div>
                        </form>
                        <div className='contato-mapa'>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3686.595381794283!2d-47.45818392381851!3d-22.48183442282369!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94c7d60713aeaaab%3A0xf2a80a14ff3e121b!2sWGA%20Inform%C3%A1tica!5e0!3m2!1spt-BR!2sbr!4v1740070201709!5m2!1spt-BR!2sbr" width="100%" height="100%" style={{borderRadius:'5px', border:0}} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                            <span>Localização da nossa empresa de desenvolvimento.</span>
                        </div>
                    </div>
                </Container>
            </section>
        </>
    )
}