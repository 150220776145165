import { useNavigate } from 'react-router-dom'

export default function ItensMinhaConta(){
    const navigate = useNavigate()

    return(
        <ul className='ul-itens'>
            <li onClick={() => navigate('/account')}>MEU PERFIL</li>
            <li>ALTERAR SENHA</li>
        </ul>
    )
}