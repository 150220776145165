import { useState, ChangeEvent, useEffect } from 'react'
import './index.css'
import { FaWindowClose } from 'react-icons/fa'
import { BsFillCheckCircleFill } from 'react-icons/bs'
import { TextArea, Input } from '../Input'
import { toast } from 'react-toastify'
import { BiLoaderCircle } from 'react-icons/bi'
import { setupApiClient } from '../../services/api'
import { converterDataParaTexto, isFloat, isNumber } from '../../utils/utils'
import { MdAdd } from "react-icons/md"
import { FaTrash } from 'react-icons/fa'
import { ProcedimentoProps } from '../../pages/ListaProcedimentos'
import { ProdutosProps } from '../../pages/ListaProdutos'
import ModalDeleteGenerico, { dataModalProps } from '../ModalDeleteGenerico'
import { arrAgendaProps } from '../../pages/Agenda'

interface ModalFinalizarProps{
    data: arrAgendaProps
    onClose: () => void
    onRequestAtualizar?: (dia: string) => Promise<void>
}

interface ItensAdicionadoProps{
    ID_SEQUENCIA: number
    UUID_AGENDA: string
    ID_EMPRESA: number
    ID_PACIENTE: number
    ID_ITEM_ADICIONADO: number
    DESCRICAO: string
    QUANTIDADE: number
    VALOR: number
    TOTAL: number
    TIPOITEM: number
}

export default function ModalFinalizar({ data, onClose, onRequestAtualizar }: ModalFinalizarProps){

    let newData = data?.DATA_AGENDA.split('-')
    const diaRequest = newData[2].substring(0,2)
    const [formaPagamento, setFormaPagamento] = useState(['DINHEIRO','CHEQUE','PIX','CARTÃO CRÉDITO','CARTÃO DÉBITO','TRANSFERÊNCIA','CARTEIRA'])
    const [formaPagtoSelecionada, setFormaPagtoSelecionada] = useState(0)
    const [valorCobrado, setValorCobrado] = useState('')
    const [parcelas, setParcelas] = useState(['1x','2x','3x','4x','5x','6x','7x','8x','9x','10x','11x','12x'])
    const [parcelaSelecionada, setParcelaSelecionada] = useState(0)
    const [dataVencimento, setDataVencimento] = useState(newData[0]+'-'+newData[1]+'-'+newData[2].substring(0,2))
    const [obsPagamento, setObsPagamento] = useState('')
    const [tratamentoRealizado, setTratamentoRealizado] = useState('')
    const [loadingFinalizar, setLoadingFinalizar] = useState(false)
    const [openModalDelete, setOpenModalDelete] = useState(false)
    const [deleteProduto, setDeleteProduto] = useState<dataModalProps>()

    const [tipoTratamento, setTipoTratamento] = useState('T')
    const [labelOrcamento, setLabelOrcamento] = useState('Tratamento realizado')
    const [procedimentos, setProcedimentos] = useState<ProcedimentoProps[]>([])
    const [procSelecionado, setProcSelecionado] = useState(0)
    const [quantProcedimento, setQuantProcedimento] = useState('1')
    const [valorProcedimento, setValorProcedimento] = useState('')

    const [produtos, setProdutos] = useState<ProdutosProps[]>([])
    const [prodSelecionado, setProdSelecionado] = useState(0)
    const [quantItemAdicional, setQuantItemAdicional] = useState('1')
    const [valorAdicional, setValorAdicional] = useState('')
    const [refreshItens, setRefreshItens] = useState(false)
    const [totalItens, setTotalItens] = useState(0)
    const [itensAdicionados, setItensAdicionados] = useState<ItensAdicionadoProps[]>([])

    useEffect( () => {
        getProcedimentos()
        getProdutos()
    },[])
    
    useEffect(() => {
        getItensAtendimento()
    },[refreshItens])

    async function getProcedimentos(){
        try{
            setLoadingFinalizar(true)
            const userid = sessionStorage.getItem('@userid_clinica')
            const apiClient = setupApiClient()

            const response  = await apiClient.get('/procedimentos',{
                params:{
                    userid: userid
                }
            })

            setProcedimentos(response.data)
            setValorProcedimento(response.data[0]?.VALOR.toFixed(2))
            setLoadingFinalizar(false)
        } catch(err) {
            console.log(err)
            setLoadingFinalizar(false)
        }
    }

    async function getProdutos(){
        try{
            setLoadingFinalizar(true)
            const userid = sessionStorage.getItem('@userid_clinica')
            const apiClient = setupApiClient()

            const response  = await apiClient.get('/produtos',{
                params:{
                    userid: userid
                }
            })

            setProdutos(response.data)
            setValorAdicional(response.data[0]?.VALOR.toFixed(2))
            setLoadingFinalizar(false)
        } catch(err) {
            console.log(err)
            setLoadingFinalizar(false)
        }
    }

    async function getItensAtendimento() {
        try{
            setLoadingFinalizar(true)
            const userid = sessionStorage.getItem('@userid_clinica')
            const apiClient = setupApiClient()

            const response  = await apiClient.get('/itensadicionadoatendimento',{
                params:{
                    idempresa: userid,
                    idatendimento: data?.UUID,
                    idpaciente: data?.ID_PACIENTE
                }
            })

            if(response.data.length > 0){
                setTotalItens(0)
                let total = 0
                response.data.map((item: ItensAdicionadoProps) => {
                    total += item?.TOTAL
                })

                setTotalItens(total)
            } else {
                setTotalItens(0)
            }

            setItensAdicionados(response.data)
            setLoadingFinalizar(false)
        } catch(err) {
            console.log(err)
            setLoadingFinalizar(false)
        }        
    }

    function handleFormaSelecionada(e: any){
        setFormaPagtoSelecionada(e.target.value)
    }

    function handleParcelaSelecionada(e:any){
        setParcelaSelecionada(e.target.value)
    }

    function handleTipo(e: any){
        setTipoTratamento(e.target.value)
        e.target.value == 'O' ? setLabelOrcamento('Tratamento orçado') : setLabelOrcamento('Tratamento realizado')
    }

    function handleProcSelecionado(e: any){
        setProcSelecionado(e.target.value)
        setValorProcedimento(String(procedimentos[e.target.value]?.VALOR.toFixed(2)))
        setQuantProcedimento('1')
    }

    function handleProdSelecionado(e: any){
        setProdSelecionado(e.target.value)
        setValorAdicional(String(produtos[e.target.value]?.VALOR.toFixed(2)))
        setQuantItemAdicional('1')
    }

    async function handleAddItemAtendimento(itemProcedimento: boolean){
        
        const userid = sessionStorage.getItem('@userid_clinica')

        if(itemProcedimento){
            const idx = itensAdicionados.findIndex(item => item?.DESCRICAO == procedimentos[procSelecionado]?.DESCRICAO)
            if(idx > -1){
                toast.warn('Procedimento já consta na lista!')
                return
            }

            try{
                setLoadingFinalizar(true)
                
                let obj = {
                    idatendimento: data?.UUID,
                    idempresa: userid,
                    idpaciente: data?.ID_PACIENTE,
                    iditem: procedimentos[procSelecionado]?.ID_PROCEDIMENTO,
                    descricao: procedimentos[procSelecionado]?.DESCRICAO,
                    quantidade: quantProcedimento,
                    valor: parseFloat(valorProcedimento),
                    total: parseFloat(quantProcedimento) * parseFloat(valorProcedimento),
                    tipo: 1
                }
                
                const apiClient = setupApiClient()
    
                const resp = await apiClient.post('/additematendimento', obj)
    
                if(resp.data?.COD == 201){
                    setRefreshItens(!refreshItens)
                    toast.success('Procedimento adicionado! :)')  
                } else {
                    toast.error(resp.data?.COD+'-'+resp.data?.MSG)
                }
    
                setLoadingFinalizar(false)
    
            } catch(err){
                toast.error('Erro adicionando item! :(')
                console.log(err.data)
                setLoadingFinalizar(false)
            }

        } else {

            const idx = itensAdicionados.findIndex(item => item?.DESCRICAO == produtos[prodSelecionado]?.DESCRICAO)
            if(idx > -1){
                toast.warn('Item adiciional já consta na lista!')
                return
            }

            try{
                setLoadingFinalizar(true)
                
                let obj = {
                    idatendimento: data?.UUID,
                    idempresa: userid,
                    idpaciente: data?.ID_PACIENTE,
                    iditem: produtos[prodSelecionado]?.ID_PRODUTO,
                    descricao: produtos[prodSelecionado]?.DESCRICAO,
                    quantidade: quantItemAdicional,
                    valor: parseFloat(valorAdicional),
                    total: parseFloat(quantItemAdicional) * parseFloat(valorAdicional),
                    tipo: 2
                }
                
                const apiClient = setupApiClient()
    
                const resp = await apiClient.post('/additematendimento', obj)
    
                if(resp.data?.COD == 201){
                    setRefreshItens(!refreshItens)
                    toast.success('Item adicional adicionado! :)')  
                } else {
                    toast.error(resp.data?.COD+'-'+resp.data?.MSG)
                }
    
                setLoadingFinalizar(false)
    
            } catch(err){
                toast.error('Erro adicionando item! :(')
                console.log(err.data)
                setLoadingFinalizar(false)
            }
        }
    }

    function handleExcluirItem(item:ItensAdicionadoProps){
        let obj = {
            ID: item?.ID_SEQUENCIA,
            DESCRICAO: item?.DESCRICAO
        }

        setDeleteProduto(obj)
        setOpenModalDelete(true)
    }

    async function deleteItemAtendimento(idsequencia: number){
        try{
            setLoadingFinalizar(true)
            const userid = sessionStorage.getItem('@userid_clinica')
            const apiClient = setupApiClient()

            const response  = await apiClient.delete('/itensadicionadoatendimento',{
                params:{
                    idempresa: userid,
                    idatendimento: data?.UUID,
                    idsequencia: idsequencia
                }
            })

            if(response.data?.COD == 200){
                setRefreshItens(!refreshItens)
                setOpenModalDelete(false)
                toast.success('Item removido')
            } else {
                toast.error(response.data?.COD+' - '+response.data?.MSG)
            }

            setLoadingFinalizar(false)
        } catch(err) {
            console.log(err)
            setLoadingFinalizar(false)
        }
    }

    async function handleFinalizarAtendimento(){
        if(tratamentoRealizado == ''){
            toast.warn('Informe o tratamento realizado!')
            return
        }

        if(itensAdicionados.length <= 0){
            toast.warn('Insira o procedimento realizado!')
            return
        }

        if(valorCobrado == ''){
            toast.warn('Informe o valor cobrado!')
            return
        }

        if(!isNumber(valorCobrado) && !isFloat(valorCobrado)){
            toast.warn('Valor cobrado inválido!')
            return            
        }

        try{
            setLoadingFinalizar(true)
            const userid = sessionStorage.getItem('@userid_clinica')

            let obj = {
                id: data?.UUID,
                idempresa: userid, 
                idpaciente: data?.ID_PACIENTE,
                nomepaciente: data?.NOME.toUpperCase(),
                tipotratamento: tipoTratamento,
                tratamento: tratamentoRealizado, 
                formapagto: formaPagamento[formaPagtoSelecionada],
                valorcobrado: valorCobrado.toString().replace(',','.'),
                totalmaterial: totalItens.toString(),
                datavcto: converterDataParaTexto(dataVencimento),
                obspagto: obsPagamento == '' ? '' : obsPagamento.toUpperCase(),
                parcelas: Number(parcelaSelecionada) + 1
            }

            const apiClient = setupApiClient()

            const resp = await apiClient.put('/agendafinalizaratendimento', obj)

            if(resp.data?.COD == 200){
                toast.success('Atendimento finalizado! :)') 
                setLoadingFinalizar(false)
                onRequestAtualizar(diaRequest)
                onClose()
            } else {
                toast.error(resp.data?.COD+' - '+resp.data?.MSG)
                return
            }

        } catch(err){
            toast.error('Erro finalizando atendimento! :(')
            console.log(err.data)
            setLoadingFinalizar(false)
        }
    }

    return(
        <section className='container-finalizar'>
            <div className='content-finalizar'>
                <div className='grupo-btn'>
                    <button id='fechar-modal' onClick={ () => onClose()}>
                        <FaWindowClose size={20} color='#FFF'/>
                    </button>

                    <select name="" id="select-forma" value={tipoTratamento} onChange={handleTipo}>
                        <option value="O">ORÇAMENTO</option>
                        <option value="T">TRATAMENTO</option>
                    </select>
                
                    <button id='finalizar-atend' onClick={handleFinalizarAtendimento}>
                        <BsFillCheckCircleFill size={20} color='#FFF'/>
                        FINALIZAR
                    </button>
                    
                    { loadingFinalizar && (
                        <div className='loading-finalizar'>
                            <BiLoaderCircle color='#000' size={25}/>
                            <span>Finalizando atendimento...</span>
                        </div>
                    )}
                </div>

                <div className='boxcontent-dados'>
                    <div className='dados-paciente'>
                        <small>Paciente</small>
                        <span>{data?.NOME}</span>
                    </div>

                    <div className='trat-realizado'>
                        <label htmlFor='txt-realizado'>{labelOrcamento}</label>
                        <TextArea
                            id='txt-realizado'
                            placeholder='Oque foi realizado?'
                            value={tratamentoRealizado}
                            onChange={ (e) => setTratamentoRealizado(e.target.value)}
                        />
                    </div>
                </div>

                <div className='container-itens'>
                    <label>Procedimentos e produtos usados no tratamento</label>       
                    
                    <div className='div-itens up'>
                        <div className='box-select'>
                            <div className='div-select'>
                                <label htmlFor='txt-realizado'>PROCEDIMENTOS</label>
                                <select value={procSelecionado} id="select-forma" onChange={handleProcSelecionado}>
                                    { procedimentos.length > 0 && procedimentos.map( (proc, index) => {
                                        return(
                                            <option key={proc?.ID_PROCEDIMENTO} value={index}>
                                                {proc?.DESCRICAO}
                                            </option>
                                        )
                                    })} 
                                </select>
                            </div>  
                        </div>

                        <div className='box-input'>
                            <div className='div-camposfin'>
                                <label htmlFor='txt-realizado'>QUANTIDADE</label>
                                <Input
                                    id=''
                                    type='text'
                                    placeholder='0,00'
                                    value={quantProcedimento}
                                    onChange={(e) => setQuantProcedimento(e.target.value)}
                                />
                            </div>
                            
                            <div className='div-camposfin'>
                                <label htmlFor='txt-realizado'>VALOR</label>
                                <Input
                                    id=''
                                    type='text'
                                    placeholder='R$ 0,00'
                                    value={valorProcedimento}
                                    onChange={(e) => setValorProcedimento(e.target.value)}
                                />
                            </div>

                            <div className='div-camposfin'>
                                <button onClick={() => handleAddItemAtendimento(true)}>
                                    <MdAdd size={20} color='#FFF'/>ADD 
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className='div-itens down'>
                        <div className='box-select'>
                            <div className='div-select'>
                                <label htmlFor='txt-realizado'>ITENS ADICIONAIS</label>
                                <select value={prodSelecionado} id="select-forma" onChange={handleProdSelecionado}>
                                    { produtos.length > 0 && produtos.map( (prod, index) => {
                                        return(
                                            <option key={prod?.ID_PRODUTO} value={index}>
                                                {prod?.DESCRICAO}
                                            </option>
                                        )
                                    })} 
                                </select>
                            </div>  
                        </div>

                        <div className='box-input'>
                            <div className='div-camposfin'>
                                <label htmlFor='txt-realizado'>QUANTIDADE</label>
                                <Input
                                    id=''
                                    type='text'
                                    placeholder='0,00' 
                                    value={quantItemAdicional}
                                    onChange={(e) => setQuantItemAdicional(e.target.value)}
                                />
                            </div>
                            
                            <div className='div-camposfin'>
                                <label htmlFor='txt-realizado'>VALOR</label>
                                <Input
                                    id=''
                                    type='text'
                                    placeholder='R$ 0,00'
                                    value={valorAdicional}
                                    onChange={(e) => setValorAdicional(e.target.value)}
                                />
                            </div>

                            <div className='div-camposfin'>
                                <button onClick={() => handleAddItemAtendimento(false)}>
                                    <MdAdd size={20} color='#FFF'/>ADD 
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className='div-griditens'>
                        <div className='grid-header-itens'>
                            <span className='i1'>DESCRIÇÃO</span>
                            <span className='i2'>QUANT.</span>
                            <span className='i2'>VALOR</span>
                            <span className='i2'>TOTAL</span>
                            <span className='i3'>AÇÕES</span>
                        </div>    

                        <div className='grid-body-container'>
                            { itensAdicionados.length > 0 && itensAdicionados.map( (item, index) => {
                                return(
                                    <div key={index} className='grid-body-itens'>
                                        <span className='i1'>{item?.DESCRICAO}</span>
                                        <span className='i2 align-right'>{item?.QUANTIDADE.toFixed(2).replace('.',',')}</span>
                                        <span className='i2 align-right'>{item?.VALOR.toLocaleString('pt-BR',{style:'currency', currency:'BRL'})}</span>
                                        <span className='i2 align-right'>{item?.TOTAL.toLocaleString('pt-BR',{style:'currency', currency:'BRL'})}</span>
                                        <span className='i3 center'>
                                            <button className='btn-acao' title='EXCLUIR' onClick={() => handleExcluirItem(item)}>
                                                <FaTrash size={16} color='#FF0000'/>
                                            </button>
                                        </span>
                                    </div>
                                )
                            })} 
                        </div>
                    </div>    

                    <div className='container-valores'>
                        <div className='valor-cobrado'>
                            <div className='dv-valor'>
                                <label htmlFor='vl-cobrado'>Valor cobrado</label>
                                <Input
                                    id='vl-cobrado'
                                    type='text'
                                    placeholder='R$ 0,00'
                                    value={valorCobrado}
                                    onChange={(e) => setValorCobrado(e.target.value)}
                                />
                            </div>

                            <div className='dv-formapagto'>
                                <label htmlFor='form-pag'>Forma de pagamento</label>
                                <select id='form-pag' value={formaPagtoSelecionada} onChange={handleFormaSelecionada}>
                                    { formaPagamento.map( (forma, index) => {
                                        return(
                                            <option key={index} value={index}>{forma}</option>
                                        )
                                    })}
                                </select>
                            </div>

                            <div className='dv-formapagto'>
                                <label htmlFor='parcelas'>Parcelado em?</label>
                                <select id='parcelas' value={parcelaSelecionada} onChange={handleParcelaSelecionada}>
                                    { parcelas.map( (parcela, index) => {
                                        return(
                                            <option key={index} value={index}>{parcela}</option>
                                        )
                                    })}
                                </select>
                            </div>

                            <div className='dv-formapagto'>
                                <label htmlFor='dt-vencto'>Vencto a partir de</label>
                                <Input
                                    id='dt-vencto'
                                    type='date'
                                    value={dataVencimento}
                                    onChange={(e) => setDataVencimento(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>

                    <div className='obs-pagamento'>
                        <label htmlFor="obs-pag">Observação sobre o pagamento</label>
                        <TextArea
                            id='obs-pag'
                            placeholder='Observação referente ao pagamento...'
                            value={obsPagamento}
                            onChange={ (e) => setObsPagamento(e.target.value)}
                        />
                    </div>

                    <div className='div-totais'>
                        <span>TOTAL MATERIAL: {totalItens.toLocaleString('pt-BR',{style:'currency', currency:'BRL'})}</span>
                        <span>TOTAL DO ATENDIMENTO:  {valorCobrado == '' ? 'R$ 0,00' : parseFloat(valorCobrado).toLocaleString('pt-BR',{style:'currency', currency:'BRL'})}</span>
                    </div>
                </div>    
            </div>

            { openModalDelete && (
                <ModalDeleteGenerico
                    data={deleteProduto}
                    title='Item'
                    onClose={ () => setOpenModalDelete(!openModalDelete)}
                    onRequest={ (id: number) => deleteItemAtendimento(id)}
                />
            )}
        </section>
    )

}