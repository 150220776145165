import { useState, useEffect } from 'react'
import './index.css'
import { VscCalendar } from 'react-icons/vsc'
import { IoChevronBack } from 'react-icons/io5'
import { AiOutlineFieldTime, AiOutlineUsergroupAdd } from 'react-icons/ai'
import { FaHourglassStart } from 'react-icons/fa'
import { CgSmileSad } from 'react-icons/cg'
import { BiTimer } from 'react-icons/bi'
import { Button } from '../../components/Button'
import { BiChevronDown } from 'react-icons/bi'
import ModalAgenda from '../../components/ModalAgenda'
import Loading from '../../components/Loading'
import Header from '../../components/Header'
import BarraLateral from '../../components/BarraLateral'
import Footer from '../../components/Footer'
import { toast } from 'react-toastify'
import { setupApiClient } from '../../services/api'
import { MdCancel, MdModeEditOutline } from 'react-icons/md'
import { BsFillCheckCircleFill } from 'react-icons/bs'
import ModalFinalizar from '../../components/ModalFinalizar'
import { AtendimentoProps } from '../../components/ModalAgenda'
import ModalDeleteGenerico, { dataModalProps } from '../../components/ModalDeleteGenerico'
import { formataDataHoraToString } from '../../utils/utils'

export interface arrAgendaProps{
    UUID: string
    ID_EMPRESA: string
    ID_PACIENTE: string
    DATA_AGENDA: string
    TRATAMENTO: string
    OBSERVACOES: string
    TRATAMENTO_REALIZADO: string
    FINALIZADO: string
    NOME: string
    CONSIDERADO?: number
    TIPOATENDIMENTO: number
}

type RequestDiasAtendimentoProps = {
    DATA_AGENDA: string
}

export default function Agenda(){
    const [isLoaging, setIsLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const [modalFinalizar, setModalFinalizar] = useState(false)
    const [isloadingHorario, setIsLoadingHorario] = useState(false)
    const [diaAgenda, setDiaAgenda] = useState('')
    const [arrAgenda, setArrAgenda] = useState<arrAgendaProps[]>([])
    const [atendimento, setAtendimento] = useState<arrAgendaProps>()
    const [openModalAgenda, setOpenModalAgenda] = useState(false)
    const [dadosconsulta, setDadosConsulta] = useState<AtendimentoProps>()
    const [modalDelete, setModalDelete] = useState(false)
    const [deleteAtendimento, setDeleteAtendimento] = useState<dataModalProps>()
    const [diaAgendarNovo, setDiaAgendarNovo] = useState('')
    const [diasAgendamento, setDiasAgendamento] = useState([])
    const meses = ['JANEIRO', 'FEVEREIRO', 'MARÇO', 'ABRIL', 'MAIO', 'JUNHO', 'JULHO', 'AGOSTO', 'SETEMBRO', 'OUTUBRO', 'NOVEMBRO', 'DEZEMBRO']
    const semana = ['DOM', 'SEG', 'TER', 'QUA', 'QUI', 'SEX', 'SAB']
    const diaAtual = new Date()
    diaAtual.setDate(1) 

    const [dataHora, setDataHora] = useState(new Date(diaAtual))
    let mes = dataHora.getMonth()
    let ano = dataHora.getFullYear()

    useEffect( () => {
        pintaMesAtual()
        imprimeMesCorrente()
        getDiasAtendimento(dataHora.getDate()+'/'+(dataHora.getMonth()+1)+'/'+dataHora.getFullYear())
    },[dataHora, refresh])

    useEffect( () => {
        const span = document.querySelectorAll('tbody tr td span')
        const tdHoje = document.querySelector('.hoje')

        if(tdHoje){
            tdHoje.classList.remove('class','atendimento')
        }

        span.forEach( (sp) => {
            if(diasAgendamento.find( (dia) => dia == sp.innerHTML)){
                sp.classList.add('atendimento')
            } else {
                sp.classList.remove('class','atendimento')
            }
        })

    },[diasAgendamento])

    function handleFechaModal(){
        setRefresh(!refresh)
        setOpenModalAgenda(false)
        handleDia(diaAgenda.substring(0,2))
    }

    function pintaMesAtual(){
        for(let i = 0; i <= 11; i++){
            let lis = document.getElementById(i.toString())
            lis.classList.remove('class','mesatual')
        }

        let li = document.getElementById(mes.toString())
        li.setAttribute('class','mesatual')
    }

    function pegaUltimoDiaMes(data: Date){
        let dataCopia = new Date(data)
        dataCopia.setMonth(data.getMonth() +1)
        dataCopia.setDate(1)
        dataCopia.setDate(dataCopia.getDate() -1)
        return dataCopia.getDate()
    }

    function handleVoltaMes(){
        let dataCopia = new Date(dataHora)
        dataCopia.setMonth(dataHora.getMonth() - 1)
        dataCopia.setDate(1) 
        setDataHora(dataCopia)       
    }

    function handleAvancaMes(){
        let dataCopia = new Date(dataHora)
        dataCopia.setMonth(dataHora.getMonth() + 1)
        dataCopia.setDate(1) 
        setDataHora(dataCopia)   
    }

    async function getAgenda(dia: string){
        try{
            setIsLoadingHorario(true)
            const id = sessionStorage.getItem('@userid_clinica')
            const apiClient = setupApiClient()
            
            const response = await apiClient.get('/agenda',{
                params:{
                    userid: id,
                    data: dia
                }
            })

            if(response.status == 200){
                setArrAgenda(response.data)
            } else {
                setArrAgenda([])
                toast.warn('Erro buscando dia da agenda!')
            }

            setIsLoadingHorario(false)

        } catch(err){
            console.log(err.data)
            setIsLoadingHorario(false)
            toast.error('Erro buscando informações da agenda!')
        }
    }

    async function getDiasAtendimento(data: string){
        try{
            const id = sessionStorage.getItem('@userid_clinica')
            const apiClient = setupApiClient()

            const response = await apiClient.get('/dias-atendimento',{
                params:{
                    userid: id,
                    data: data
                }
            })

            setDiasAgendamento([])    
            let dias = []
            if(response.data.length > 0){
                response.data.map( (dia: RequestDiasAtendimentoProps) => {
                    dias.push(
                        Number(dia.DATA_AGENDA.substring(8,10))
                    )
                })
            }

            setDiasAgendamento(dias)

        } catch(err){
            console.log(err.data)
            toast.error('Erro buscando os dias de atendimento!')
        }
    }

    async function handleDia(dia: string){
        let mesCorrent = mes + 1
        let mesString = ''

        if(mesCorrent < 10){
            mesString = '0'+mesCorrent   
        } else {
            mesString = mesCorrent.toString()
        }

        if(Number(dia) < 10 && dia.length < 2){
            dia = '0'+dia
        }

        // if(new Date(ano,Number(mesString),Number(dia)) < new Date()){
        //     return
        // }

        setDiaAgenda(dia+'/'+mesString+'/'+ano)
        getAgenda(dia+'/'+mesString+'/'+ano)
        setRefresh(!refresh)
    }

    function handleOpenModal(){
        setDadosConsulta(null)
        const convertDate = diaAgenda.split('/')
        setDiaAgendarNovo(convertDate[2]+'-'+convertDate[1]+'-'+convertDate[0])
        setOpenModalAgenda(true)
    }

    function handleBuscaMes(mes: number){
        let dataCopia = new Date(dataHora)
        dataCopia.setMonth(mes)
        dataCopia.setDate(1) 
        setDataHora(dataCopia)  
    }

    function handleFinalizar(atendimento: arrAgendaProps){
        setAtendimento(atendimento)
        setModalFinalizar(true)
    }

    async function handleEditar(id: string){
        try{
            const userid = sessionStorage.getItem('@userid_clinica')
            const apiClient = setupApiClient()
    
            const response = await apiClient.get('/atendimento',{
                params:{
                    userid: userid,
                    uuid: id
                }
            })
    
            setDadosConsulta(response.data)
            setOpenModalAgenda(true)
        } catch(err){
            console.log(err.data)
            toast.error('Erro buscando dados do atendimento!')
        }
    }

    function handleCancelarAtendimento(atendimento: arrAgendaProps){
        setDeleteAtendimento({
            ID: atendimento?.UUID,
            DESCRICAO: atendimento?.NOME
        })

        setModalDelete(true)
    }

    async function requestcancelarAtendimento(id: number | string){
        try{
            const userid = sessionStorage.getItem('@userid_clinica')
            const apiClient = setupApiClient()

            const response = await apiClient.delete('/atendimento',{
                params:{
                    userid: userid,
                    uuid: id.toString()
                }
            })

            if(response.data?.COD == 200){
                handleDia(diaAgenda.substring(0,2))
                setModalDelete(false)
                toast.success('Atendimento cancelado!')             
            } else {
                toast.error(response.data?.COD+' - '+response.data?.MSG)
                return  
            }
        } catch(err){
            console.log(err.data)
            toast.error('Erro cancelando atendimento!')
        }
    }


    function imprimeMesCorrente(){
        let removeBody = document.querySelector('tbody')
        if(removeBody){
            removeBody.remove()
        }
        
        const table = document.querySelector('table')
        const tbody = document.createElement('tbody')
        tbody.setAttribute('class','body-calendar')

        let trSemana = document.createElement('tr')
        const hoje = new Date().getDate()        
        const comeciaNoDia = dataHora.getDay()
        let nDia = 1
        let i = 1

        while(nDia <= pegaUltimoDiaMes(dataHora)){
            let dia = document.createElement('td')

            if(i <= comeciaNoDia){
                dia.innerHTML = ''
            } else {
                let span = document.createElement('span')
                span.innerText = nDia < 10 ? '0'+nDia.toString() : nDia.toString()

                if(nDia == hoje){
                    span.setAttribute('class','hoje')
                }
                span.onclick = () => handleDia(span.innerText)
                dia.appendChild(span)
                nDia++
            }

            trSemana.appendChild(dia)
            if(i % 7 === 0){
                tbody.appendChild(trSemana)
                trSemana = document.createElement('tr')
            }
            i++
        }
        tbody.appendChild(trSemana)
        table.appendChild(tbody)
    }


    if(isLoaging){
        return(
            <main>
                <Header/>
                <BarraLateral/>            
                <Loading/>
                <Footer/>
            </main>
        )
    }

    return(
        <main>
            <Header/>
            <BarraLateral/> 
            <section className='content-listapaciente'>

                <div className='container-titulo-paciente'>
                    <div className='textheader'>
                        <VscCalendar size={25} color='#555'/>
                        <h3>Você está em agenda de atendimento</h3>
                    </div>               
                    <button className='btn-agendar' onClick={handleOpenModal}>
                        <AiOutlineUsergroupAdd size={25} color='#fff'/>
                        <span>AGENDAR</span>
                    </button> 
                </div>

                <div className='div-titulo-meses'>
                    <div className='titulo-ano'>
                        <span>{ano}</span>
                    </div>  
                    <ul>
                        {meses.map( (mes, index) => (
                            <li id={index.toString()} key={index} onClick={ () => handleBuscaMes(index)}>{mes}</li>
                        ))}
                    </ul>  
                </div>

                <div className='div-container-agenda'>
                    <div className='div-calendario'>
                        <div className='titulo-mes'>
                            <IoChevronBack size={30} color='#000' onClick={handleVoltaMes}/>
                            <span>{meses[mes]} DE {ano}</span>
                            <IoChevronBack size={30} color='#000' onClick={handleAvancaMes}/>
                        </div> 

                        <div className='div-container-calendario'>
                            <table className='table-grid'>
                                <thead className='table-head'>
                                    <tr>
                                        { semana.map( (semana, index) => (
                                            <td key={index}>{semana}</td>
                                        ))}
                                    </tr>
                                </thead>
                            </table> 
                        </div>
                    </div>

                    <div className='div-pacientes'>
                        <div className='div-titulo-agenda'>
                            <AiOutlineFieldTime size={28} color='#000'/>
                            <span>AGENDA DO DIA {diaAgenda}</span>
                        </div>

                        { isloadingHorario ? (
                            <div className='div-request-horario'>
                                <div className='isloading'>
                                    <FaHourglassStart size={60} color='#555'/>
                                    <span>Carregando...</span>
                                </div>
                            </div>
                            ) : ( 
                            <div className='div-atendimento'>
                                <ul>
                                    {arrAgenda.length > 0 ? arrAgenda.map( (atendimento) => {
                                        return(
                                            <li className={atendimento?.CONSIDERADO == 2 ? `li-container considerado` : `li-container`} key={atendimento?.UUID}>
                                                <div className='li-nivel' 
                                                    style={{background: atendimento?.TIPOATENDIMENTO == 1 ? '#6060E0' : 
                                                                        atendimento?.TIPOATENDIMENTO == 2 ? '#6DE04A' :
                                                                        atendimento?.TIPOATENDIMENTO == 3 ? 'yellow'  :
                                                                        atendimento?.TIPOATENDIMENTO == 4 ? 'brown'   : '#6DE04A'
                                                    }}
                                                >                                           
            
                                                </div>
                                                <div className='li-info-atendimento'>
                                                    <div className='li-linha-titulo'>
                                                        <BiTimer id='timer' size={26} color='#000'/>
                                                        <span>{formataDataHoraToString(atendimento?.DATA_AGENDA)}</span> 
                                                    </div>
                                                    <span>{atendimento?.NOME}</span> 
                                                    <div className='li-div-tratamento'>
                                                        <span>TRATAMENTO: {atendimento?.TRATAMENTO}</span>
                                                        <span>OBS: {atendimento?.OBSERVACOES}</span>
                                                    </div>
                                                </div>
                                                <div className='li-btn'>
                                                    <button className='btn-finalizar' onClick={() => handleFinalizar(atendimento)}>
                                                        <BsFillCheckCircleFill color='#FFF' size={18}/>
                                                        FINALIZAR
                                                    </button>

                                                    <button className='btn-editar' onClick={() => handleEditar(atendimento?.UUID)}>
                                                        <MdModeEditOutline color='#FFF' size={18}/>
                                                        EDITAR
                                                    </button>
                                                    
                                                    <button className='btn-cancelar' onClick={() => handleCancelarAtendimento(atendimento)}>
                                                        <MdCancel color='#FFF' size={18}/>
                                                        CANCELAR
                                                    </button>
                                                </div>
                                            </li>
                                        )
                                    }) : (
                                        <li>
                                            <div className='agenda-vazia'>
                                                <CgSmileSad size={100} color='#bbb'/>
                                                <span>Nenhum paciente agendado nesse dia!</span>    
                                            </div>
                                        </li>
                                    )}
                            
                                </ul>
                            </div>
                            )}
                    </div>
                </div>

                { openModalAgenda && (
                    <ModalAgenda
                        date={diaAgendarNovo}
                        dadosatendimento={dadosconsulta}
                        onClose={handleFechaModal}
                    />                                
                )}

                { modalFinalizar && (
                    <ModalFinalizar
                        data={atendimento}
                        onRequestAtualizar={(dia) => handleDia(dia)}
                        onClose={() => setModalFinalizar(false)}
                    />
                )}

                { modalDelete && (
                    <ModalDeleteGenerico
                        data={deleteAtendimento}
                        title='atendimento'
                        onClose={ () => setModalDelete(!modalDelete)}
                        onRequest={ (id) => requestcancelarAtendimento(id)}
                    />
                )}

            </section>
            <Footer/> 
        </main>
    )
}