import './index.css'
import HeaderHome from "../Home/Header"
import MenuMobile from "../Home/MenuMobile"
import { Container } from "../Home/Container"
import soft from '../../assets/software.png'
import Contato from "../Home/Contato"
import FooterHome from "../Home/FooterHome"

export default function QuemSomos(){

    const ano = new Date().getFullYear() - 2008;

    function handletoggle(){
        const nav = document.querySelector('.menumobile-box')
        nav.classList.toggle('active')   
    }

    return(
        <>
            <HeaderHome
                openMenu={handletoggle}
            />
            <Container>
                <section className='quemsomos-container'>
                    <div>
                        <img src={soft} alt="Software" />
                    </div>
                    <div>
                        <p>Somos uma empresa que foi criada especialmente para prestação de serviços nas áreas de desenvolvimento de software comercial, sites e aplicativos para pessoa física e jurídica.</p>
                        <p>Estamos atuando no mercado de informática há mais de {ano} anos e já contamos com grande número de clientes no segmento de oficinas mecânicas, lojas de auto peças, materiais de construção, papelaria, transportadoras, Bares e restaurantes, Clínicas e muito mais.</p>
                    </div>
                </section>
                <Contato/>
            </Container>

            <FooterHome/>

            <MenuMobile
                openMenu={handletoggle}   
            />
        </>
    )
}